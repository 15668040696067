import axios from "axios";

// const BaseUrl = "http://localhost:5000/api/";
const BaseUrl = "https://api.rentmocy.com/api/";
//  const BaseUrl = "https://fc8a-2403-6200-8860-cd36-b584-cc59-451f-790.ngrok.io/api/";


async function apiservice({ method, path, body, token }) {
  try {
    let header = {
      "Content-Type": "application/json",
    };

    if (token != undefined) {
      header.Authorization = token;
    }

    const response = await axios({
      method: method,
      data: body,
      url: BaseUrl + path,
      headers: header,
    });

    if (response.status == 200) {
      return {
        data: response.data,
        status: response.status,
      };
    }

    return response;
  } catch (error) {
    return { data: error.response.data, status: error.response.status };
  }
}

export { apiservice };
