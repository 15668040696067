import { StatusBar } from "expo-status-bar";
import React, { useRef } from "react";
import { useState } from "react";
import {
  StyleSheet,
  Text,
  View,
  TouchableOpacity,
  Dimensions,
  SafeAreaView,
  Image,
  ImageBackground,
  TextInput,
} from "react-native";
import { AntDesign, Entypo } from "@expo/vector-icons";
import {
  widthPercentageToDP as wp,
  heightPercentageToDP as hp,
} from "react-native-responsive-screen";
import { apiservice } from "../../apiservice/api";
const { width, height } = Dimensions.get("window");

const login = ({ navigation }) => {
  const [hidePass, setHidePass] = useState(true);

  const [state, setState] = useState({
    username: "",
    password: "",
  });

  async function authenLogin() {
    const response = await apiservice({
      path: "authen/loginadmin",
      method: "post",
      body: state,
    });
    if (response.status == 200) {
      console.log(response);
      navigation.navigate("Home");
    }
  }

  return (
    <View style={styles.container}>
      <View style={styles.Viewstyle}>
        <View style={{ width: 500, alignSelf: "center" }}>
          <Image
            style={{ width: wp("12%"), height: wp("12%"), alignSelf: "center" }}
            source={require("../../image/Backoffice.png")}
          />

          <Text
            style={{
              fontSize: hp("2%"),
              color: "#3F6BAE",
              fontFamily: "Kanit-Regular",
              marginTop: 47,
            }}
          >
            Username
          </Text>
          <View style={{ width: 493 }}>
            <TextInput
              defaultValue={state.username}
              onChangeText={(text) => {
                setState((val) => ({ ...val, username: text }));
              }}
              style={{
                width: 480,
                left: 10,
                fontSize: hp("2%"),
                fontFamily: "Kanit-Regular",
              }}
              placeholder="Username"
              placeholderTextColor="#C4C4C4"
            />
          </View>
          <View
            style={{
              width: 493,
              borderWidth: 1,
              borderColor: "#3F6BAE",
              marginVertical: 5,
            }}
          />

          <Text
            style={{
              fontSize: hp("2%"),
              color: "#3F6BAE",
              fontFamily: "Kanit-Regular",
              marginTop: 23,
            }}
          >
            Password
          </Text>
          <View style={{ width: 493, flexDirection: "row" }}>
            <TextInput
              defaultValue={state.password}
              onChangeText={(text) => {
                setState((val) => ({ ...val, password: text }));
              }}
              secureTextEntry={hidePass ? true : false}
              style={{
                width: 470,
                left: 10,
                fontSize: hp("2%"),
                fontFamily: "Kanit-Regular",
              }}
              placeholder="Password"
              placeholderTextColor="#C4C4C4"
            />
            {state.password.length ? (
              <AntDesign
                name={hidePass ? "eyeo" : "eye"}
                size={24}
                color="red"
                onPress={() => setHidePass(!hidePass)}
                // style={{ marginTop: 5 }}
              />
            ) : (
              <View></View>
            )}
          </View>
          <View
            style={{
              width: 493,
              borderWidth: 1,
              borderColor: "#3F6BAE",
              marginVertical: 5,
            }}
          />
          <TouchableOpacity
            onPress={() => {
              authenLogin();
            }}
            style={styles.buttomLogin}
          >
            Login
          </TouchableOpacity>
        </View>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: "#036BB2",
    alignItems: "center",
    justifyContent: "center",
  },
  Viewstyle: {
    width: width * 0.42,
    height: height * 0.75,
    backgroundColor: "#fff",
    borderRadius: 10,
    justifyContent: "center",
  },
  buttomLogin: {
    width: 493,
    height: 80,
    backgroundColor: "#EA640D",
    justifyContent: "center",
    alignItems: "center",
    marginTop: 50,
    fontFamily: "Kanit-Regular",
    fontSize: hp("5%"),
    color: "#fff",
    borderRadius: 5,
  },
});

export default login;
