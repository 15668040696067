import React, { useRef, useState } from "react";
import {
  StyleSheet,
  Text,
  View,
  TouchableOpacity,
  Dimensions,
  SafeAreaView,
  Image,
  ImageBackground,
  TextInput,
  ScrollView,
  FlatList,
} from "react-native";
import {
  widthPercentageToDP as wp,
  heightPercentageToDP as hp,
} from "react-native-responsive-screen";
import {
  AntDesign,
  Entypo,
  MaterialCommunityIcons,
  FontAwesome,
} from "@expo/vector-icons";
const { width, height } = Dimensions.get("window");
import Page from "../../component/page";
import Page1 from "../../component/page1";
import Page2 from "../../component/page2";
import Page3 from "../../component/page3";
import Pageoption from "../../component/pageoption";
import Pageoption1 from "../../component/pageoption1";
import Pageoption2 from "../../component/pageoption2";
import Pageoption3 from "../../component/pageoption3";
import Page5 from "../../component/page5";
import { useWindowSize } from "../../component/responsive";
const Home = ({ navigation }) => {
  const [page, setpage] = useState(0);

  const [pageoption, setpageoption] = useState(0);
  const [option, setoption] = useState(false);
  const size = useWindowSize();
  return (
    <View style={styles.container}>
      <ScrollView>
        <View style={{ flexDirection: "row" }}>
          <View
            style={{
              width: "20%",
              height: height,
              backgroundColor: "#fff",
              shadowColor: "#000",
              shadowOffset: {
                width: 0,
                height: 2,
              },
              shadowOpacity: 0.25,
              shadowRadius: 3.84,
              elevation: 5,
            }}
          >
            <Image
            resizeMode={"stretch"}
              style={{
                width:"100%",
                maxWidth: 500,
                height: 250,
                alignSelf: "center",
              }}
              source={require("../../image/Backoffice.png")}
            />
            <TouchableOpacity
              onPress={() => {
                setoption(false);
                setpage(0);
              }}
              style={[
                styles.stytab,
                {
                  backgroundColor: page == 0 ? "#036BB2" : "#036BB215",
                },
              ]}
            >
              <View
                style={[
                  styles.styleR,
                  {
                    backgroundColor: page == 0 ? "#FFFFFF" : "#EA640D",
                  },
                ]}
              />
              <View style={{ width: "70%"}}>
                <Text
                  style={[
                    styles.styletext,
                    {
                      color: page == 0 ? "#FFFFFF" : "#00000065",
                      fontSize: size.width < 1080 ? 18 : 26,
                    },
                  ]}
                >
                  Dashboard
                </Text>
              </View>
              <AntDesign
                name="right"
                size={30}
                color={page == 0 ? "#FFFFFF" : "#EA640D"}
              />
            </TouchableOpacity>

            <TouchableOpacity
              onPress={() => {
                setoption(false);
                setpage(1);
              }}
              style={[
                styles.stytab,
                {
                  backgroundColor: page == 1 ? "#036BB2" : "#FFFFFF",
                },
              ]}
            >
              <View
                style={[
                  styles.styleR,
                  {
                    backgroundColor: page == 1 ? "#FFFFFF" : "#EA640D",
                  },
                ]}
              />
              <View style={{ width: "70%"}}>
                <Text
                  style={[
                    styles.styletext,
                    {
                      color: page == 1 ? "#FFFFFF" : "#00000065",
                      fontSize: size.width < 1080 ? 18 : 26,
                    },
                  ]}
                >
                  History
                </Text>
              </View>
              <AntDesign
                name="right"
                size={30}
                color={page == 1 ? "#FFFFFF" : "#EA640D"}
              />
            </TouchableOpacity>
            <TouchableOpacity
              onPress={() => {
                setoption(false);
                setpage(2);
              }}
              style={[
                styles.stytab,
                {
                  backgroundColor: page == 2 ? "#036BB2" : "#036BB215",
                },
              ]}
            >
              <View
                style={[
                  styles.styleR,
                  {
                    backgroundColor: page == 2 ? "#FFFFFF" : "#EA640D",
                  },
                ]}
              />
            <View style={{ width: "70%"}}>
                <Text
                  style={[
                    styles.styletext,
                    {
                      color: page == 2 ? "#FFFFFF" : "#00000065",
                      fontSize: size.width < 1080 ? 18 : 26,
                    },
                  ]}
                >
                  Account
                </Text>
              </View>
              <AntDesign
                name="right"
                size={30}
                color={page == 2 ? "#FFFFFF" : "#EA640D"}
              />
            </TouchableOpacity>
            {/* <TouchableOpacity
            onPress={() => setpage(3)}
            style={[
              styles.stytab,
              {
                backgroundColor: page == 3 ? "#036BB2" : "#FFFFFF",
              },
            ]}
          >
            <View
              style={[
                styles.styleR,
                {
                  backgroundColor: page == 3 ? "#FFFFFF" : "#EA640D",
                },
              ]}
            />
            <View style={{ width: 250 }}>
              <Text
                style={[
                  styles.styletext,
                  {
                    color: page == 3 ? "#FFFFFF" : "#00000065",
                  },
                ]}
              >
                Store account
              </Text>
            </View>
            <AntDesign
              name="right"
              size={30}
              color={page == 3 ? "#FFFFFF" : "#EA640D"}
            />
          </TouchableOpacity> */}
            <TouchableOpacity
              onPress={() => {
                setoption(false);
                setpage(3);
              }}
              style={[
                styles.stytab,
                {
                  backgroundColor: page == 3 ? "#036BB2" : "#FFFFFF",
                },
              ]}
            >
              <View
                style={[
                  styles.styleR,
                  {
                    backgroundColor: page == 3 ? "#FFFFFF" : "#EA640D",
                  },
                ]}
              />
            <View style={{ width: "70%"}}>
                <Text
                  style={[
                    styles.styletext,
                    {
                      color: page == 3 ? "#FFFFFF" : "#00000065",
                      fontSize: size.width < 1080 ? 18 : 26,
                    },
                  ]}
                >
                  Motorbike
                </Text>
              </View>
              <AntDesign
                name="right"
                size={30}
                color={page == 3 ? "#FFFFFF" : "#EA640D"}
              />
            </TouchableOpacity>
            {option ? (
              <View>
                <TouchableOpacity
                  onPress={() => {
                    setoption((val) => !val);
                    setpage(4);
                  }}
                  style={[
                    styles.stytab,
                    {
                      backgroundColor: page == 4 ? "#036BB2" : "#036BB215",
                    },
                  ]}
                >
                  <View
                    style={[
                      styles.styleR,
                      {
                        backgroundColor: page == 4 ? "#FFFFFF" : "#EA640D",
                      },
                    ]}
                  />
                    <View style={{ width: "70%"}}>
                    <Text
                      style={[
                        styles.styletext,
                        {
                          color: page == 4 ? "#FFFFFF" : "#00000065",
                          fontSize: size.width < 1080 ? 18 : 26,
                        },
                      ]}
                    >
                      Option
                    </Text>
                  </View>
                  <AntDesign
                    name="down"
                    size={30}
                    color={page == 4 ? "#FFFFFF" : "#EA640D"}
                  />
                </TouchableOpacity>

                <View style={{ width: "100%"}}>
                  <TouchableOpacity
                    onPress={() => {
                      setpageoption(0);
                    }}
                    style={{
                      width: "100%",
                      height: 50,
                      backgroundColor: "#036BB2",
                      justifyContent: "center",
                      textDecorationLine: 'underline',
                      textDecorationColor: pageoption == 0 ? "#FFFFFF" : "#036BB2",
                    }}
                  >
                    <Text
                      style={{
                        color: "#ffff",
                        fontSize: size.width < 1080 ? 16 : 20,
                        marginLeft: 65,
                        
                      }}
                    >
                      คำขอยกเลิก
                    </Text>
                    {/* <View
                      style={{
                        borderWidth: 1,
                        width: 130,
                        marginLeft: 65,
                        borderColor: pageoption == 0 ? "#FFFFFF" : "#036BB2",
                      }}
                    /> */}
                  </TouchableOpacity>
                </View>

                <View style={{width: "100%", }}>
                  {/* <TouchableOpacity
                    onPress={() => {
                      setpageoption(1);
                    }}
                    style={{
                      width: "100%",
                      height: 50,
                      backgroundColor: "#036BB2",
                      justifyContent: "center",
                      textDecorationLine: 'underline',
                      textDecorationColor: pageoption == 1 ? "#FFFFFF" : "#036BB2",
                    }}
                  >
                    <Text
                      style={{
                        color: "#ffff",
                        fontSize: size.width < 1080 ? 16 : 20,
                        marginLeft: 65,
                      }}
                    >
                      Payment
                    </Text>
                  
                  </TouchableOpacity> */}
                </View>

                <View style={{width: "100%", }}>
                  <TouchableOpacity
                    onPress={() => {
                      setpageoption(2);
                    }}
                    style={{
                      width: "100%",
                      height: 80,
                      backgroundColor: "#036BB2",
                      justifyContent: "center",
                    }}
                  >
                    <Text
                      style={{
                        color: "#ffff",
                        fontSize: size.width < 1080 ? 16 : 20,
                        marginLeft: 65,
                        textDecorationLine: 'underline',
                        textDecorationColor: pageoption == 2 ? "#FFFFFF" : "#036BB2",
                      }}
                    >
                      Privacy policy & {"\n"}Term of use
                    </Text>
                   
                  </TouchableOpacity>
                </View>

                <View style={{     width: "100%", }}>
                  <TouchableOpacity
                    onPress={() => {
                      setpageoption(3);
                    }}
                    style={{
                      width: "100%",
                      height: 80,
                      backgroundColor: "#036BB2",
                      justifyContent: "center",
                      textDecorationLine: 'underline',
                      textDecorationColor: pageoption == 3 ? "#FFFFFF" : "#036BB2",
                    }}
                  >
                    <Text
                      style={{
                        color: "#ffff",
                        fontSize: size.width < 1080 ? 16 : 20,
                        marginLeft: 65,
                      }}
                    >
                      ร้องเรียนและ {"\n"}ข้อเสนอเพิ่มเติม
                    </Text>
   
                  </TouchableOpacity>
                </View>
              </View>
            ) : (
              <TouchableOpacity
                onPress={() => {
                  setoption((val) => !val);
                  setpage(4);
                }}
                style={[
                  styles.stytab,
                  {
                    backgroundColor: page == 4 ? "#036BB2" : "#036BB215",
                  },
                ]}
              >
                <View
                  style={[
                    styles.styleR,
                    {
                      backgroundColor: page == 4 ? "#FFFFFF" : "#EA640D",
                    },
                  ]}
                />
               <View style={{ width: "70%"}}>
                  <Text
                    style={[
                      styles.styletext,
                      {
                        color: page == 4 ? "#FFFFFF" : "#00000065",
                        fontSize: size.width < 1080 ? 18 : 26,
                      },
                    ]}
                  >
                    Option
                  </Text>
                </View>
                <AntDesign
                  name="right"
                  size={30}
                  color={page == 4 ? "#FFFFFF" : "#EA640D"}
                />
              </TouchableOpacity>
            )}

            <TouchableOpacity
              onPress={() => {
                setoption(false);
                setpage(5);
              }}
              style={[
                styles.stytab,
                {
                  backgroundColor: page == 5 ? "#036BB2" : "#FFFFFF",
                },
              ]}
            >
              <View
                style={[
                  styles.styleR,
                  {
                    backgroundColor: page == 5 ? "#FFFFFF" : "#EA640D",
                  },
                ]}
              />
             <View style={{ width: "70%"}}>
                <Text
                  style={[
                    styles.styletext,
                    {
                      color: page == 5 ? "#FFFFFF" : "#00000065",
                    fontSize: size.width < 1080 ? 18 : 26,
                    },
                  ]}
                >
                  Admin
                </Text>
              </View>
              <AntDesign
                name="right"
                size={30}
                color={page == 5 ? "#FFFFFF" : "#EA640D"}
              />
            </TouchableOpacity>
          </View>

          <View style={{width: "80%",}}>
            <View style={{  height: 95 }}></View>

            <View>
              {page == 0 && <Page />}
              {page == 1 && <Page1 />}
              {page == 2 && <Page2 />}
              {page == 3 && <Page3 />}
              {page == 4 && (
                <View>
                  {pageoption == 0 && <Pageoption />}

                  {pageoption == 1 && <Pageoption1 />}

                  {pageoption == 2 && <Pageoption2 />}

                  {pageoption == 3 && <Pageoption3 />}
                </View>
              )}
              {page == 5 && <Page5 />}
            </View>
          </View>
        </View>
      </ScrollView>
    </View>
  );
};
const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: "#F5F5F5",
  },
  stytab: {
    width: "100%",
    height: 90,
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
  },
  styletext: {
    fontSize: hp("3%"),
    fontFamily: "Kanit-Regular",
    marginHorizontal: 10,
  },
  styleR: {
    width: 17,
    height: 17,
    borderRadius: 30,
    marginHorizontal: 5,
  },

});

export default Home;
