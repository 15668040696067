import React, { useEffect, useRef, useState } from "react";
import {
  StyleSheet,
  Text,
  View,
  TouchableOpacity,
  Dimensions,
  TextInput,
  FlatList,
  ScrollView,
  Linking,
} from "react-native";
import {
  widthPercentageToDP as wp,
  heightPercentageToDP as hp,
} from "react-native-responsive-screen";
import {
  FontAwesome5,
  FontAwesome,
  Foundation,
  AntDesign,
  MaterialCommunityIcons,
} from "@expo/vector-icons";
import { useWindowSize } from "./responsive";
import Modal from "modal-enhanced-react-native-web";
import { apiservice } from "../apiservice/api";
const { width, height } = Dimensions.get("window");

const page1 = () => {
  const [data, setData] = useState([]);
  const [checkbox, setcheckbox] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);
  const [EdmodalVisible, setEdModalVisible] = useState(false);
  const [search, setsearch] = useState("");

  const [detail, setItem] = useState({ customer_info: {}, market_info: {} });

  useEffect(() => {
    callApi();
  }, []);
  console.log("Data", detail);

  // {detail.market_info.recive_address != undefined &&
  //   detail.market_info.recive_address[0].name}
  async function callApi() {
    const api = await apiservice({
      path: "/market/history/getall",
      method: "get",
    });
    // console.log(api);
    setData(api.data.data);
  }
  const size = useWindowSize();
  return (
    <View>
      <Modal
        isVisible={modalVisible}
        onBackdropPress={() => setModalVisible((val) => !val)}
      >
        <ScrollView>
          <View
            style={{
              width: 728,
              height: 900,
              backgroundColor: "#fff",
              alignSelf: "center",
              borderRadius: 10,
              paddingTop: 10,
            }}
          >
            <View style={{ width: 675, alignSelf: "center" }}>
              <Text
                style={{
                  fontSize: 30,
                  color: "#EA640D",
                  fontFamily: "Kanit-Regular",
                }}
              >
                ข้อมูลการเช่ารถ
              </Text>

              <View
                style={{
                  width: 678,
                  height: 700,
                  backgroundColor: "#E7E7E7",
                  borderRadius: 10,
                }}
              >
                <View style={{ width: 639, alignSelf: "center" }}>
                  <Text style={styles.styletextAll}>หมายเลขการจอง</Text>

                  <Text
                    style={[
                      styles.styleinput,
                      {
                        width: 639,
                      },
                    ]}
                  >
                    {10000 + detail.id}
                  </Text>

                  <Text style={styles.styletextAll}>รถที่เช่า</Text>

                  <Text
                    style={[
                      styles.styleinput,
                      {
                        width: 639,
                      },
                    ]}
                  >
                    {detail.name}
                  </Text>

                  <Text style={styles.styletextAll}>ชื่อร้านค้า</Text>
                  <View
                    style={[
                      styles.styleinput,
                      {
                        width: 639,
                      },
                    ]}
                  >
                    <Text
                      style={[
                        styles.styleinput,
                        {
                          width: 639,
                        },
                      ]}
                    >
                      {detail.marketname}
                    </Text>
                  </View>
                  <View style={{ flexDirection: "row" }}>
                    <View>
                      <Text style={styles.styletextAll}>ชื่อผู้เช่า</Text>

                      <Text
                        style={[
                          styles.styleinput,
                          {
                            width: 315,
                          },
                        ]}
                      >
                        {detail.customer_info.firstname +
                          " " +
                          detail.customer_info.lastname}
                      </Text>
                    </View>

                    <View>
                      <Text style={[styles.styletextAll, { marginLeft: 5 }]}>
                        หมายเลขรถทัวร์/รถไฟ/เครื่องบิน
                      </Text>

                      <TextInput
                        style={[
                          styles.styleinput,
                          {
                            width: 309,
                            marginLeft: 5,
                          },
                        ]}
                      />
                    </View>
                  </View>

                  <View style={{ flexDirection: "row" }}>
                    <View>
                      <Text style={styles.styletextAll}>
                        สถานที่รับรถ Pick-up
                      </Text>

                      <Text
                        style={[
                          styles.styleinput,
                          {
                            width: 315,
                          },
                        ]}
                      >
                        {detail.market_info.recive_address != undefined &&
                          detail.market_info.recive_address[0].name}
                      </Text>
                    </View>
                    {/* <View>
                      <Text style={[styles.styletextAll, { marginLeft: 5 }]}>
                        เวลารับรถ
                      </Text>
                      <View
                        style={[
                          styles.styleinput,
                          {
                            width: 309,
                            marginLeft: 5,
                          },
                        ]}
                      >
                        <TextInput
                          style={[
                            styles.styleinput,
                            {
                              width: 309,
                            },
                          ]}
                        />
                      </View>
                    </View> */}
                  </View>
                  {/* <Text style={[styles.styletextAll, { marginLeft: 5 }]}>
                    ราคาสถานที่รับรถ
                  </Text>

                    <Text
                        style={[
                          styles.styleinput,
                          {
                            width: 309,
                            marginLeft: 5,
                          },
                        ]}
                      >
                        {detail.market_info.returnCar_address != undefined &&
                          detail.market_info.returnCar_address[0].name}
                      </Text> */}

                  <View style={{ flexDirection: "row" }}>
                    <View>
                      <Text style={styles.styletextAll}>
                        สถานที่คืนรถ Drop-off
                      </Text>

                      {/* <TextInput
                        style={[
                          styles.styleinput,
                          {
                            width: 315,
                          },
                        ]}
                      /> */}
                      <Text
                        style={[
                          styles.styleinput,
                          {
                            width: 315,
                          },
                        ]}
                      >
                        {detail.market_info.returnCar_address != undefined &&
                          detail.market_info.returnCar_address[0].name}
                      </Text>
                    </View>

                    {/* <View>
                      <Text style={[styles.styletextAll, { marginLeft: 5 }]}>
                        เวลาคืนรถ
                      </Text>

                      <TextInput
                        style={[
                          styles.styleinput,
                          {
                            width: 309,
                            marginLeft: 5,
                          },
                        ]}
                      />
                    </View> */}
                  </View>

                  {/* <Text style={styles.styletextAll}>ราคาสถานที่ส่งรถ</Text>

                  <TextInput
                    style={[
                      styles.styleinput,
                      {
                        width: 315,
                      },
                    ]}
                  /> */}

                  <View style={{ flexDirection: "row" }}>
                    <View>
                      <Text style={styles.styletextAll}>วันที่เช่า</Text>
                      <Text
                        style={[
                          styles.styleinput,
                          {
                            width: 315,
                          },
                        ]}
                      >
                        {detail.reciveinfo != undefined &&
                          detail.reciveinfo.reviceDate}
                      </Text>
                    </View>
                    <View>
                      <Text style={[styles.styletextAll, { marginLeft: 5 }]}>
                        วันที่คืน
                      </Text>
                      <Text
                        style={[
                          styles.styleinput,
                          {
                            width: 309,
                            marginLeft: 5,
                          },
                        ]}
                      >
                        {detail.reciveinfo != undefined &&
                          detail.reciveinfo.returnCarDate}
                      </Text>
                    </View>
                  </View>
                  <Text style={styles.styletextAll}>ประกันจาก Rent Mocy</Text>
                  <View style={{ flexDirection: "row" }}>
                    {/* detail.market_info.returnCar_address != undefined &&
                          detail.market_info.returnCar_address[0].name */}
                    {detail.product_info != undefined &&
                    detail.product_info.PLB == true ? (
                      <TouchableOpacity>
                        <AntDesign
                          name="checksquareo"
                          size={24}
                          color="#EA640D"
                        />
                      </TouchableOpacity>
                    ) : (
                      <TouchableOpacity>
                        <MaterialCommunityIcons
                          name="checkbox-blank"
                          size={24}
                          color="#F3F3F3"
                        />
                      </TouchableOpacity>
                    )}
                    <Text
                      style={{
                        fontSize: 18,
                        color: "#A1A1A1",
                        fontFamily: "Kanit-Regular",
                        marginHorizontal: 4,
                      }}
                    >
                      มีประกัน
                    </Text>

                    {detail.product_info != undefined &&
                    detail.product_info.PLB == false ? (
                      <TouchableOpacity
                        onPress={() => {
                          setcheckbox((val) => !val);
                        }}
                      >
                        <AntDesign
                          name="checksquareo"
                          size={24}
                          color="#EA640D"
                        />
                      </TouchableOpacity>
                    ) : (
                      <TouchableOpacity
                        onPress={() => {
                          setcheckbox((val) => !val);
                        }}
                      >
                        <MaterialCommunityIcons
                          name="checkbox-blank"
                          size={24}
                          color="#F3F3F3"
                        />
                      </TouchableOpacity>
                    )}
                    <Text
                      style={{
                        fontSize: 18,
                        color: "#A1A1A1",
                        fontFamily: "Kanit-Regular",
                        marginHorizontal: 4,
                      }}
                    >
                      ไม่มีประกัน
                    </Text>
                    {/* {checkbox ? (
                      <TouchableOpacity
                        onPress={() => {
                          setcheckbox((val) => !val);
                        }}
                      >
                        <AntDesign
                          name="checksquareo"
                          size={24}
                          color="#EA640D"
                        />
                      </TouchableOpacity>
                    ) : (
                      <TouchableOpacity
                        onPress={() => {
                          setcheckbox((val) => !val);
                        }}
                      >
                        <MaterialCommunityIcons
                          name="checkbox-blank"
                          size={24}
                          color="#F3F3F3"
                        />
                      </TouchableOpacity>
                    )}
                    <Text
                      style={{
                        fontSize: 18,
                        color: "#A1A1A1",
                        fontFamily: "Kanit-Regular",
                        marginHorizontal: 4,
                      }}
                    >
                      สนใจประกันเพิ่มเติม
                    </Text> */}
                  </View>

                  <Text style={styles.styletextAll}>GPS</Text>
                  <View style={{ flexDirection: "row" }}>
                    {detail.product_info != undefined &&
                    detail.product_info.GPS == true ? (
                      <TouchableOpacity
                        onPress={() => {
                          setcheckbox((val) => !val);
                        }}
                      >
                        <AntDesign
                          name="checksquareo"
                          size={24}
                          color="#EA640D"
                        />
                      </TouchableOpacity>
                    ) : (
                      <TouchableOpacity
                        onPress={() => {
                          setcheckbox((val) => !val);
                        }}
                      >
                        <MaterialCommunityIcons
                          name="checkbox-blank"
                          size={24}
                          color="#F3F3F3"
                        />
                      </TouchableOpacity>
                    )}
                    <Text
                      style={{
                        fontSize: 18,
                        color: "#A1A1A1",
                        fontFamily: "Kanit-Regular",
                        marginHorizontal: 7,
                      }}
                    >
                      มี GPS
                    </Text>

                    {detail.product_info != undefined &&
                    detail.product_info.GPS == false ? (
                      <TouchableOpacity
                        style={{ marginHorizontal: 4 }}
                        onPress={() => {
                          setcheckbox((val) => !val);
                        }}
                      >
                        <AntDesign
                          name="checksquareo"
                          size={24}
                          color="#EA640D"
                        />
                      </TouchableOpacity>
                    ) : (
                      <TouchableOpacity
                        style={{ marginHorizontal: 4 }}
                        onPress={() => {
                          setcheckbox((val) => !val);
                        }}
                      >
                        <MaterialCommunityIcons
                          name="checkbox-blank"
                          size={24}
                          color="#F3F3F3"
                        />
                      </TouchableOpacity>
                    )}
                    <Text
                      style={{
                        fontSize: 18,
                        color: "#A1A1A1",
                        fontFamily: "Kanit-Regular",
                        marginHorizontal: 4,
                      }}
                    >
                      ไม่มี GPS
                    </Text>
                    {/* {checkbox ? (
                      <TouchableOpacity
                        style={{ marginHorizontal: 4 }}
                        onPress={() => {
                          setcheckbox((val) => !val);
                        }}
                      >
                        <AntDesign
                          name="checksquareo"
                          size={24}
                          color="#EA640D"
                        />
                      </TouchableOpacity>
                    ) : (
                      <TouchableOpacity
                        style={{ marginHorizontal: 4 }}
                        onPress={() => {
                          setcheckbox((val) => !val);
                        }}
                      >
                        <MaterialCommunityIcons
                          name="checkbox-blank"
                          size={24}
                          color="#F3F3F3"
                        />
                      </TouchableOpacity>
                    )}
                    <Text
                      style={{
                        fontSize: 18,
                        color: "#A1A1A1",
                        fontFamily: "Kanit-Regular",
                        marginHorizontal: 4,
                      }}
                    >
                      สนใจติดตั้ง GPS
                    </Text> */}
                  </View>
                  <View style={{ flexDirection: "row" }}>
                    <View>
                      <Text style={styles.styletextAll}>ยอดชำระ</Text>
                      <Text
                        style={[
                          styles.styleinput,
                          {
                            width: 315,
                          },
                        ]}
                      >
                        {detail.product_info != undefined &&
                          detail.product_info.price}
                      </Text>
                    </View>
                    <View>
                      {/* <Text style={[styles.styletextAll, { marginLeft: 5 }]}>
                        คำขอยกเลิก
                      </Text>
                      <View
                        style={[
                          styles.styleinput,
                          {
                            width: 309,
                            marginLeft: 5,
                          },
                        ]}
                      >
                        <TextInput
                          style={[
                            styles.styleinput,
                            {
                              width: 309,
                            },
                          ]}
                        />
                      </View> */}
                    </View>
                  </View>

                  <View style={{ flexDirection: "row" }}>
                    {/* <View>
                      <Text style={styles.styletextAll}>วิธีชำระเงิน</Text>
                      <View
                        style={[
                          styles.styleinput,
                          {
                            width: 315,
                          },
                        ]}
                      >
                        <TextInput
                          style={[
                            styles.styleinput,
                            {
                              width: 315,
                            },
                          ]}
                        />
                      </View>
                    </View> */}
                    <View>
                      <Text style={[styles.styletextAll, { marginLeft: 5 }]}>
                        วันที่ทำรายการ
                      </Text>
                      <Text
                        style={[
                          styles.styleinput,
                          {
                            width: 309,
                            marginLeft: 5,
                          },
                        ]}
                      >
                        {/* <TextInput
                          style={[
                            styles.styleinput,
                            {
                              width: 309,
                            },
                          ]}
                        /> */}
                        {detail.product_info != undefined &&
                          detail.product_info.updatedAt}
                      </Text>
                    </View>
                  </View>
                  {/* <Text style={styles.styletextAll}>สถานะ</Text>
                  <View
                    style={[
                      styles.styleinput,
                      {
                        width: 315,
                      },
                    ]}
                  >
                    <TextInput
                      style={[
                        styles.styleinput,
                        {
                          width: 315,
                        },
                      ]}
                    />
                  </View> */}
                </View>
              </View>

              {/* <Text
                style={{
                  fontSize: 30,
                  color: "#EA640D",
                  fontFamily: "Kanit-Regular",
                }}
              >
                ยอดชำระเพิ่มเติม
              </Text>
              <View
                style={{
                  width: 678,
                  height: 200,
                  backgroundColor: "#E7E7E7",
                  borderRadius: 5,
                }}
              >
                <View style={{ width: 650, alignSelf: "center" }}>
                  <View style={{ flexDirection: "row" }}>
                    <View>
                      <Text style={styles.styletextAll}>ยอดชำระ</Text>
                      <View
                        style={[
                          styles.styleinput,
                          {
                            width: 315,
                          },
                        ]}
                      >
                        <TextInput
                          style={[
                            styles.styleinput,
                            {
                              width: 315,
                            },
                          ]}
                        />
                      </View>
                    </View>
                    <View>
                      <Text style={[styles.styletextAll, { marginLeft: 5 }]}>
                        คำขอยกเลิก
                      </Text>
                      <View
                        style={[
                          styles.styleinput,
                          {
                            width: 309,
                            marginLeft: 5,
                          },
                        ]}
                      >
                        <TextInput
                          style={[
                            styles.styleinput,
                            {
                              width: 309,
                            },
                          ]}
                        />
                      </View>
                    </View>
                  </View>
                  <View style={{ flexDirection: "row" }}>
                    <View>
                      <Text style={styles.styletextAll}>วิธีชำระเงิน</Text>
                      <View
                        style={[
                          styles.styleinput,
                          {
                            width: 315,
                          },
                        ]}
                      >
                        <TextInput
                          style={[
                            styles.styleinput,
                            {
                              width: 315,
                            },
                          ]}
                        />
                      </View>
                    </View>
                    <View>
                      <Text style={[styles.styletextAll, { marginLeft: 5 }]}>
                        วันที่ทำรายการ
                      </Text>
                      <View
                        style={[
                          styles.styleinput,
                          {
                            width: 309,
                            marginLeft: 5,
                          },
                        ]}
                      >
                        <TextInput
                          style={[
                            styles.styleinput,
                            {
                              width: 309,
                            },
                          ]}
                        />
                      </View>
                    </View>
                  </View>
                  <View style={{ flexDirection: "row" }}>
                    <View>
                      <Text style={[styles.styletextAll, { marginLeft: 5 }]}>
                        หมายเหตุ
                      </Text>
                      <View
                        style={[
                          styles.styleinput,
                          {
                            width: 309,
                            marginLeft: 5,
                          },
                        ]}
                      >
                        <TextInput
                          style={[
                            styles.styleinput,
                            {
                              width: 309,
                            },
                          ]}
                        />
                      </View>
                    </View>
                    <TouchableOpacity
                      style={{
                        width: 192,
                        height: 29,
                        backgroundColor: "#EA640D",
                        marginHorizontal: 8,
                        borderRadius: 5,
                        marginTop: 25,
                      }}
                    >
                      <Text
                        style={{
                          fontSize: 20,
                          fontFamily: "Kanit-Regular",
                          color: "#ffffff",
                          textAlign: "center",
                        }}
                      >
                        เรียกเก็บเงินเพิ่ม
                      </Text>
                    </TouchableOpacity>
                  </View>
                </View>
              </View> */}

              {/* <View
                style={{
                  flexDirection: "row",
                  justifyContent: "center",
                  marginTop: 20,
                }}
              >
                <TouchableOpacity
                  style={{
                    width: 232,
                    height: 37,
                    backgroundColor: "#036BB2",
                    marginHorizontal: 10,
                    borderRadius: 5,
                    justifyContent: "center",
                  }}
                >
                  <Text
                    style={{
                      fontSize: 20,
                      fontFamily: "Kanit-Regular",
                      color: "#ffffff",
                      textAlign: "center",
                    }}
                  >
                    ส่ง E-mail ให้ร้านค้า
                  </Text>
                </TouchableOpacity>

                <TouchableOpacity
                  style={{
                    width: 232,
                    height: 37,
                    backgroundColor: "#036BB2",
                    marginHorizontal: 10,
                    borderRadius: 5,
                    justifyContent: "center",
                  }}
                >
                  <Text
                    style={{
                      fontSize: 20,
                      fontFamily: "Kanit-Regular",
                      color: "#ffffff",
                      textAlign: "center",
                    }}
                  >
                    ส่ง E-mail ให้ผู้เช่า
                  </Text>
                </TouchableOpacity>
              </View> */}

              <TouchableOpacity
                onPress={() => {
                  setModalVisible(false);
                }}
                style={{
                  width: 91,
                  height: 32,
                  backgroundColor: "#EA640D",
                  alignSelf: "flex-end",
                  marginTop: 15,
                  borderRadius: 5,
                }}
              >
                <Text
                  style={{
                    fontSize: 20,
                    fontFamily: "Kanit-Regular",
                    color: "#ffffff",
                    textAlign: "center",
                  }}
                >
                  Close
                </Text>
              </TouchableOpacity>
            </View>
          </View>
        </ScrollView>
      </Modal>

      {/* EdModal */}
      {/* <Modal
        isVisible={EdmodalVisible}
        onBackdropPress={() => setEdModalVisible((val) => !val)}
      >
        <ScrollView>
          <View
            style={{
              width: 728,
              height: 1300,
              backgroundColor: "#fff",
              alignSelf: "center",
              borderRadius: 10,
              paddingTop: 10,
            }}
          >
            <View style={{ width: 675, alignSelf: "center" }}>
              <Text
                style={{
                  fontSize: 30,
                  color: "#EA640D",
                  fontFamily: "Kanit-Regular",
                }}
              >
                แก้ไขข้อมูลการเช่ารถ
              </Text>
              <View
                style={{
                  width: 678,
                  height: 830,
                  backgroundColor: "#E7E7E7",
                  borderRadius: 10,
                }}
              >
                <View style={{ width: 639, alignSelf: "center" }}>
                  <Text style={styles.styletextAll}>หมายเลขการจอง</Text>
                  <View
                    style={[
                      styles.styleinput,
                      {
                        width: 639,
                      },
                    ]}
                  >
                    <TextInput
                      style={[
                        styles.styleinput,
                        {
                          width: 639,
                        },
                      ]}
                    />
                  </View>

                  <Text style={styles.styletextAll}>รถที่เช่า</Text>
                  <View
                    style={[
                      styles.styleinput,
                      {
                        width: 639,
                      },
                    ]}
                  >
                    <TextInput
                      style={[
                        styles.styleinput,
                        {
                          width: 639,
                        },
                      ]}
                    />
                  </View>

                  <Text style={styles.styletextAll}>ชื่อร้านค้า</Text>
                  <View
                    style={[
                      styles.styleinput,
                      {
                        width: 639,
                      },
                    ]}
                  >
                    <TextInput
                      style={[
                        styles.styleinput,
                        {
                          width: 639,
                        },
                      ]}
                    />
                  </View>
                  <View style={{ flexDirection: "row" }}>
                    <View>
                      <Text style={styles.styletextAll}>ชื่อผู้เช่า</Text>
                      <View
                        style={[
                          styles.styleinput,
                          {
                            width: 315,
                          },
                        ]}
                      >
                        <TextInput
                          style={[
                            styles.styleinput,
                            {
                              width: 315,
                            },
                          ]}
                        />
                      </View>
                    </View>
                    <View>
                      <Text style={[styles.styletextAll, { marginLeft: 5 }]}>
                        หมายเลขรถทัวร์/รถไฟ/เครื่องบิน
                      </Text>
                      <View
                        style={[
                          styles.styleinput,
                          {
                            width: 309,
                            marginLeft: 5,
                          },
                        ]}
                      >
                        <TextInput
                          style={[
                            styles.styleinput,
                            {
                              width: 309,
                            },
                          ]}
                        />
                      </View>
                    </View>
                  </View>

                  <View style={{ flexDirection: "row" }}>
                    <View>
                      <Text style={styles.styletextAll}>
                        สถานที่รับรถ Pick-up
                      </Text>
                      <View
                        style={[
                          styles.styleinput,
                          {
                            width: 315,
                          },
                        ]}
                      >
                        <TextInput
                          style={[
                            styles.styleinput,
                            {
                              width: 315,
                            },
                          ]}
                        />
                      </View>
                    </View>
                    <View>
                      <Text style={[styles.styletextAll, { marginLeft: 5 }]}>
                        เวลารับรถ
                      </Text>
                      <View
                        style={[
                          styles.styleinput,
                          {
                            width: 309,
                            marginLeft: 5,
                          },
                        ]}
                      >
                        <TextInput
                          style={[
                            styles.styleinput,
                            {
                              width: 309,
                            },
                          ]}
                        />
                      </View>
                    </View>
                  </View>
                  <Text style={[styles.styletextAll, { marginLeft: 5 }]}>
                    ราคาสถานที่รับรถ
                  </Text>
                  <View
                    style={[
                      styles.styleinput,
                      {
                        width: 309,
                        marginLeft: 5,
                      },
                    ]}
                  >
                    <TextInput
                      style={[
                        styles.styleinput,
                        {
                          width: 309,
                        },
                      ]}
                    />
                  </View>
                  <View style={{ flexDirection: "row" }}>
                    <View>
                      <Text style={styles.styletextAll}>
                        สถานที่คืนรถ Drop-off
                      </Text>
                      <Text
                        style={[
                          styles.styleinput,
                          {
                            width: 315,
                          },
                        ]}
                      >
                        <TextInput
                          style={[
                            styles.styleinput,
                            {
                              width: 315,
                            },
                          ]}
                        />
                      </Text>
                    </View>
                    <View>
                      <Text style={[styles.styletextAll, { marginLeft: 5 }]}>
                        เวลาคืนรถ
                      </Text>
                      <View
                        style={[
                          styles.styleinput,
                          {
                            width: 309,
                            marginLeft: 5,
                          },
                        ]}
                      >
                        <TextInput
                          style={[
                            styles.styleinput,
                            {
                              width: 309,
                            },
                          ]}
                        />
                      </View>
                    </View>
                  </View>

                  <Text style={styles.styletextAll}>ราคาสถานที่ส่งรถ</Text>
                  <View
                    style={[
                      styles.styleinput,
                      {
                        width: 315,
                      },
                    ]}
                  >
                    <TextInput
                      style={[
                        styles.styleinput,
                        {
                          width: 315,
                        },
                      ]}
                    />
                  </View>

                  <View style={{ flexDirection: "row" }}>
                    <View>
                      <Text style={styles.styletextAll}>วันที่เช่า</Text>
                      <View
                        style={[
                          styles.styleinput,
                          {
                            width: 315,
                          },
                        ]}
                      >
                        <TextInput
                          style={[
                            styles.styleinput,
                            {
                              width: 315,
                            },
                          ]}
                        />
                      </View>
                    </View>
                    <View>
                      <Text style={[styles.styletextAll, { marginLeft: 5 }]}>
                        วันที่คืน
                      </Text>
                      <View
                        style={[
                          styles.styleinput,
                          {
                            width: 309,
                            marginLeft: 5,
                          },
                        ]}
                      >
                        <TextInput
                          style={[
                            styles.styleinput,
                            {
                              width: 309,
                            },
                          ]}
                        />
                      </View>
                    </View>
                  </View>
                  <Text style={styles.styletextAll}>ประกันจาก Rent Mocy</Text>
                  <View style={{ flexDirection: "row" }}>
                    {checkbox ? (
                      <TouchableOpacity
                        onPress={() => {
                          setcheckbox((val) => !val);
                        }}
                      >
                        <AntDesign
                          name="checksquareo"
                          size={24}
                          color="#EA640D"
                        />
                      </TouchableOpacity>
                    ) : (
                      <TouchableOpacity
                        onPress={() => {
                          setcheckbox((val) => !val);
                        }}
                      >
                        <MaterialCommunityIcons
                          name="checkbox-blank"
                          size={24}
                          color="#F3F3F3"
                        />
                      </TouchableOpacity>
                    )}
                    <Text
                      style={{
                        fontSize: 18,
                        color: "#A1A1A1",
                        fontFamily: "Kanit-Regular",
                        marginHorizontal: 4,
                      }}
                    >
                      มีประกัน
                    </Text>

                    {checkbox ? (
                      <TouchableOpacity
                        onPress={() => {
                          setcheckbox((val) => !val);
                        }}
                      >
                        <AntDesign
                          name="checksquareo"
                          size={24}
                          color="#EA640D"
                        />
                      </TouchableOpacity>
                    ) : (
                      <TouchableOpacity
                        onPress={() => {
                          setcheckbox((val) => !val);
                        }}
                      >
                        <MaterialCommunityIcons
                          name="checkbox-blank"
                          size={24}
                          color="#F3F3F3"
                        />
                      </TouchableOpacity>
                    )}
                    <Text
                      style={{
                        fontSize: 18,
                        color: "#A1A1A1",
                        fontFamily: "Kanit-Regular",
                        marginHorizontal: 4,
                      }}
                    >
                      ไม่มีประกัน
                    </Text>
                    {checkbox ? (
                      <TouchableOpacity
                        onPress={() => {
                          setcheckbox((val) => !val);
                        }}
                      >
                        <AntDesign
                          name="checksquareo"
                          size={24}
                          color="#EA640D"
                        />
                      </TouchableOpacity>
                    ) : (
                      <TouchableOpacity
                        onPress={() => {
                          setcheckbox((val) => !val);
                        }}
                      >
                        <MaterialCommunityIcons
                          name="checkbox-blank"
                          size={24}
                          color="#F3F3F3"
                        />
                      </TouchableOpacity>
                    )}
                    <Text
                      style={{
                        fontSize: 18,
                        color: "#A1A1A1",
                        fontFamily: "Kanit-Regular",
                        marginHorizontal: 4,
                      }}
                    >
                      สนใจประกันเพิ่มเติม
                    </Text>
                  </View>

                  <Text style={styles.styletextAll}>GPS</Text>
                  <View style={{ flexDirection: "row" }}>
                    {checkbox ? (
                      <TouchableOpacity
                        onPress={() => {
                          setcheckbox((val) => !val);
                        }}
                      >
                        <AntDesign
                          name="checksquareo"
                          size={24}
                          color="#EA640D"
                        />
                      </TouchableOpacity>
                    ) : (
                      <TouchableOpacity
                        onPress={() => {
                          setcheckbox((val) => !val);
                        }}
                      >
                        <MaterialCommunityIcons
                          name="checkbox-blank"
                          size={24}
                          color="#F3F3F3"
                        />
                      </TouchableOpacity>
                    )}
                    <Text
                      style={{
                        fontSize: 18,
                        color: "#A1A1A1",
                        fontFamily: "Kanit-Regular",
                        marginHorizontal: 7,
                      }}
                    >
                      มี GPS
                    </Text>

                    {checkbox ? (
                      <TouchableOpacity
                        style={{ marginHorizontal: 4 }}
                        onPress={() => {
                          setcheckbox((val) => !val);
                        }}
                      >
                        <AntDesign
                          name="checksquareo"
                          size={24}
                          color="#EA640D"
                        />
                      </TouchableOpacity>
                    ) : (
                      <TouchableOpacity
                        style={{ marginHorizontal: 4 }}
                        onPress={() => {
                          setcheckbox((val) => !val);
                        }}
                      >
                        <MaterialCommunityIcons
                          name="checkbox-blank"
                          size={24}
                          color="#F3F3F3"
                        />
                      </TouchableOpacity>
                    )}
                    <Text
                      style={{
                        fontSize: 18,
                        color: "#A1A1A1",
                        fontFamily: "Kanit-Regular",
                        marginHorizontal: 4,
                      }}
                    >
                      ไม่มี GPS
                    </Text>
                    {checkbox ? (
                      <TouchableOpacity
                        style={{ marginHorizontal: 4 }}
                        onPress={() => {
                          setcheckbox((val) => !val);
                        }}
                      >
                        <AntDesign
                          name="checksquareo"
                          size={24}
                          color="#EA640D"
                        />
                      </TouchableOpacity>
                    ) : (
                      <TouchableOpacity
                        style={{ marginHorizontal: 4 }}
                        onPress={() => {
                          setcheckbox((val) => !val);
                        }}
                      >
                        <MaterialCommunityIcons
                          name="checkbox-blank"
                          size={24}
                          color="#F3F3F3"
                        />
                      </TouchableOpacity>
                    )}
                    <Text
                      style={{
                        fontSize: 18,
                        color: "#A1A1A1",
                        fontFamily: "Kanit-Regular",
                        marginHorizontal: 4,
                      }}
                    >
                      สนใจติดตั้ง GPS
                    </Text>
                  </View>
                  <View style={{ flexDirection: "row" }}>
                    <View>
                      <Text style={styles.styletextAll}>ยอดชำระ</Text>
                      <View
                        style={[
                          styles.styleinput,
                          {
                            width: 315,
                          },
                        ]}
                      >
                        <TextInput
                          style={[
                            styles.styleinput,
                            {
                              width: 315,
                            },
                          ]}
                        />
                      </View>
                    </View>
                    <View>
                      <Text style={[styles.styletextAll, { marginLeft: 5 }]}>
                        คำขอยกเลิก
                      </Text>
                      <View
                        style={[
                          styles.styleinput,
                          {
                            width: 309,
                            marginLeft: 5,
                          },
                        ]}
                      >
                        <TextInput
                          style={[
                            styles.styleinput,
                            {
                              width: 309,
                            },
                          ]}
                        />
                      </View>
                    </View>
                  </View>

                  <View style={{ flexDirection: "row" }}>
                    <View>
                      <Text style={styles.styletextAll}>วิธีชำระเงิน</Text>
                      <View
                        style={[
                          styles.styleinput,
                          {
                            width: 315,
                          },
                        ]}
                      >
                        <TextInput
                          style={[
                            styles.styleinput,
                            {
                              width: 315,
                            },
                          ]}
                        />
                      </View>
                    </View>
                    <View>
                      <Text style={[styles.styletextAll, { marginLeft: 5 }]}>
                        วันที่ทำรายการ
                      </Text>
                      <View
                        style={[
                          styles.styleinput,
                          {
                            width: 309,
                            marginLeft: 5,
                          },
                        ]}
                      >
                        <TextInput
                          style={[
                            styles.styleinput,
                            {
                              width: 309,
                            },
                          ]}
                        />
                      </View>
                    </View>
                  </View>
                  <Text style={styles.styletextAll}>สถานะ</Text>
                  <View
                    style={[
                      styles.styleinput,
                      {
                        width: 315,
                      },
                    ]}
                  >
                    <TextInput
                      style={[
                        styles.styleinput,
                        {
                          width: 315,
                        },
                      ]}
                    />
                  </View>
                </View>
              </View>

              <Text
                style={{
                  fontSize: 30,
                  color: "#EA640D",
                  fontFamily: "Kanit-Regular",
                }}
              >
                ยอดชำระเพิ่มเติม
              </Text>
              <View
                style={{
                  width: 678,
                  height: 200,
                  backgroundColor: "#E7E7E7",
                  borderRadius: 5,
                }}
              >
                <View style={{ width: 650, alignSelf: "center" }}>
                  <View style={{ flexDirection: "row" }}>
                    <View>
                      <Text style={styles.styletextAll}>ยอดชำระ</Text>
                      <View
                        style={[
                          styles.styleinput,
                          {
                            width: 315,
                          },
                        ]}
                      >
                        <TextInput
                          style={[
                            styles.styleinput,
                            {
                              width: 315,
                            },
                          ]}
                        />
                      </View>
                    </View>
                    <View>
                      <Text style={[styles.styletextAll, { marginLeft: 5 }]}>
                        คำขอยกเลิก
                      </Text>
                      <View
                        style={[
                          styles.styleinput,
                          {
                            width: 309,
                            marginLeft: 5,
                          },
                        ]}
                      >
                        <TextInput
                          style={[
                            styles.styleinput,
                            {
                              width: 309,
                            },
                          ]}
                        />
                      </View>
                    </View>
                  </View>
                  <View style={{ flexDirection: "row" }}>
                    <View>
                      <Text style={styles.styletextAll}>วิธีชำระเงิน</Text>
                      <View
                        style={[
                          styles.styleinput,
                          {
                            width: 315,
                          },
                        ]}
                      >
                        <TextInput
                          style={[
                            styles.styleinput,
                            {
                              width: 315,
                            },
                          ]}
                        />
                      </View>
                    </View>
                    <View>
                      <Text style={[styles.styletextAll, { marginLeft: 5 }]}>
                        วันที่ทำรายการ
                      </Text>
                      <View
                        style={[
                          styles.styleinput,
                          {
                            width: 309,
                            marginLeft: 5,
                          },
                        ]}
                      >
                        <TextInput
                          style={[
                            styles.styleinput,
                            {
                              width: 309,
                            },
                          ]}
                        />
                      </View>
                    </View>
                  </View>
                  <View style={{ flexDirection: "row" }}>
                    <View>
                      <Text style={[styles.styletextAll, { marginLeft: 5 }]}>
                        หมายเหตุ
                      </Text>
                      <View
                        style={[
                          styles.styleinput,
                          {
                            width: 309,
                            marginLeft: 5,
                          },
                        ]}
                      >
                        <TextInput
                          style={[
                            styles.styleinput,
                            {
                              width: 309,
                            },
                          ]}
                        />
                      </View>
                    </View>
                    <TouchableOpacity
                      style={{
                        width: 192,
                        height: 29,
                        backgroundColor: "#EA640D",
                        marginHorizontal: 8,
                        borderRadius: 5,
                        marginTop: 25,
                      }}
                    >
                      <Text
                        style={{
                          fontSize: 20,
                          fontFamily: "Kanit-Regular",
                          color: "#ffffff",
                          textAlign: "center",
                        }}
                      >
                        เรียกเก็บเงินเพิ่ม
                      </Text>
                    </TouchableOpacity>
                  </View>
                </View>
              </View>

              <View
                style={{
                  flexDirection: "row",
                  justifyContent: "center",
                  marginTop: 20,
                }}
              >
                <TouchableOpacity
                  style={{
                    width: 232,
                    height: 37,
                    backgroundColor: "#036BB2",
                    marginHorizontal: 10,
                    borderRadius: 5,
                    justifyContent: "center",
                  }}
                >
                  <Text
                    style={{
                      fontSize: 20,
                      fontFamily: "Kanit-Regular",
                      color: "#ffffff",
                      textAlign: "center",
                    }}
                  >
                    ส่ง E-mail ให้ร้านค้า
                  </Text>
                </TouchableOpacity>

                <TouchableOpacity
                  style={{
                    width: 232,
                    height: 37,
                    backgroundColor: "#036BB2",
                    marginHorizontal: 10,
                    borderRadius: 5,
                    justifyContent: "center",
                  }}
                >
                  <Text
                    style={{
                      fontSize: 20,
                      fontFamily: "Kanit-Regular",
                      color: "#ffffff",
                      textAlign: "center",
                    }}
                  >
                    ส่ง E-mail ให้ผู้เช่า
                  </Text>
                </TouchableOpacity>
              </View>

              <View
                style={{ flexDirection: "row", justifyContent: "flex-end" }}
              >
                <TouchableOpacity
                  onPress={() => {
                    setEdModalVisible(false);
                  }}
                  style={{
                    width: 91,
                    height: 32,
                    backgroundColor: "#E7E7E7",
                    alignSelf: "flex-end",
                    marginTop: 15,
                    borderRadius: 5,
                    marginHorizontal: 5,
                  }}
                >
                  <Text
                    style={{
                      fontSize: 20,
                      fontFamily: "Kanit-Regular",
                      color: "#A1A1A1",
                      textAlign: "center",
                    }}
                  >
                    Close
                  </Text>
                </TouchableOpacity>

                <TouchableOpacity
                  onPress={() => {
                    setEdModalVisible(false);
                  }}
                  style={{
                    width: 91,
                    height: 32,
                    backgroundColor: "#EA640D",
                    alignSelf: "flex-end",
                    marginTop: 15,
                    borderRadius: 5,
                    marginHorizontal: 5,
                  }}
                >
                  <Text
                    style={{
                      fontSize: 20,
                      fontFamily: "Kanit-Regular",
                      color: "#ffffff",
                      textAlign: "center",
                    }}
                  >
                    Save
                  </Text>
                </TouchableOpacity>
              </View>
            </View>
          </View>
        </ScrollView>
      </Modal> */}

      <View
        style={{
          height: 138,
          backgroundColor: "#036BB2",
        }}
      >
        <Text style={[styles.styletext, { color: "#ffff", marginLeft: 40 }]}>
          History
        </Text>
        <View style={styles.styleViewAll}>
          <View style={{ flexDirection: "row" }}>
            <View
              style={{
                width: "70%",
                maxWidth: 587,
                height: 58,
                backgroundColor: "#E7E7E7",
                borderRadius: 5,
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <FontAwesome
                name="search"
                size={35}
                color="black"
                style={{ marginHorizontal: 8 }}
              />
              <TextInput
                style={{
                  width: "50%",
                  maxWidth: 550,
                  height: 58,
                  fontSize: 30,
                  fontFamily: "Kanit-Regular",
                }}
                placeholder={"ค้นหา"}
                defaultValue={search}
                onChangeText={(text) => {
                  setsearch(text);
                }}
              />
            </View>

            <TouchableOpacity
              onPress={() => {
                Linking.openURL(
                  "https://api.rentmocy.com/api/market/exporthistoryxlxs"
                );
                // Linking.openURL("http://localhost:5000/api/market/exportxlxs");
              }}
              style={{
                width: "50%",
                maxWidth: 157,
                height: 59,
                backgroundColor: "#EA640D",
                justifyContent: "center",
                borderRadius: 5,
                marginLeft: 35,
              }}
            >
              <Text
                style={{
                  fontSize: 30,
                  color: "#ffffff",
                  fontFamily: "Kanit-Regular",
                  textAlign: "center",
                }}
              >
                Export
              </Text>
            </TouchableOpacity>
          </View>

          {/* Header */}
          <View
            style={{
              width: "100%",
              alignItems: "center",
              backgroundColor: "#036BB2",
              height: 60,
              borderRadius: 5,
              marginTop: 15,
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <Text
              style={[
                styles.textTitle,
                { width: "20%", fontSize: size.width < 1080 ? 18 : 20 },
              ]}
            >
              หมายเลขการจอง
            </Text>

            <Text
              style={[
                styles.textTitle,
                {
                  width: "20%",
                  fontSize: size.width < 1080 ? 18 : 20,
                  textAlign: "left",
                },
              ]}
            >
              รถที่เช่า
            </Text>

            <Text
              style={[
                styles.textTitle,
                {
                  width: "20%",
                  fontSize: size.width < 1080 ? 18 : 20,
                  textAlign: "left",
                },
              ]}
            >
              ชื่อร้านค้า
            </Text>

            <Text
              style={[
                styles.textTitle,
                {
                  width: "10%",
                  fontSize: size.width < 1080 ? 18 : 20,
                },
              ]}
            >
              ราคา
            </Text>

            <Text
              style={[
                styles.textTitle,
                {
                  width: "10%",
                  fontSize: size.width < 1080 ? 18 : 20,
                },
              ]}
            >
              ผู้เช่า
            </Text>

            <Text
              style={[
                styles.textTitle,
                {
                  width: "10%",
                  fontSize: size.width < 1080 ? 18 : 20,
                },
              ]}
            >
              สถานะ
            </Text>

            <Text
              style={[
                styles.textTitle,
                {
                  width: "10%",
                  fontSize: size.width < 1080 ? 18 : 20,
                },
              ]}
            >
              ข้อมูล
            </Text>

            {/* <Text
              style={[
                styles.textTitle,
                {
                  width: "10%",
                  fontSize: size.width < 1080 ? 18 : 20,
                },
              ]}
            >
              แก้ไข
            </Text> */}

            <Text
              style={[
                styles.textTitle,
                {
                  width: "10%",
                  fontSize: size.width < 1080 ? 18 : 20,
                },
              ]}
            >
              ลบ
            </Text>
          </View>

          {/* data */}

          <FlatList
            data={data.filter(
              (item) =>
                item.name.toLowerCase().includes(search) ||
                item.marketname.toLowerCase().includes(search)
            )}
            renderItem={({ item }) => {
              return (
                <View
                  style={{
                    width: "100%",
                    alignItems: "center",
                    backgroundColor: "#036BB210",
                    height: 60,
                    borderRadius: 5,
                    marginTop: 15,
                    flexDirection: "row",
                    justifyContent: "space-between",
                  }}
                >
                  <Text
                    style={[
                      styles.textDetail,
                      { width: "20%", fontSize: size.width < 1080 ? 18 : 20 },
                    ]}
                  >
                    # {item.id + 100000}
                  </Text>

                  <Text
                    style={[
                      styles.textDetail,
                      {
                        width: "20%",
                        fontSize: size.width < 1080 ? 18 : 20,
                        textAlign: "left",
                      },
                    ]}
                  >
                    {item.name}
                  </Text>

                  <Text
                    style={[
                      styles.textDetail,
                      {
                        width: "20%",
                        fontSize: size.width < 1080 ? 18 : 20,
                        textAlign: "left",
                      },
                    ]}
                  >
                    {item.marketname}
                  </Text>

                  <Text
                    style={[
                      styles.textDetail,
                      {
                        width: "10%",
                        fontSize: size.width < 1080 ? 18 : 20,
                      },
                    ]}
                  >
                    {item.total_price}฿
                  </Text>

                  <Text
                    style={[
                      styles.textDetail,
                      {
                        width: "10%",
                        fontSize: size.width < 1080 ? 18 : 20,
                      },
                    ]}
                  >
                    {item.username}
                  </Text>

                  <Text
                    style={[
                      styles.textDetail,
                      {
                        width: "10%",
                        fontSize: size.width < 1080 ? 18 : 20,
                      },
                    ]}
                  >
                    สถานะ
                  </Text>

                  <TouchableOpacity
                    style={{
                      width: "9%",
                      alignItems: "center",
                    }}
                    onPress={async () => {
                      // const api = await apiservice({
                      //   path: "/market/history/getall",
                      //   method: "get",
                      // });

                      setItem(item);
                      setModalVisible(true);
                    }}
                  >
                    <Foundation
                      name="clipboard-notes"
                      size={30}
                      color="#A1A1A1"
                    />
                  </TouchableOpacity>
                  {/* 
                  <TouchableOpacity
                    style={{
                      width: "8%",
                      alignItems: "center",
                    }}
                    onPress={() => {
                      // setItem(item);
                      setEdModalVisible(true);
                    }}
                  >
                    <FontAwesome5 name="edit" size={25} color="#A1A1A1" />
                  </TouchableOpacity> */}
                  <TouchableOpacity
                    style={{
                      width: "9%",
                      alignItems: "center",
                    }}
                    onPress={() => {}}
                  >
                    <AntDesign name="delete" size={30} color="#A1A1A1" />
                  </TouchableOpacity>
                </View>
              );
            }}
          />
        </View>
      </View>
    </View>
  );
};
const styles = StyleSheet.create({
  styletext: {
    fontSize: 36,
    fontFamily: "Kanit-Regular",
    marginHorizontal: 10,
  },

  styleViewAll: {
    height: 800,
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 3.84,
    elevation: 5,
    marginHorizontal: 20,
    borderRadius: 10,
    marginTop: 10,
    padding: 20,
    backgroundColor: "#fff",
  },
  styletextAll: {
    fontSize: 18,
    color: "#EA640D",
    fontFamily: "Kanit-Regular",
  },
  styleinput: {
    height: 29,
    backgroundColor: "#FFFFFF50",
    fontSize: 18,
    fontFamily: "Kanit-Regular",
  },
  textTitle: {
    color: "#ffffff",
    fontFamily: "Kanit-Regular",
    textAlign: "center",
  },
  textDetail: {
    color: "#000000",
    fontFamily: "Kanit-Regular",
    textAlign: "center",
  },
});
export default page1;
