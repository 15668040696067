import React, { useRef, useState } from "react";
import {
  StyleSheet,
  Text,
  View,
  TouchableOpacity,
  Dimensions,
  SafeAreaView,
  TextInput,
  FlatList,
  ScrollView,
  Image,
} from "react-native";
import {
  widthPercentageToDP as wp,
  heightPercentageToDP as hp,
} from "react-native-responsive-screen";
import {
  AntDesign,
  Entypo,
  MaterialCommunityIcons,
  FontAwesome,
  FontAwesome5,
  Foundation,
} from "@expo/vector-icons";
const { width, height } = Dimensions.get("window");

import Modal from "modal-enhanced-react-native-web";

const data = [{ ID: 1 }, { ID: 2 }, { ID: 3 }];
const pageoption1 = () => {
  const [modalVisible, setModalVisible] = useState(false);
  const [modalVisibleED, setModalVisibleED] = useState(false);
  const [modalVisibleADD, setModalVisibleADD] = useState(false);

  return (
    <View>
      <Modal
        isVisible={modalVisible}
        onBackdropPress={() => setModalVisible((val) => !val)}
      >
        <ScrollView>
          <View style={{ flex: 1, justifyContent: "center" }}>
            <View
              style={{
                width: 734,
                backgroundColor: "#fff",
                alignSelf: "center",
                borderRadius: 10,
                paddingTop: 10,
                height: 511,
                marginTop: 100,
                justifyContent: "center",
              }}
            >
              <View
                style={{
                  width: 675,
                  alignSelf: "center",
                  justifyContent: "center",
                }}
              >
                <Text
                  style={{
                    fontSize: 30,
                    color: "#EA640D",
                    fontFamily: "Kanit-Regular",
                  }}
                >
                 ข้อมูล Code
                </Text>

                <View
                  style={{
                    width: 678,
                    height: 350,
                    backgroundColor: "#E7E7E7",
                    borderRadius: 10,
                    justifyContent: "center",
                  }}
                >
                  <View style={{ width: 650, alignSelf: "center" }}>
                    <Text style={styles.styletextAll}>ชื่อ</Text>
                    <View
                      style={[
                        styles.styleinput,
                        {
                          width: 640,
                        },
                      ]}
                    >
                      <TextInput
                        style={[
                          styles.styleinput,
                          {
                            width: 640,
                          },
                        ]}
                      />
                    </View>
                    {/* <Text style={styles.styletextAll}>ชื่อ - สกุล</Text>
                    <View
                      style={[
                        styles.styleinput,
                        {
                          width: 640,
                        },
                      ]}
                    >
                      <TextInput
                        style={[
                          styles.styleinput,
                          {
                            width: 640,
                          },
                        ]}
                      />
                    </View> */}

                    <View style={{ flexDirection: "row" }}>
                      <View>
                        <Text style={styles.styletextAll}>ส่วนลด</Text>
                        <View
                          style={[
                            styles.styleinput,
                            {
                              width: 315,
                            },
                          ]}
                        >
                          <TextInput
                            style={[
                              styles.styleinput,
                              {
                                width: 315,
                              },
                            ]}
                          />
                        </View>
                      </View>
                      <View>
                        <Text style={[styles.styletextAll, { marginLeft: 20 }]}>
                        รหัส Code
                        </Text>
                        <View
                          style={[
                            styles.styleinput,
                            {
                              width: 309,
                              marginLeft: 20,
                            },
                          ]}
                        >
                          <TextInput
                            style={[
                              styles.styleinput,
                              {
                                width: 309,
                              },
                            ]}
                          />
                        </View>
                      </View>
                    </View>

                    <View style={{ flexDirection: "row" }}>
                      <View>
                        <Text style={styles.styletextAll}>สถานะ</Text>
                        <View
                          style={[
                            styles.styleinput,
                            {
                              width: 315,
                            },
                          ]}
                        >
                          <TextInput
                            style={[
                              styles.styleinput,
                              {
                                width: 315,
                              },
                            ]}
                          />
                        </View>
                      </View>
                      <View>
                        <Text style={[styles.styletextAll, { marginLeft: 20 }]}>
                          เพิ่มโดย
                        </Text>
                        <View
                          style={[
                            styles.styleinput,
                            {
                              width: 309,
                              marginLeft: 20,
                            },
                          ]}
                        >
                          <TextInput
                            style={[
                              styles.styleinput,
                              {
                                width: 309,
                              },
                            ]}
                          />
                        </View>
                      </View>
                    </View>


                    <View style={{ flexDirection: "row" }}>
                      <View>
                        <Text style={styles.styletextAll}>จำนวน Code</Text>
                        <View
                          style={[
                            styles.styleinput,
                            {
                              width: 315,
                            },
                          ]}
                        >
                          <TextInput
                            style={[
                              styles.styleinput,
                              {
                                width: 315,
                              },
                            ]}
                          />
                        </View>
                      </View>
                      <View>
                        <Text style={[styles.styletextAll, { marginLeft: 20 }]}>
                        จำนวน Code ที่เหลือ
                        </Text>
                        <View
                          style={[
                            styles.styleinput,
                            {
                              width: 309,
                              marginLeft: 20,
                            },
                          ]}
                        >
                          <TextInput
                            style={[
                              styles.styleinput,
                              {
                                width: 309,
                              },
                            ]}
                          />
                        </View>
                      </View>
                    </View>



                    <View style={{ flexDirection: "row" }}>
                      <View>
                        <Text style={styles.styletextAll}>เพิ่มเมื่อ</Text>
                        <View
                          style={[
                            styles.styleinput,
                            {
                              width: 315,
                            },
                          ]}
                        >
                          <TextInput
                            style={[
                              styles.styleinput,
                              {
                                width: 315,
                              },
                            ]}
                          />
                        </View>
                      </View>
                      <View>
                        <Text style={[styles.styletextAll, { marginLeft: 20 }]}>
                        เพิ่มโดย
                        </Text>
                        <View
                          style={[
                            styles.styleinput,
                            {
                              width: 309,
                              marginLeft: 20,
                            },
                          ]}
                        >
                          <TextInput
                            style={[
                              styles.styleinput,
                              {
                                width: 309,
                              },
                            ]}
                          />
                        </View>
                      </View>
                    </View>
                  </View>
                </View>

                <TouchableOpacity
                  onPress={() => {
                    setModalVisible((val) => !val);
                  }}
                  style={{
                    width: 84,
                    height: 23,
                    backgroundColor: "#EA640D",
                    justifyContent: "center",
                    marginHorizontal: 5,
                    marginVertical: 10,
                    alignSelf: "flex-end",
                  }}
                >
                  <Text
                    style={{
                      fontFamily: "Kanit-Regular",
                      fontSize: 12,
                      textAlign: "center",
                      color: "#ffff",
                    }}
                  >
                    SAVE
                  </Text>
                </TouchableOpacity>
              </View>
            </View>
          </View>
        </ScrollView>
      </Modal>







      <Modal
        isVisible={modalVisibleED}
        onBackdropPress={() => setModalVisibleED((val) => !val)}
      >
        <ScrollView>
          <View style={{ flex: 1, justifyContent: "center" }}>
            <View
              style={{
                width: 734,
                backgroundColor: "#fff",
                alignSelf: "center",
                borderRadius: 10,
                paddingTop: 10,
                height: 511,
                marginTop: 100,
                justifyContent: "center",
              }}
            >
              <View
                style={{
                  width: 675,
                  alignSelf: "center",
                  justifyContent: "center",
                }}
              >
                <Text
                  style={{
                    fontSize: 30,
                    color: "#EA640D",
                    fontFamily: "Kanit-Regular",
                  }}
                >
                 ข้อมูล Code
                </Text>

                <View
                  style={{
                    width: 678,
                    height: 350,
                    backgroundColor: "#E7E7E7",
                    borderRadius: 10,
                    justifyContent: "center",
                  }}
                >
                  <View style={{ width: 650, alignSelf: "center" }}>
                    <Text style={styles.styletextAll}>ชื่อ</Text>
                    <View
                      style={[
                        styles.styleinput,
                        {
                          width: 640,
                        },
                      ]}
                    >
                      <TextInput
                        style={[
                          styles.styleinput,
                          {
                            width: 640,
                          },
                        ]}
                      />
                    </View>
                    {/* <Text style={styles.styletextAll}>ชื่อ - สกุล</Text>
                    <View
                      style={[
                        styles.styleinput,
                        {
                          width: 640,
                        },
                      ]}
                    >
                      <TextInput
                        style={[
                          styles.styleinput,
                          {
                            width: 640,
                          },
                        ]}
                      />
                    </View> */}

                    <View style={{ flexDirection: "row" }}>
                      <View>
                        <Text style={styles.styletextAll}>ส่วนลด</Text>
                        <View
                          style={[
                            styles.styleinput,
                            {
                              width: 315,
                            },
                          ]}
                        >
                          <TextInput
                            style={[
                              styles.styleinput,
                              {
                                width: 315,
                              },
                            ]}
                          />
                        </View>
                      </View>
                      <View>
                        <Text style={[styles.styletextAll, { marginLeft: 20 }]}>
                        รหัส Code
                        </Text>
                        <View
                          style={[
                            styles.styleinput,
                            {
                              width: 309,
                              marginLeft: 20,
                            },
                          ]}
                        >
                          <TextInput
                            style={[
                              styles.styleinput,
                              {
                                width: 309,
                              },
                            ]}
                          />
                        </View>
                      </View>
                    </View>

                    <View style={{ flexDirection: "row" }}>
                      <View>
                        <Text style={styles.styletextAll}>สถานะ</Text>
                        <View
                          style={[
                            styles.styleinput,
                            {
                              width: 315,
                            },
                          ]}
                        >
                          <TextInput
                            style={[
                              styles.styleinput,
                              {
                                width: 315,
                              },
                            ]}
                          />
                        </View>
                      </View>
                      <View>
                        <Text style={[styles.styletextAll, { marginLeft: 20 }]}>
                          เพิ่มโดย
                        </Text>
                        <View
                          style={[
                            styles.styleinput,
                            {
                              width: 309,
                              marginLeft: 20,
                            },
                          ]}
                        >
                          <TextInput
                            style={[
                              styles.styleinput,
                              {
                                width: 309,
                              },
                            ]}
                          />
                        </View>
                      </View>
                    </View>


                    <View style={{ flexDirection: "row" }}>
                      <View>
                        <Text style={styles.styletextAll}>จำนวน Code</Text>
                        <View
                          style={[
                            styles.styleinput,
                            {
                              width: 315,
                            },
                          ]}
                        >
                          <TextInput
                            style={[
                              styles.styleinput,
                              {
                                width: 315,
                              },
                            ]}
                          />
                        </View>
                      </View>
                      <View>
                        <Text style={[styles.styletextAll, { marginLeft: 20 }]}>
                        จำนวน Code ที่เหลือ
                        </Text>
                        <View
                          style={[
                            styles.styleinput,
                            {
                              width: 309,
                              marginLeft: 20,
                            },
                          ]}
                        >
                          <TextInput
                            style={[
                              styles.styleinput,
                              {
                                width: 309,
                              },
                            ]}
                          />
                        </View>
                      </View>
                    </View>



                    <View style={{ flexDirection: "row" }}>
                      <View>
                        <Text style={styles.styletextAll}>เพิ่มเมื่อ</Text>
                        <View
                          style={[
                            styles.styleinput,
                            {
                              width: 315,
                            },
                          ]}
                        >
                          <TextInput
                            style={[
                              styles.styleinput,
                              {
                                width: 315,
                              },
                            ]}
                          />
                        </View>
                      </View>
                      <View>
                        <Text style={[styles.styletextAll, { marginLeft: 20 }]}>
                        เพิ่มโดย
                        </Text>
                        <View
                          style={[
                            styles.styleinput,
                            {
                              width: 309,
                              marginLeft: 20,
                            },
                          ]}
                        >
                          <TextInput
                            style={[
                              styles.styleinput,
                              {
                                width: 309,
                              },
                            ]}
                          />
                        </View>
                      </View>
                    </View>
                  </View>
                </View>

                <View style={{ flexDirection: "row", alignSelf: "flex-end" }}>
                  <TouchableOpacity
                    onPress={() => {
                      setModalVisibleED((val) => !val);
                    }}
                    style={{
                      width: 84,
                      height: 23,
                      backgroundColor: "#A1A1A1",
                      justifyContent: "center",
                      marginVertical: 10,
                      marginHorizontal: 5,
                    }}
                  >
                    <Text
                      style={{
                        fontFamily: "Kanit-Regular",
                        fontSize: 12,
                        textAlign: "center",
                        color: "#ffff",
                      }}
                    >
                      Close
                    </Text>
                  </TouchableOpacity>

                  <TouchableOpacity
                    onPress={() => {
                      setModalVisibleED((val) => !val);
                    }}
                    style={{
                      width: 84,
                      height: 23,
                      backgroundColor: "#EA640D",
                      justifyContent: "center",
                      marginHorizontal: 5,
                      marginVertical: 10,
                    }}
                  >
                    <Text
                      style={{
                        fontFamily: "Kanit-Regular",
                        fontSize: 12,
                        textAlign: "center",
                        color: "#ffff",
                      }}
                    >
                      SAVE
                    </Text>
                  </TouchableOpacity>
                </View>
              </View>
            </View>
          </View>
        </ScrollView>
      </Modal>


  


      <Modal
        isVisible={modalVisibleADD}
        onBackdropPress={() => setModalVisibleADD((val) => !val)}
      >
        <ScrollView>
          <View style={{ flex: 1, justifyContent: "center" }}>
            <View
              style={{
                width: 734,
                backgroundColor: "#fff",
                alignSelf: "center",
                borderRadius: 10,
                paddingTop: 10,
                height: 511,
                marginTop: 100,
                justifyContent: "center",
              }}
            >
              <View
                style={{
                  width: 675,
                  alignSelf: "center",
                  justifyContent: "center",
                }}
              >
                <Text
                  style={{
                    fontSize: 30,
                    color: "#EA640D",
                    fontFamily: "Kanit-Regular",
                  }}
                >
                 ข้อมูล Code
                </Text>

                <View
                  style={{
                    width: 678,
                    height: 350,
                    backgroundColor: "#E7E7E7",
                    borderRadius: 10,
                    justifyContent: "center",
                  }}
                >
                  <View style={{ width: 650, alignSelf: "center" }}>
                    <Text style={styles.styletextAll}>ชื่อ</Text>
                    <View
                      style={[
                        styles.styleinput,
                        {
                          width: 640,
                        },
                      ]}
                    >
                      <TextInput
                        style={[
                          styles.styleinput,
                          {
                            width: 640,
                          },
                        ]}
                      />
                    </View>
                    {/* <Text style={styles.styletextAll}>ชื่อ - สกุล</Text>
                    <View
                      style={[
                        styles.styleinput,
                        {
                          width: 640,
                        },
                      ]}
                    >
                      <TextInput
                        style={[
                          styles.styleinput,
                          {
                            width: 640,
                          },
                        ]}
                      />
                    </View> */}

                    <View style={{ flexDirection: "row" }}>
                      <View>
                        <Text style={styles.styletextAll}>ส่วนลด</Text>
                        <View
                          style={[
                            styles.styleinput,
                            {
                              width: 315,
                            },
                          ]}
                        >
                          <TextInput
                            style={[
                              styles.styleinput,
                              {
                                width: 315,
                              },
                            ]}
                          />
                        </View>
                      </View>
                      <View>
                        <Text style={[styles.styletextAll, { marginLeft: 20 }]}>
                        รหัส Code
                        </Text>
                        <View
                          style={[
                            styles.styleinput,
                            {
                              width: 309,
                              marginLeft: 20,
                            },
                          ]}
                        >
                          <TextInput
                            style={[
                              styles.styleinput,
                              {
                                width: 309,
                              },
                            ]}
                          />
                        </View>
                      </View>
                    </View>

                    <View style={{ flexDirection: "row" }}>
                      <View>
                        <Text style={styles.styletextAll}>สถานะ</Text>
                        <View
                          style={[
                            styles.styleinput,
                            {
                              width: 315,
                            },
                          ]}
                        >
                          <TextInput
                            style={[
                              styles.styleinput,
                              {
                                width: 315,
                              },
                            ]}
                          />
                        </View>
                      </View>
                      <View>
                        <Text style={[styles.styletextAll, { marginLeft: 20 }]}>
                          เพิ่มโดย
                        </Text>
                        <View
                          style={[
                            styles.styleinput,
                            {
                              width: 309,
                              marginLeft: 20,
                            },
                          ]}
                        >
                          <TextInput
                            style={[
                              styles.styleinput,
                              {
                                width: 309,
                              },
                            ]}
                          />
                        </View>
                      </View>
                    </View>


                    <View style={{ flexDirection: "row" }}>
                      <View>
                        <Text style={styles.styletextAll}>จำนวน Code</Text>
                        <View
                          style={[
                            styles.styleinput,
                            {
                              width: 315,
                            },
                          ]}
                        >
                          <TextInput
                            style={[
                              styles.styleinput,
                              {
                                width: 315,
                              },
                            ]}
                          />
                        </View>
                      </View>
                      <View>
                        <Text style={[styles.styletextAll, { marginLeft: 20 }]}>
                        จำนวน Code ที่เหลือ
                        </Text>
                        <View
                          style={[
                            styles.styleinput,
                            {
                              width: 309,
                              marginLeft: 20,
                            },
                          ]}
                        >
                          <TextInput
                            style={[
                              styles.styleinput,
                              {
                                width: 309,
                              },
                            ]}
                          />
                        </View>
                      </View>
                    </View>



                    <View style={{ flexDirection: "row" }}>
                      <View>
                        <Text style={styles.styletextAll}>เพิ่มเมื่อ</Text>
                        <View
                          style={[
                            styles.styleinput,
                            {
                              width: 315,
                            },
                          ]}
                        >
                          <TextInput
                            style={[
                              styles.styleinput,
                              {
                                width: 315,
                              },
                            ]}
                          />
                        </View>
                      </View>
                      <View>
                        <Text style={[styles.styletextAll, { marginLeft: 20 }]}>
                        เพิ่มโดย
                        </Text>
                        <View
                          style={[
                            styles.styleinput,
                            {
                              width: 309,
                              marginLeft: 20,
                            },
                          ]}
                        >
                          <TextInput
                            style={[
                              styles.styleinput,
                              {
                                width: 309,
                              },
                            ]}
                          />
                        </View>
                      </View>
                    </View>
                  </View>
                </View>

                <View style={{ flexDirection: "row", alignSelf: "flex-end" }}>
                  <TouchableOpacity
                    onPress={() => {
                        setModalVisibleADD((val) => !val)
                    }}
                    style={{
                      width: 84,
                      height: 23,
                      backgroundColor: "#A1A1A1",
                      justifyContent: "center",
                      marginVertical: 10,
                      marginHorizontal: 5,
                    }}
                  >
                    <Text
                      style={{
                        fontFamily: "Kanit-Regular",
                        fontSize: 12,
                        textAlign: "center",
                        color: "#ffff",
                      }}
                    >
                      Close
                    </Text>
                  </TouchableOpacity>

                  <TouchableOpacity
                    onPress={() => {
                        setModalVisibleADD((val) => !val)
                    }}
                    style={{
                      width: 84,
                      height: 23,
                      backgroundColor: "#EA640D",
                      justifyContent: "center",
                      marginHorizontal: 5,
                      marginVertical: 10,
                    }}
                  >
                    <Text
                      style={{
                        fontFamily: "Kanit-Regular",
                        fontSize: 12,
                        textAlign: "center",
                        color: "#ffff",
                      }}
                    >
                      SAVE
                    </Text>
                  </TouchableOpacity>
                </View>
              </View>
            </View>
          </View>
        </ScrollView>
      </Modal>
      <View
        style={{
          width: wp("85%"),
          height: 138,
          backgroundColor: "#036BB2",
        }}
      >
        <Text style={[styles.styletext, { color: "#ffff", marginLeft: 40 }]}>
          Option
        </Text>
        <View style={styles.styleViewAll}>
          <View
            style={{
              width: wp("74%"),
              height: 650,
              // backgroundColor: "red",
            }}
          >
            <View style={{ flexDirection: "row" }}>
              <View style={{ width: wp("58.5%") }}>
                <View
                  style={{
                    width: wp("40%"),
                    height: hp("5%"),
                    borderRadius: 5,
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >
                  <Text
                    style={{
                      fontSize: hp("5%"),
                      fontFamily: "Kanit-Regular",
                      color: "#036BB2",
                    }}
                  >
                  Payment
                  </Text>
                </View>
              </View>

              {/* RNPickerSelect */}

              <TouchableOpacity
                onPress={() => {
                    setModalVisibleADD((val) => !val)
                }}
                style={{
                  width: wp("13%"),
                  height: hp("5%"),
                  backgroundColor: "#EA640D",
                  justifyContent: "center",
                  borderRadius: 5,
                  flexDirection: "row",
                  marginHorizontal: 10,
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Text
                  style={{
                    fontSize: hp("2.4%"),
                    color: "#ffffff",
                    fontFamily: "Kanit-Regular",
                    textAlign: "center",
                    marginHorizontal: 5,
                  }}
                >
                  Add Code
                </Text>
              </TouchableOpacity>
            </View>

            <View
              style={{
                width: wp("73%"),
                backgroundColor: "#036BB2",
                height: hp("7%"),
                borderRadius: 5,
                marginTop: 15,
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <View style={[styles.Box, { width: wp("5%") }]}>
                <Text
                  style={{
                    fontSize: hp("2.4%"),
                    fontFamily: "Kanit-Regular",
                    color: "#ffffff",
                    textAlign: "center",
                  }}
                >
                  No.
                </Text>
              </View>

              <View style={[styles.Box, {width: wp("12%") }]}>
                <Text
                  style={{
                    fontSize: hp("2.4%"),
                    fontFamily: "Kanit-Regular",
                    color: "#ffffff",
                  }}
                > 
                  ชื่อ
                </Text>
              </View>

             
            

              <View style={[styles.Box, { width: wp("12%") }]}>
                <Text
                  style={{
                    fontSize: hp("2.4%"),
                    fontFamily: "Kanit-Regular",
                    color: "#ffffff",
                    textAlign: "center",
                  }}
                >
                  สถานะ
                </Text>
              </View>

              <View style={[styles.Box, {  width: wp("5%") }]}>
                <Text
                  style={{
                    fontSize: hp("2.4%"),
                    fontFamily: "Kanit-Regular",
                    color: "#ffffff",
                    textAlign: "center",
                  }}
                >
                  ข้อมูล
                </Text>
              </View>

              <View style={[styles.Box, {  width: wp("5%") }]}>
                <Text
                  style={{
                    fontSize: hp("2.4%"),
                    fontFamily: "Kanit-Regular",
                    color: "#ffffff",
                    textAlign: "center",
                  }}
                >
                  แก้
                </Text>
              </View>

              <View style={[styles.Box, {  width: wp("5%")  }]}>
                <Text
                  style={{
                    fontSize: hp("2.4%"),
                    fontFamily: "Kanit-Regular",
                    color: "#ffffff",
                    textAlign: "center",
                  }}
                >
                  ลบ
                </Text>
              </View>
            </View>

            <FlatList
              data={data}
              renderItem={({ item }) => {
                return (
                  <View
                    style={{
                      width: wp("73%"),
                      backgroundColor: "#C5E5FE30",
                      height: 81,
                      borderRadius: 5,
                      marginTop: 15,
                      flexDirection: "row",
                      alignItems:"center"
                    }}
                  >
                    <View style={[styles.Box, {width: wp("5%") }]}>
                      <Text
                        style={{
                          fontSize: hp("2.4%"),
                          fontFamily: "Kanit-Regular",
                          color: "#535659",
                          textAlign: "center",
                        }}
                      >
                        1
                      </Text>
                    </View>

                    <View style={[styles.Box, { width: wp("12%") }]}>
                      <Text
                        style={{
                          fontSize: hp("2.4%"),
                          fontFamily: "Kanit-Regular",
                          color: "#535659",
                        }}
                      >
                        Visa
                      </Text>
                    </View>


                    <View style={[styles.Box, { width: wp("12%") }]}>
                      <Text
                        style={{
                          fontSize: hp("2.4%"),
                          fontFamily: "Kanit-Regular",
                          color: "#26BB2C",
                          textAlign: "center",
                        }}
                      >
                        ใช้งาน
                      </Text>
                    </View>

                    <View
                      style={{
                        width: wp("5%") ,
                        height: 81,
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <TouchableOpacity
                        onPress={() => {
                          setModalVisible((val) => !val);
                        }}
                      >
                        <Foundation
                          name="clipboard-notes"
                          size={30}
                          color="#A1A1A1"
                        />
                      </TouchableOpacity>
                    </View>
                    <View
                      style={{
                        width: wp("5%") ,
                        height: 81,
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <TouchableOpacity
                        onPress={() => {
                          setModalVisibleED((val) => !val);
                        }}
                      >
                        <FontAwesome5 name="edit" size={30} color="#A1A1A1" />
                      </TouchableOpacity>
                    </View>
                    <View
                      style={{
                        width: wp("5%") ,
                        height: 81,
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <TouchableOpacity>
                        <AntDesign name="delete" size={30} color="#A1A1A1" />
                      </TouchableOpacity>
                    </View>
                  </View>
                );
              }}
            />
          </View>
        </View>
      </View>
    </View>
  );
};
const styles = StyleSheet.create({
  styletext: {
    fontSize: 36,
    fontFamily: "Kanit-Regular",
    marginHorizontal: 10,
  },

  styleViewAll: {
    width: wp("78%"),
    height: 700,
    backgroundColor: "#fff",
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 3.84,
    elevation: 5,
    marginLeft: 40,
    borderRadius: 10,
    marginTop: 10,
    alignItems: "center",
    justifyContent: "center",
  },
  styletextAll: {
    fontSize: 20,
    color: "#EA640D",
    fontFamily: "Kanit-Regular",
  },
  styleinput: {
    height: 29,
    backgroundColor: "#FFFFFF50",
    fontSize: 18,
    fontFamily: "Kanit-Regular",
  },
  Box: {
    height: 60,
    justifyContent: "center",
  },
});
export default pageoption1;

