import React, { useEffect, useRef, useState } from "react";
import {
  StyleSheet,
  Text,
  View,
  TouchableOpacity,
  Dimensions,
  SafeAreaView,
  Image,
  ImageBackground,
  TextInput,
  Picker,
  FlatList,
  ScrollView,
  Linking,
} from "react-native";
import {
  widthPercentageToDP as wp,
  heightPercentageToDP as hp,
} from "react-native-responsive-screen";
import {
  AntDesign,
  FontAwesome,
  FontAwesome5,
  Foundation,
  MaterialIcons,
} from "@expo/vector-icons";
const { width, height } = Dimensions.get("window");
import RNPickerSelect from "react-native-picker-select";
import Modal from "modal-enhanced-react-native-web";
import { apiservice } from "../apiservice/api";
import { useWindowSize } from "./responsive";
const page2 = () => {
  const [selectedValue, setSelectedValue] = useState("เลือกประเภทผู้ใช้งาน");
  const [search, setsearch] = useState("");

  // const data = [{ ID: 1 }, { ID: 2 }, { ID: 3 }, { ID: 4 }, { ID: 5 }];

  const [data, setData] = useState([]);
  const [modalVisible, setModalVisible] = useState(false);
  const [modalVisibleAcc, setModalVisibleAcc] = useState(false);
  const placeholder = {
    label: "เลือกประเภทผู้ใช้งาน",
    value: null,
    color: "#777777",
  };
  const [items, setItems] = useState([
    { label: "Account", value: "1" },
    { label: "Store Account", value: "2" },
    { label: "ทั้งหมด", value: "3" },
  ]);

  const [dropdaw, setdropdaw] = useState(null);
  const [modalVisibleAddAccount, setModalVisibleAddAccount] = useState(false);
  const [detail, setItem] = useState({});
  console.log("Prfile", detail);
  useEffect(() => {
    callApiProcute();
  }, []);

  async function callApiProcute() {
    const api = await apiservice({
      path: "/authen/alluser",
      method: "get",
    });
    // console.log(api);
    setData(api.data);
  }
  const size = useWindowSize();
  return (
    <View>
      <Modal
        isVisible={modalVisible}
        onBackdropPress={() => setModalVisible((val) => !val)}
      >
        <ScrollView>
          <View
            style={{
              width: 728,
              backgroundColor: "#fff",
              alignSelf: "center",
              borderRadius: 10,
              paddingTop: 10,
            }}
          >
            <View style={{ width: 675, alignSelf: "center" }}>
              <Text
                style={{
                  fontSize: 30,
                  color: "#EA640D",
                  fontFamily: "Kanit-Regular",
                }}
              >
                ข้อมูล Account
              </Text>
              <View
                style={{
                  width: 678,
                  height: 815,
                  backgroundColor: "#E7E7E7",
                  borderRadius: 10,
                }}
              >
                <View style={{ width: 650, alignSelf: "center" }}>
                  <View style={{ flexDirection: "row" }}>
                    <View
                      style={{
                        width: 230,
                        height: 250,
                        justifyContent: "center",
                      }}
                    >
                      <Text
                        style={{
                          fontSize: 20,
                          color: "#EA640D",
                          fontFamily: "Kanit-Regular",
                          textAlign: "center",
                        }}
                      >
                        รูปผู้ใช้งาน
                      </Text>
                      <TouchableOpacity
                        style={{
                          backgroundColor: "#fff",
                          width: 170,
                          height: 170,
                          alignItems: "center",
                          alignSelf: "center",
                          marginTop: 20,
                        }}
                      >
                        <Image
                          style={{
                            width: 170,
                            height: 170,
                            alignItems: "center",
                          }}
                          source={require("../image/BAof.png")}
                        />
                      </TouchableOpacity>
                    </View>
                    <View
                      style={{
                        width: 430,
                        height: 250,
                        justifyContent: "center",
                      }}
                    >
                      <Text style={styles.styletextAll}>ชื่อผู้ใช้งาน</Text>
                      <Text
                        style={[
                          styles.styleinput,
                          {
                            width: 420,
                          },
                        ]}
                      >
                        {detail.firstname}
                      </Text>
                      <Text style={styles.styletextAll}>ชื่อ - สกุล</Text>
                      <Text
                        style={[
                          styles.styleinput,
                          {
                            width: 420,
                          },
                        ]}
                      >
                        {detail.firstname} {detail.lastname}
                      </Text>

                      <Text style={styles.styletextAll}>เลขบัตรประชาชน</Text>
                      <Text
                        style={[
                          styles.styleinput,
                          {
                            width: 420,
                          },
                        ]}
                      >
                        {detail.telephoneNo}
                      </Text>
                    </View>
                  </View>
                  <View style={{ flexDirection: "row" }}>
                    <View>
                      <Text style={styles.styletextAll}>อีเมล </Text>
                      <Text
                        style={[
                          styles.styleinput,
                          {
                            width: 315,
                          },
                        ]}
                      >
                        {detail.email}
                      </Text>
                    </View>
                    <View>
                      <Text style={[styles.styletextAll, { marginLeft: 20 }]}>
                        เบอร์โทร
                      </Text>
                      <Text
                        style={[
                          styles.styleinput,
                          {
                            width: 309,
                            marginLeft: 20,
                          },
                        ]}
                      >
                        {detail.telephoneNo}
                      </Text>
                    </View>
                  </View>

                  <View style={{ flexDirection: "row" }}>
                    <View>
                      <Text style={styles.styletextAll}>วันเดือนปีเกิด</Text>
                      <Text
                        style={[
                          styles.styleinput,
                          {
                            width: 315,
                          },
                        ]}
                      >
                        {detail.birthday}
                      </Text>
                    </View>
                    <View>
                      <Text style={[styles.styletextAll, { marginLeft: 20 }]}>
                        อายุ
                      </Text>
                      <Text
                        style={[
                          styles.styleinput,
                          {
                            width: 309,
                            marginLeft: 20,
                          },
                        ]}
                      >
                        {detail.birthday}
                      </Text>
                    </View>
                  </View>

                  <Text style={[styles.styletextAll, {}]}>ประเภทผู้ใช้งาน</Text>
                  <Text
                    style={[
                      styles.styleinput,
                      {
                        width: 642,
                      },
                    ]}
                  >
                    {detail.Type}
                  </Text>

                  {/* <Text style={[styles.styletextAll, {}]}>สถานะ</Text>
                  <View
                    style={[
                      styles.styleinput,
                      {
                        width: 642,
                      },
                    ]}
                  >
                    <TextInput
                      style={[
                        styles.styleinput,
                        {
                          width: 642,
                        },
                      ]}
                    />
                  </View> */}

                  <Text style={[styles.styletextAll, {}]}>
                    สร้างแอคเคาท์เมื่อ
                  </Text>
                  <Text
                    style={[
                      styles.styleinput,
                      {
                        width: 642,
                      },
                    ]}
                  >
                    {detail.updatedAt}
                  </Text>

                  <Text style={[styles.styletextAll, { marginTop: 10 }]}>
                    ใบขับขี่
                  </Text>
                  <View style={{ width: 280, height: 170 }}>
                    <Image
                      style={{ width: 280, height: 170 }}
                      source={require("../image/U.png")}
                    />
                  </View>
                </View>
              </View>

              {detail.market_info != undefined &&
                detail.market_info.length > 0 && (
                  <Text
                    style={{
                      fontSize: 30,
                      color: "#EA640D",
                      fontFamily: "Kanit-Regular",
                      marginTop: 10,
                    }}
                  >
                    Store Account
                  </Text>
                )}

              {detail.market_info != undefined &&
                detail.market_info.length > 0 && (
                  <View
                    style={{
                      width: 678,
                      height: 400,
                      backgroundColor: "#E7E7E7",
                      borderRadius: 10,
                    }}
                  >
                    <View style={{ width: 650, alignSelf: "center" }}>
                      <Text style={[styles.styletextAll]}>ชื่อร้าน</Text>
                      <Text
                        style={[
                          styles.styleinput,
                          {
                            width: 642,
                          },
                        ]}
                      >
                        {detail.market_info != undefined &&
                          detail.market_info.length > 0 &&
                          detail.market_info[0].market_name}
                      </Text>

                      <Text style={[styles.styletextAll]}>ที่อยู่ร้าน</Text>
                      <Text
                        style={[
                          styles.styleinput,
                          {
                            width: 642,
                          },
                        ]}
                      >
                        {detail.market_info != undefined &&
                          detail.market_info.length > 0 &&
                          detail.market_info[0].recive_address[0].name}
                      </Text>

                      <Text style={[styles.styletextAll]}>อีเมล</Text>
                      <Text
                        style={[
                          styles.styleinput,
                          {
                            width: 642,
                          },
                        ]}
                      >
                        {detail.email}
                      </Text>

                      <Text style={[styles.styletextAll]}>เบอร์โทร</Text>
                      <Text
                        style={[
                          styles.styleinput,
                          {
                            width: 642,
                          },
                        ]}
                      >
                        {detail.telephoneNo}
                      </Text>

                      <View style={{ flexDirection: "row" }}>
                        <View>
                          <Text style={styles.styletextAll}>ตำแหน่งยืมรถ</Text>
                          <Text
                            style={[
                              styles.styleinput,
                              {
                                width: 460,
                              },
                            ]}
                          >
                            {detail.market_info != undefined &&
                              detail.market_info.length > 0 &&
                              detail.market_info[0].recive_address[0].name}
                          </Text>
                        </View>
                        {/* <View>
                      <Text style={[styles.styletextAll, { marginLeft: 20 }]}>
                        / ราคา
                      </Text>
                      <View
                        style={[
                          styles.styleinput,
                          {
                            width: 170,
                            marginLeft: 20,
                          },
                        ]}
                      >
                        <TextInput
                          style={[
                            styles.styleinput,
                            {
                              width: 170,
                            },
                          ]}
                        />
                      </View>
                    </View> */}
                      </View>

                      {/* <View style={{ flexDirection: "row", marginTop: 13 }}>
                    <View>
                      <View
                        style={[
                          styles.styleinput,
                          {
                            width: 460,
                          },
                        ]}
                      >
                        <TextInput
                          style={[
                            styles.styleinput,
                            {
                              width: 460,
                            },
                          ]}
                        />
                      </View>
                    </View>
                    <View>
                      <View
                        style={[
                          styles.styleinput,
                          {
                            width: 170,
                            marginLeft: 20,
                          },
                        ]}
                      >
                        <TextInput
                          style={[
                            styles.styleinput,
                            {
                              width: 170,
                            },
                          ]}
                        />
                      </View>
                    </View>
                  </View> */}

                      <View style={{ flexDirection: "row" }}>
                        <View>
                          <Text style={styles.styletextAll}>ตำแหน่งคืนรถ</Text>
                          <Text
                            style={[
                              styles.styleinput,
                              {
                                width: 460,
                              },
                            ]}
                          >
                            {detail.market_info != undefined &&
                              detail.market_info.length > 0 &&
                              detail.market_info[0].returnCar_address[0].name}
                          </Text>
                        </View>
                        {/* <View>
                      <Text style={[styles.styletextAll, { marginLeft: 20 }]}>
                        / ราคา
                      </Text>
                      <View
                        style={[
                          styles.styleinput,
                          {
                            width: 170,
                            marginLeft: 20,
                          },
                        ]}
                      >
                        <TextInput
                          style={[
                            styles.styleinput,
                            {
                              width: 170,
                            },
                          ]}
                        />
                      </View>
                    </View> */}
                      </View>

                      {/* <View style={{ flexDirection: "row", marginTop: 13 }}>
                    <View>
                      <View
                        style={[
                          styles.styleinput,
                          {
                            width: 460,
                          },
                        ]}
                      >
                        <TextInput
                          style={[
                            styles.styleinput,
                            {
                              width: 460,
                            },
                          ]}
                        />
                      </View>
                    </View>
                    <View>
                      <View
                        style={[
                          styles.styleinput,
                          {
                            width: 170,
                            marginLeft: 20,
                          },
                        ]}
                      >
                        <TextInput
                          style={[
                            styles.styleinput,
                            {
                              width: 170,
                            },
                          ]}
                        />
                      </View>
                    </View>
                  </View> */}
                      {/* 
                  <Text style={styles.styletextAll}>ตำแหน่งคืนรถ</Text>
                  <View
                    style={[
                      styles.styleinput,
                      {
                        width: 642,
                        flexDirection: "row",
                      },
                    ]}
                  >
                    <AntDesign name="star" size={24} color="#EA640D" />
                    <AntDesign name="star" size={24} color="#EA640D" />
                    <AntDesign name="star" size={24} color="#EA640D" />
                    <AntDesign name="star" size={24} color="#EA640D" />
                    <AntDesign name="star" size={24} color="##AAAAAA" />

                    <Text
                      style={{
                        fontFamily: "Kanit-Regular",
                        fontSize: 20,
                        color: "#A1949A",
                      }}
                    >
                      4 ดาว
                    </Text>
                  </View> */}

                      {/* <Text style={[styles.styletextAll]}>
                    เงื่อนไขการใช้บริการ
                  </Text>
                  <View
                    style={{
                      height: 76,
                      backgroundColor: "#FFFFFF50",
                      width: 642,
                    }}
                  >
                    <TextInput
                      multiline={true}
                      style={{
                        height: 76,
                        backgroundColor: "#FFFFFF50",
                        fontSize: 18,
                        fontFamily: "Kanit-Regular",
                        width: 642,
                      }}
                    />
                  </View>
                  <Text style={[styles.styletextAll]}>Top comment</Text>

                  <View
                    style={{
                      height: 76,
                      backgroundColor: "#FFFFFF50",
                      width: 642,
                      flexDirection: "row",
                    }}
                  >
                    <View
                      style={{
                        width: 76,
                        height: 76,
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <Image
                        style={{ width: 50, height: 50 }}
                        source={require("../image/User.png")}
                      />
                    </View>

                    <View
                      style={{
                        width: 90,
                        height: 76,
                        justifyContent: "center",
                      }}
                    >
                      <Text
                        style={{
                          fontFamily: "Kanit-Regular",
                          fontSize: 12,
                          textAlign: "center",
                          marginTop: -18,
                        }}
                      >
                        นนท์ ธนนท์ :
                      </Text>
                    </View>
                    <View
                      style={{
                        width: 250,
                        height: 76,
                        justifyContent: "center",
                      }}
                    >
                      <Text
                        style={{ fontFamily: "Kanit-Regular", fontSize: 12 }}
                      >
                        รถคันนี้ใฃ้งานดีมากเลยครับ นอกจากเลี้ยงซ้ายได้
                        ยังเลี้ยวขวาได้ด้วย
                      </Text>
                    </View>
                  </View> */}
                    </View>
                  </View>
                )}

              <TouchableOpacity
                onPress={() => {
                  setModalVisible((val) => !val);
                }}
                style={{
                  width: 84,
                  height: 23,
                  backgroundColor: "#EA640D",
                  justifyContent: "center",
                  alignSelf: "flex-end",
                  marginVertical: 10,
                }}
              >
                <Text
                  style={{
                    fontFamily: "Kanit-Regular",
                    fontSize: 12,
                    textAlign: "center",
                    color: "#ffff",
                  }}
                >
                  Close
                </Text>
              </TouchableOpacity>
            </View>
          </View>
        </ScrollView>
      </Modal>

      {/* แก้ไขข้อมูล Account */}
      {/* 
      <Modal
        isVisible={modalVisibleAcc}
        onBackdropPress={() => setModalVisibleAcc((val) => !val)}
      >
        <ScrollView>
          <View
            style={{
              width: 728,
              backgroundColor: "#fff",
              alignSelf: "center",
              borderRadius: 10,
              paddingTop: 10,
            }}
          >
            <View style={{ width: 675, alignSelf: "center" }}>
              <Text
                style={{
                  fontSize: 30,
                  color: "#EA640D",
                  fontFamily: "Kanit-Regular",
                }}
              >
                แก้ไขข้อมูล Account
              </Text>
              <View
                style={{
                  width: 678,
                  height: 815,
                  backgroundColor: "#E7E7E7",
                  borderRadius: 10,
                }}
              >
                <View style={{ width: 650, alignSelf: "center" }}>
                  <View style={{ flexDirection: "row" }}>
                    <View
                      style={{
                        width: 230,
                        height: 250,
                        justifyContent: "center",
                      }}
                    >
                      <Text
                        style={{
                          fontSize: 20,
                          color: "#EA640D",
                          fontFamily: "Kanit-Regular",
                          textAlign: "center",
                        }}
                      >
                        ข้อมูล Account
                      </Text>
                      <TouchableOpacity
                        style={{
                          backgroundColor: "#fff",
                          width: 170,
                          height: 170,
                          alignItems: "center",
                          alignSelf: "center",
                          marginTop: 20,
                        }}
                      >
                        <Image
                          style={{
                            width: 170,
                            height: 170,
                            alignItems: "center",
                          }}
                          source={require("../image/BAof.png")}
                        />
                      </TouchableOpacity>
                    </View>
                    <View
                      style={{
                        width: 430,
                        height: 250,
                        justifyContent: "center",
                      }}
                    >
                      <Text style={styles.styletextAll}>ชื่อผู้ใช้งาน</Text>
                      <View
                        style={[
                          styles.styleinput,
                          {
                            width: 420,
                          },
                        ]}
                      >
                        <TextInput
                          style={[
                            styles.styleinput,
                            {
                              width: 420,
                            },
                          ]}
                        />
                      </View>
                      <Text style={styles.styletextAll}>ชื่อ - สกุล</Text>
                      <View
                        style={[
                          styles.styleinput,
                          {
                            width: 420,
                          },
                        ]}
                      >
                        <TextInput
                          style={[
                            styles.styleinput,
                            {
                              width: 420,
                            },
                          ]}
                        />
                      </View>

                      <Text style={styles.styletextAll}>เลขบัตรประชาชน</Text>
                      <View
                        style={[
                          styles.styleinput,
                          {
                            width: 420,
                          },
                        ]}
                      >
                        <TextInput
                          style={[
                            styles.styleinput,
                            {
                              width: 420,
                            },
                          ]}
                        />
                      </View>
                    </View>
                  </View>
                  <View style={{ flexDirection: "row" }}>
                    <View>
                      <Text style={styles.styletextAll}>อีเมล </Text>
                      <View
                        style={[
                          styles.styleinput,
                          {
                            width: 315,
                          },
                        ]}
                      >
                        <TextInput
                          style={[
                            styles.styleinput,
                            {
                              width: 315,
                            },
                          ]}
                        />
                      </View>
                    </View>
                    <View>
                      <Text style={[styles.styletextAll, { marginLeft: 20 }]}>
                        เบอร์โทร
                      </Text>
                      <View
                        style={[
                          styles.styleinput,
                          {
                            width: 309,
                            marginLeft: 20,
                          },
                        ]}
                      >
                        <TextInput
                          style={[
                            styles.styleinput,
                            {
                              width: 309,
                            },
                          ]}
                        />
                      </View>
                    </View>
                  </View>

                  <View style={{ flexDirection: "row" }}>
                    <View>
                      <Text style={styles.styletextAll}>วันเดือนปีเกิด</Text>
                      <View
                        style={[
                          styles.styleinput,
                          {
                            width: 315,
                          },
                        ]}
                      >
                        <TextInput
                          style={[
                            styles.styleinput,
                            {
                              width: 315,
                            },
                          ]}
                        />
                      </View>
                    </View>
                    <View>
                      <Text style={[styles.styletextAll, { marginLeft: 20 }]}>
                        อายุ
                      </Text>
                      <View
                        style={[
                          styles.styleinput,
                          {
                            width: 309,
                            marginLeft: 20,
                          },
                        ]}
                      >
                        <TextInput
                          style={[
                            styles.styleinput,
                            {
                              width: 309,
                            },
                          ]}
                        />
                      </View>
                    </View>
                  </View>

                  <Text style={[styles.styletextAll, {}]}>ประเภทผู้ใช้งาน</Text>
                  <View
                    style={[
                      styles.styleinput,
                      {
                        width: 642,
                      },
                    ]}
                  >
                    <TextInput
                      style={[
                        styles.styleinput,
                        {
                          width: 642,
                        },
                      ]}
                    />
                  </View>

                  <Text style={[styles.styletextAll, {}]}>สถานะ</Text>
                  <View
                    style={[
                      styles.styleinput,
                      {
                        width: 642,
                      },
                    ]}
                  >
                    <TextInput
                      style={[
                        styles.styleinput,
                        {
                          width: 642,
                        },
                      ]}
                    />
                  </View>

                  <Text style={[styles.styletextAll, {}]}>
                    สร้างแอคเคาท์เมื่อ
                  </Text>
                  <View
                    style={[
                      styles.styleinput,
                      {
                        width: 642,
                      },
                    ]}
                  >
                    <TextInput
                      style={[
                        styles.styleinput,
                        {
                          width: 642,
                        },
                      ]}
                    />
                  </View>

                  <Text style={[styles.styletextAll, { marginTop: 10 }]}>
                    ใบขับขี่
                  </Text>
                  <View style={{ width: 280, height: 170 }}>
                    <Image
                      style={{ width: 280, height: 170 }}
                      source={require("../image/U.png")}
                    />
                  </View>
                </View>
              </View>

              <Text
                style={{
                  fontSize: 30,
                  color: "#EA640D",
                  fontFamily: "Kanit-Regular",
                  marginTop: 10,
                }}
              >
                Store Account
              </Text>

              <View
                style={{
                  width: 678,
                  height: 750,
                  backgroundColor: "#E7E7E7",
                  borderRadius: 10,
                }}
              >
                <View style={{ width: 650, alignSelf: "center" }}>
                  <Text style={[styles.styletextAll]}>ชื่อร้าน</Text>
                  <View
                    style={[
                      styles.styleinput,
                      {
                        width: 642,
                      },
                    ]}
                  >
                    <TextInput
                      style={[
                        styles.styleinput,
                        {
                          width: 642,
                        },
                      ]}
                    />
                  </View>

                  <Text style={[styles.styletextAll]}>ที่อยู่ร้าน</Text>
                  <View
                    style={[
                      styles.styleinput,
                      {
                        width: 642,
                      },
                    ]}
                  >
                    <TextInput
                      style={[
                        styles.styleinput,
                        {
                          width: 642,
                        },
                      ]}
                    />
                  </View>

                  <Text style={[styles.styletextAll]}>อีเมล</Text>
                  <View
                    style={[
                      styles.styleinput,
                      {
                        width: 642,
                      },
                    ]}
                  >
                    <TextInput
                      style={[
                        styles.styleinput,
                        {
                          width: 642,
                        },
                      ]}
                    />
                  </View>

                  <Text style={[styles.styletextAll]}>เบอร์โทร</Text>
                  <View
                    style={[
                      styles.styleinput,
                      {
                        width: 642,
                      },
                    ]}
                  >
                    <TextInput
                      style={[
                        styles.styleinput,
                        {
                          width: 642,
                        },
                      ]}
                    />
                  </View>

                  <View style={{ flexDirection: "row" }}>
                    <View>
                      <Text style={styles.styletextAll}>ตำแหน่งยืมรถ</Text>
                      <View
                        style={[
                          styles.styleinput,
                          {
                            width: 460,
                          },
                        ]}
                      >
                        <TextInput
                          style={[
                            styles.styleinput,
                            {
                              width: 460,
                            },
                          ]}
                        />
                      </View>
                    </View>
                    <View>
                      <Text style={[styles.styletextAll, { marginLeft: 20 }]}>
                        / ราคา
                      </Text>
                      <View
                        style={[
                          styles.styleinput,
                          {
                            width: 170,
                            marginLeft: 20,
                          },
                        ]}
                      >
                        <TextInput
                          style={[
                            styles.styleinput,
                            {
                              width: 170,
                            },
                          ]}
                        />
                      </View>
                    </View>
                  </View>

                  <View style={{ flexDirection: "row", marginTop: 13 }}>
                    <View>
                      <View
                        style={[
                          styles.styleinput,
                          {
                            width: 460,
                          },
                        ]}
                      >
                        <TextInput
                          style={[
                            styles.styleinput,
                            {
                              width: 460,
                            },
                          ]}
                        />
                      </View>
                    </View>
                    <View>
                      <View
                        style={[
                          styles.styleinput,
                          {
                            width: 170,
                            marginLeft: 20,
                          },
                        ]}
                      >
                        <TextInput
                          style={[
                            styles.styleinput,
                            {
                              width: 170,
                            },
                          ]}
                        />
                      </View>
                    </View>
                  </View>

                  <View style={{ flexDirection: "row" }}>
                    <View>
                      <Text style={styles.styletextAll}>ตำแหน่งคืนรถ</Text>
                      <View
                        style={[
                          styles.styleinput,
                          {
                            width: 460,
                          },
                        ]}
                      >
                        <TextInput
                          style={[
                            styles.styleinput,
                            {
                              width: 460,
                            },
                          ]}
                        />
                      </View>
                    </View>
                    <View>
                      <Text style={[styles.styletextAll, { marginLeft: 20 }]}>
                        / ราคา
                      </Text>
                      <View
                        style={[
                          styles.styleinput,
                          {
                            width: 170,
                            marginLeft: 20,
                          },
                        ]}
                      >
                        <TextInput
                          style={[
                            styles.styleinput,
                            {
                              width: 170,
                            },
                          ]}
                        />
                      </View>
                    </View>
                  </View>

                  <View style={{ flexDirection: "row", marginTop: 13 }}>
                    <View>
                      <View
                        style={[
                          styles.styleinput,
                          {
                            width: 460,
                          },
                        ]}
                      >
                        <TextInput
                          style={[
                            styles.styleinput,
                            {
                              width: 460,
                            },
                          ]}
                        />
                      </View>
                    </View>
                    <View>
                      <View
                        style={[
                          styles.styleinput,
                          {
                            width: 170,
                            marginLeft: 20,
                          },
                        ]}
                      >
                        <TextInput
                          style={[
                            styles.styleinput,
                            {
                              width: 170,
                            },
                          ]}
                        />
                      </View>
                    </View>
                  </View>

                  <Text style={styles.styletextAll}>ตำแหน่งคืนรถ</Text>
                  <View
                    style={[
                      styles.styleinput,
                      {
                        width: 642,
                        flexDirection: "row",
                      },
                    ]}
                  >
                    <AntDesign name="star" size={24} color="#EA640D" />
                    <AntDesign name="star" size={24} color="#EA640D" />
                    <AntDesign name="star" size={24} color="#EA640D" />
                    <AntDesign name="star" size={24} color="#EA640D" />
                    <AntDesign name="star" size={24} color="##AAAAAA" />

                    <Text
                      style={{
                        fontFamily: "Kanit-Regular",
                        fontSize: 20,
                        color: "#A1949A",
                      }}
                    >
                      4 ดาว
                    </Text>
                  </View>

                  <Text style={[styles.styletextAll]}>
                    เงื่อนไขการใช้บริการ
                  </Text>
                  <View
                    style={{
                      height: 76,
                      backgroundColor: "#FFFFFF50",
                      width: 642,
                    }}
                  >
                    <TextInput
                      multiline={true}
                      style={{
                        height: 76,
                        backgroundColor: "#FFFFFF50",
                        fontSize: 18,
                        fontFamily: "Kanit-Regular",
                        width: 642,
                      }}
                    />
                  </View>
                  <Text style={[styles.styletextAll]}>Top comment</Text>

                  <View
                    style={{
                      height: 76,
                      backgroundColor: "#FFFFFF50",
                      width: 642,
                      flexDirection: "row",
                    }}
                  >
                    <View
                      style={{
                        width: 76,
                        height: 76,
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <Image
                        style={{ width: 50, height: 50 }}
                        source={require("../image/User.png")}
                      />
                    </View>

                    <View
                      style={{
                        width: 90,
                        height: 76,
                        justifyContent: "center",
                      }}
                    >
                      <Text
                        style={{
                          fontFamily: "Kanit-Regular",
                          fontSize: 12,
                          textAlign: "center",
                          marginTop: -18,
                        }}
                      >
                        นนท์ ธนนท์ :
                      </Text>
                    </View>
                    <View
                      style={{
                        width: 250,
                        height: 76,
                        justifyContent: "center",
                      }}
                    >
                      <Text
                        style={{ fontFamily: "Kanit-Regular", fontSize: 12 }}
                      >
                        รถคันนี้ใฃ้งานดีมากเลยครับ นอกจากเลี้ยงซ้ายได้
                        ยังเลี้ยวขวาได้ด้วย
                      </Text>
                    </View>
                  </View>
                </View>
              </View>

              <View style={{ flexDirection: "row", alignSelf: "flex-end" }}>
                <TouchableOpacity
                  onPress={() => {
                    setModalVisibleAcc((val) => !val);
                  }}
                  style={{
                    width: 84,
                    height: 23,
                    backgroundColor: "#A1A1A1",
                    justifyContent: "center",
                    marginVertical: 10,
                    marginHorizontal: 5,
                  }}
                >
                  <Text
                    style={{
                      fontFamily: "Kanit-Regular",
                      fontSize: 12,
                      textAlign: "center",
                      color: "#ffff",
                    }}
                  >
                    Close
                  </Text>
                </TouchableOpacity>

                <TouchableOpacity
                  onPress={() => {
                    setModalVisibleAcc((val) => !val);
                  }}
                  style={{
                    width: 84,
                    height: 23,
                    backgroundColor: "#EA640D",
                    justifyContent: "center",
                    marginHorizontal: 5,
                    marginVertical: 10,
                  }}
                >
                  <Text
                    style={{
                      fontFamily: "Kanit-Regular",
                      fontSize: 12,
                      textAlign: "center",
                      color: "#ffff",
                    }}
                  >
                    SAVE
                  </Text>
                </TouchableOpacity>
              </View>
            </View>
          </View>
        </ScrollView>
      </Modal> */}

      {/* <Modal
        isVisible={modalVisibleAddAccount}
        onBackdropPress={() => setModalVisibleAddAccount((val) => !val)}
      >
        <ScrollView>
          <View
            style={{
              width: 728,
              backgroundColor: "#fff",
              alignSelf: "center",
              borderRadius: 10,
              paddingTop: 10,
            }}
          >
            <View style={{ width: 675, alignSelf: "center" }}>
              <Text
                style={{
                  fontSize: 30,
                  color: "#EA640D",
                  fontFamily: "Kanit-Regular",
                }}
              >
                Add Account
              </Text>
              <View
                style={{
                  width: 678,
                  height: 815,
                  backgroundColor: "#E7E7E7",
                  borderRadius: 10,
                }}
              >
                <View style={{ width: 650, alignSelf: "center" }}>
                  <View style={{ flexDirection: "row" }}>
                    <View
                      style={{
                        width: 230,
                        height: 250,
                        justifyContent: "center",
                      }}
                    >
                      <Text
                        style={{
                          fontSize: 20,
                          color: "#EA640D",
                          fontFamily: "Kanit-Regular",
                          textAlign: "center",
                        }}
                      >
                        ข้อมูล Account
                      </Text>
                      <TouchableOpacity
                        style={{
                          backgroundColor: "#fff",
                          width: 170,
                          height: 170,
                          alignItems: "center",
                          alignSelf: "center",
                          marginTop: 20,
                        }}
                      >
                        <Image
                          style={{
                            width: 170,
                            height: 170,
                            alignItems: "center",
                          }}
                          source={require("../image/BAof.png")}
                        />
                      </TouchableOpacity>
                    </View>
                    <View
                      style={{
                        width: 430,
                        height: 250,
                        justifyContent: "center",
                      }}
                    >
                      <Text style={styles.styletextAll}>ชื่อผู้ใช้งาน</Text>
                      <View
                        style={[
                          styles.styleinput,
                          {
                            width: 420,
                          },
                        ]}
                      >
                        <TextInput
                          style={[
                            styles.styleinput,
                            {
                              width: 420,
                            },
                          ]}
                        />
                      </View>
                      <Text style={styles.styletextAll}>ชื่อ - สกุล</Text>
                      <View
                        style={[
                          styles.styleinput,
                          {
                            width: 420,
                          },
                        ]}
                      >
                        <TextInput
                          style={[
                            styles.styleinput,
                            {
                              width: 420,
                            },
                          ]}
                        />
                      </View>

                      <Text style={styles.styletextAll}>เลขบัตรประชาชน</Text>
                      <View
                        style={[
                          styles.styleinput,
                          {
                            width: 420,
                          },
                        ]}
                      >
                        <TextInput
                          style={[
                            styles.styleinput,
                            {
                              width: 420,
                            },
                          ]}
                        />
                      </View>
                    </View>
                  </View>
                  <View style={{ flexDirection: "row" }}>
                    <View>
                      <Text style={styles.styletextAll}>อีเมล </Text>
                      <View
                        style={[
                          styles.styleinput,
                          {
                            width: 315,
                          },
                        ]}
                      >
                        <TextInput
                          style={[
                            styles.styleinput,
                            {
                              width: 315,
                            },
                          ]}
                        />
                      </View>
                    </View>
                    <View>
                      <Text style={[styles.styletextAll, { marginLeft: 20 }]}>
                        เบอร์โทร
                      </Text>
                      <View
                        style={[
                          styles.styleinput,
                          {
                            width: 309,
                            marginLeft: 20,
                          },
                        ]}
                      >
                        <TextInput
                          style={[
                            styles.styleinput,
                            {
                              width: 309,
                            },
                          ]}
                        />
                      </View>
                    </View>
                  </View>

                  <View style={{ flexDirection: "row" }}>
                    <View>
                      <Text style={styles.styletextAll}>วันเดือนปีเกิด</Text>
                      <View
                        style={[
                          styles.styleinput,
                          {
                            width: 315,
                          },
                        ]}
                      >
                        <TextInput
                          style={[
                            styles.styleinput,
                            {
                              width: 315,
                            },
                          ]}
                        />
                      </View>
                    </View>
                    <View>
                      <Text style={[styles.styletextAll, { marginLeft: 20 }]}>
                        อายุ
                      </Text>
                      <View
                        style={[
                          styles.styleinput,
                          {
                            width: 309,
                            marginLeft: 20,
                          },
                        ]}
                      >
                        <TextInput
                          style={[
                            styles.styleinput,
                            {
                              width: 309,
                            },
                          ]}
                        />
                      </View>
                    </View>
                  </View>

                  <Text style={[styles.styletextAll, {}]}>ประเภทผู้ใช้งาน</Text>
                  <View
                    style={[
                      styles.styleinput,
                      {
                        width: 642,
                      },
                    ]}
                  >
                    <TextInput
                      style={[
                        styles.styleinput,
                        {
                          width: 642,
                        },
                      ]}
                    />
                  </View>

                  <Text style={[styles.styletextAll, {}]}>สถานะ</Text>
                  <View
                    style={[
                      styles.styleinput,
                      {
                        width: 642,
                      },
                    ]}
                  >
                    <TextInput
                      style={[
                        styles.styleinput,
                        {
                          width: 642,
                        },
                      ]}
                    />
                  </View>

                  <Text style={[styles.styletextAll, {}]}>
                    สร้างแอคเคาท์เมื่อ
                  </Text>
                  <View
                    style={[
                      styles.styleinput,
                      {
                        width: 642,
                      },
                    ]}
                  >
                    <TextInput
                      style={[
                        styles.styleinput,
                        {
                          width: 642,
                        },
                      ]}
                    />
                  </View>

                  <Text style={[styles.styletextAll, { marginTop: 10 }]}>
                    ใบขับขี่
                  </Text>
                  <TouchableOpacity
                    style={{
                      width: 280,
                      height: 170,
                      backgroundColor: "#C4C4C4",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                   <Image
                      style={{ width: 280, height: 170 }}
                      source={require("../image/U.png")}
                    /> 

                    <MaterialIcons
                      name="photo-camera"
                      size={110}
                      color="#FFFFFF50"
                    />
                  </TouchableOpacity>
                </View>
              </View>

              <Text
                style={{
                  fontSize: 30,
                  color: "#EA640D",
                  fontFamily: "Kanit-Regular",
                  marginTop: 10,
                }}
              >
                Store Account
              </Text>

              <View
                style={{
                  width: 678,
                  height: 750,
                  backgroundColor: "#E7E7E7",
                  borderRadius: 10,
                }}
              >
                <View style={{ width: 650, alignSelf: "center" }}>
                  <Text style={[styles.styletextAll]}>ชื่อร้าน</Text>
                  <View
                    style={[
                      styles.styleinput,
                      {
                        width: 642,
                      },
                    ]}
                  >
                    <TextInput
                      style={[
                        styles.styleinput,
                        {
                          width: 642,
                        },
                      ]}
                    />
                  </View>

                  <Text style={[styles.styletextAll]}>ที่อยู่ร้าน</Text>
                  <View
                    style={[
                      styles.styleinput,
                      {
                        width: 642,
                      },
                    ]}
                  >
                    <TextInput
                      style={[
                        styles.styleinput,
                        {
                          width: 642,
                        },
                      ]}
                    />
                  </View>

                  <Text style={[styles.styletextAll]}>อีเมล</Text>
                  <View
                    style={[
                      styles.styleinput,
                      {
                        width: 642,
                      },
                    ]}
                  >
                    <TextInput
                      style={[
                        styles.styleinput,
                        {
                          width: 642,
                        },
                      ]}
                    />
                  </View>

                  <Text style={[styles.styletextAll]}>เบอร์โทร</Text>
                  <View
                    style={[
                      styles.styleinput,
                      {
                        width: 642,
                      },
                    ]}
                  >
                    <TextInput
                      style={[
                        styles.styleinput,
                        {
                          width: 642,
                        },
                      ]}
                    />
                  </View>

                  <View style={{ flexDirection: "row" }}>
                    <View>
                      <Text style={styles.styletextAll}>ตำแหน่งยืมรถ</Text>
                      <View
                        style={[
                          styles.styleinput,
                          {
                            width: 460,
                          },
                        ]}
                      >
                        <TextInput
                          style={[
                            styles.styleinput,
                            {
                              width: 460,
                            },
                          ]}
                        />
                      </View>
                    </View>
                    <View>
                      <Text style={[styles.styletextAll, { marginLeft: 20 }]}>
                        / ราคา
                      </Text>
                      <View
                        style={[
                          styles.styleinput,
                          {
                            width: 170,
                            marginLeft: 20,
                          },
                        ]}
                      >
                        <TextInput
                          style={[
                            styles.styleinput,
                            {
                              width: 170,
                            },
                          ]}
                        />
                      </View>
                    </View>
                  </View>

                  <View style={{ flexDirection: "row", marginTop: 13 }}>
                    <View>
                      <View
                        style={[
                          styles.styleinput,
                          {
                            width: 460,
                          },
                        ]}
                      >
                        <TextInput
                          style={[
                            styles.styleinput,
                            {
                              width: 460,
                            },
                          ]}
                        />
                      </View>
                    </View>
                    <View>
                      <View
                        style={[
                          styles.styleinput,
                          {
                            width: 170,
                            marginLeft: 20,
                          },
                        ]}
                      >
                        <TextInput
                          style={[
                            styles.styleinput,
                            {
                              width: 170,
                            },
                          ]}
                        />
                      </View>
                    </View>
                  </View>

                  <View style={{ flexDirection: "row" }}>
                    <View>
                      <Text style={styles.styletextAll}>ตำแหน่งคืนรถ</Text>
                      <View
                        style={[
                          styles.styleinput,
                          {
                            width: 460,
                          },
                        ]}
                      >
                        <TextInput
                          style={[
                            styles.styleinput,
                            {
                              width: 460,
                            },
                          ]}
                        />
                      </View>
                    </View>
                    <View>
                      <Text style={[styles.styletextAll, { marginLeft: 20 }]}>
                        / ราคา
                      </Text>
                      <View
                        style={[
                          styles.styleinput,
                          {
                            width: 170,
                            marginLeft: 20,
                          },
                        ]}
                      >
                        <TextInput
                          style={[
                            styles.styleinput,
                            {
                              width: 170,
                            },
                          ]}
                        />
                      </View>
                    </View>
                  </View>

                  <View style={{ flexDirection: "row", marginTop: 13 }}>
                    <View>
                      <View
                        style={[
                          styles.styleinput,
                          {
                            width: 460,
                          },
                        ]}
                      >
                        <TextInput
                          style={[
                            styles.styleinput,
                            {
                              width: 460,
                            },
                          ]}
                        />
                      </View>
                    </View>
                    <View>
                      <View
                        style={[
                          styles.styleinput,
                          {
                            width: 170,
                            marginLeft: 20,
                          },
                        ]}
                      >
                        <TextInput
                          style={[
                            styles.styleinput,
                            {
                              width: 170,
                            },
                          ]}
                        />
                      </View>
                    </View>
                  </View>

                  <Text style={styles.styletextAll}>ตำแหน่งคืนรถ</Text>
                  <View
                    style={[
                      styles.styleinput,
                      {
                        width: 642,
                        flexDirection: "row",
                      },
                    ]}
                  >
                    <AntDesign name="star" size={24} color="#EA640D" />
                    <AntDesign name="star" size={24} color="#EA640D" />
                    <AntDesign name="star" size={24} color="#EA640D" />
                    <AntDesign name="star" size={24} color="#EA640D" />
                    <AntDesign name="star" size={24} color="##AAAAAA" />

                    <Text
                      style={{
                        fontFamily: "Kanit-Regular",
                        fontSize: 20,
                        color: "#A1949A",
                      }}
                    >
                      4 ดาว
                    </Text>
                  </View>

                  <Text style={[styles.styletextAll]}>
                    เงื่อนไขการใช้บริการ
                  </Text>
                  <View
                    style={{
                      height: 76,
                      backgroundColor: "#FFFFFF50",
                      width: 642,
                    }}
                  >
                    <TextInput
                      multiline={true}
                      style={{
                        height: 76,
                        backgroundColor: "#FFFFFF50",
                        fontSize: 18,
                        fontFamily: "Kanit-Regular",
                        width: 642,
                      }}
                    />
                  </View>
                </View>
              </View>

              <View style={{ flexDirection: "row", alignSelf: "flex-end" }}>
                <TouchableOpacity
                  onPress={() => {
                    setModalVisibleAddAccount((val) => !val);
                  }}
                  style={{
                    width: 84,
                    height: 23,
                    backgroundColor: "#A1A1A1",
                    justifyContent: "center",
                    marginVertical: 10,
                    marginHorizontal: 5,
                  }}
                >
                  <Text
                    style={{
                      fontFamily: "Kanit-Regular",
                      fontSize: 12,
                      textAlign: "center",
                      color: "#ffff",
                    }}
                  >
                    Close
                  </Text>
                </TouchableOpacity>

                <TouchableOpacity
                  onPress={() => {
                    setModalVisibleAddAccount((val) => !val);
                  }}
                  style={{
                    width: 84,
                    height: 23,
                    backgroundColor: "#EA640D",
                    justifyContent: "center",
                    marginHorizontal: 5,
                    marginVertical: 10,
                  }}
                >
                  <Text
                    style={{
                      fontFamily: "Kanit-Regular",
                      fontSize: 12,
                      textAlign: "center",
                      color: "#ffff",
                    }}
                  >
                    SAVE
                  </Text>
                </TouchableOpacity>
              </View>
            </View>
          </View>
        </ScrollView>
      </Modal> */}

      <View
        style={{
          height: 138,
          backgroundColor: "#036BB2",
        }}
      >
        <Text style={[styles.styletext, { color: "#ffff", marginLeft: 40 }]}>
          Account
        </Text>
        <View style={styles.styleViewAll}>
          <View style={{ flexDirection: "row" }}>
            <View
              style={{
                width: "70%",
                maxWidth: 587,
                height: 58,
                backgroundColor: "#E7E7E7",
                borderRadius: 5,
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <FontAwesome
                name="search"
                size={35}
                color="black"
                style={{ marginHorizontal: 8 }}
              />
              <TextInput
                style={{
                  width: "50%",
                  maxWidth: 550,
                  height: 58,
                  fontSize: 30,
                  fontFamily: "Kanit-Regular",
                }}
                placeholder={"ค้นหา"}
                defaultValue={search}
                onChangeText={(text) => {
                  setsearch(text);
                }}
              />
            </View>

            {/* RNPickerSelect */}

            <View style={{ flexDirection: "row" }}>
              <RNPickerSelect
                placeholder={placeholder}
                value={dropdaw}
                onValueChange={(value) => {
                  setdropdaw(value);
                }}
                style={{
                  inputWeb: {
                    width: wp("15%"),
                    height: hp("5%"),
                    backgroundColor: "#EA640D",
                    justifyContent: "center",
                    borderRadius: 5,
                    flexDirection: "row",
                    marginHorizontal: 10,
                    fontSize: size.width < 1080 ? 18 : 20,
                    color: "#ffffff",
                    fontFamily: "Kanit-Regular",
                  },
                }}
                items={items}
              />

              <TouchableOpacity
                onPress={() => {
                  Linking.openURL(
                    "https://api.rentmocy.com/api/market/exportxlxs"
                  );
                }}
                style={{
                  width: "50%",
                  maxWidth: 157,
                  height: 59,
                  backgroundColor: "#EA640D",
                  justifyContent: "center",
                  borderRadius: 5,
                  marginLeft: 35,
                }}
              >
                <Text
                  style={{
                    fontSize: 30,
                    color: "#ffffff",
                    fontFamily: "Kanit-Regular",
                    textAlign: "center",
                  }}
                >
                  Export
                </Text>
              </TouchableOpacity>
            </View>
          </View>

          <View
            style={{
              width: "100%",
              alignItems: "center",
              backgroundColor: "#036BB2",
              height: 60,
              borderRadius: 5,
              marginTop: 15,
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <Text
              style={[
                styles.textTitle,
                { width: "10%", fontSize: size.width < 1080 ? 18 : 20 },
              ]}
            >
              No.
            </Text>

            <Text
              style={[
                styles.textTitle,
                { width: "20%", fontSize: size.width < 1080 ? 18 : 20 },
              ]}
            >
              ชื่อ-สกุล
            </Text>

            <Text
              style={[
                styles.textTitle,
                { width: "15%", fontSize: size.width < 1080 ? 18 : 20 },
              ]}
            >
              ชื่อผู้ใช้งาน
            </Text>

            <Text
              style={[
                styles.textTitle,
                { width: "15%", fontSize: size.width < 1080 ? 18 : 20 },
              ]}
            >
              ประเภทผู้ใช้งาน
            </Text>

            <Text
              style={[
                styles.textTitle,
                { width: "10%", fontSize: size.width < 1080 ? 18 : 20 },
              ]}
            >
              สถานะ
            </Text>

            <Text
              style={[
                styles.textTitle,
                { width: "10%", fontSize: size.width < 1080 ? 18 : 20 },
              ]}
            >
              ข้อมูล
            </Text>

            {/* <Text
              style={[
                styles.textTitle,
                { width: "10%", fontSize: size.width < 1080 ? 18 : 20 },
              ]}
            >
              แก้
            </Text> */}
            <Text
              style={[
                styles.textTitle,
                { width: "10%", fontSize: size.width < 1080 ? 18 : 20 },
              ]}
            >
              ลบ
            </Text>
          </View>

          <FlatList
            data={data
              .filter(
                (item) =>
                  item.firstname.toLowerCase().includes(search.toLowerCase()) ||
                  item.lastname.toLowerCase().includes(search.toLowerCase())
              )
              .filter((item) => {
                if (dropdaw != null) {
                  if (dropdaw == "1") {
                    return (
                      item.market_info != undefined &&
                      item.market_info.length == 0
                    );
                  } else if (dropdaw == "2") {
                    return (
                      item.market_info != undefined &&
                      item.market_info.length > 0
                    );
                  } else {
                    return true;
                  }
                } else {
                  return true;
                }
              })}
            renderItem={({ item, index }) => {
              return (
                <View
                  style={{
                    width: "100%",
                    alignItems: "center",
                    backgroundColor: "#C5E5FE30",
                    height: 60,
                    borderRadius: 5,
                    marginTop: 15,
                    flexDirection: "row",
                    justifyContent: "space-between",
                  }}
                >
                  <Text
                    style={[
                      styles.textDetail,
                      { width: "10%", fontSize: size.width < 1080 ? 18 : 20 },
                    ]}
                  >
                    {index}
                  </Text>

                  <Text
                    style={[
                      styles.textDetail,
                      { width: "20%", fontSize: size.width < 1080 ? 18 : 20 },
                    ]}
                  >
                    {item.firstname} {item.lastname}
                  </Text>

                  <Text
                    style={[
                      styles.textDetail,
                      { width: "15%", fontSize: size.width < 1080 ? 18 : 20 },
                    ]}
                  >
                    {item.firstname}
                  </Text>

                  <Text
                    style={[
                      styles.textDetail,
                      { width: "15%", fontSize: size.width < 1080 ? 18 : 20 },
                    ]}
                  >
                    {item.Type}
                  </Text>
                  <Text
                    style={[
                      styles.textDetail,
                      { width: "10%", fontSize: size.width < 1080 ? 18 : 20 },
                    ]}
                  >
                    ใช้งาน
                  </Text>

                  <TouchableOpacity
                    style={{
                      width: "10%",
                      alignItems: "center",
                    }}
                    onPress={() => {
                      setModalVisible(true);
                      setItem(item);
                    }}
                  >
                    <Foundation
                      name="clipboard-notes"
                      size={25}
                      color="#A1A1A1"
                    />
                  </TouchableOpacity>

                  {/* <TouchableOpacity
                    style={{
                      width: "10%",
                      alignItems: "center",
                    }}
                    onPress={() => {
                      setModalVisibleAcc((val) => !val);
                    }}
                  >
                    <FontAwesome5 name="edit" size={25} color="#A1A1A1" />
                  </TouchableOpacity> */}

                  <TouchableOpacity
                    style={{
                      width: "10%",
                      alignItems: "center",
                    }}
                    onPress={() => {
                      setModalVisibleAddAccount((val) => !val);
                    }}
                  >
                    <AntDesign name="delete" size={25} color="#A1A1A1" />{" "}
                  </TouchableOpacity>
                </View>
              );
            }}
          />
        </View>
      </View>
    </View>
  );
};
const styles = StyleSheet.create({
  styletext: {
    fontSize: 36,
    fontFamily: "Kanit-Regular",
    marginHorizontal: 10,
  },

  styleViewAll: {
    height: 800,
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 3.84,
    elevation: 5,
    marginHorizontal: 20,
    borderRadius: 10,
    marginTop: 10,
    padding: 20,
    backgroundColor: "#fff",
  },
  styletextAll: {
    fontSize: 20,
    color: "#EA640D",
    fontFamily: "Kanit-Regular",
  },
  styleinput: {
    height: 29,
    backgroundColor: "#FFFFFF50",
    fontSize: 18,
    fontFamily: "Kanit-Regular",
  },
  Box: {
    height: hp("5%"),
    justifyContent: "center",
  },
  textTitle: {
    color: "#ffffff",
    fontFamily: "Kanit-Regular",
    textAlign: "center",
  },
  textDetail: {
    color: "#000000",
    fontFamily: "Kanit-Regular",
    textAlign: "center",
  },
});

export default page2;
