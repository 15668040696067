import * as React from "react";
import { View, Text, Image, Platform } from "react-native";
import { Dimensions } from "react-native";
import { NavigationContainer } from "@react-navigation/native";
import { createStackNavigator } from "@react-navigation/stack";

const { width, height } = Dimensions.get("screen");

import Login from "../page/login/login";
import Home from "../page/Home/Home";

function Route() {
  const routeNameRef = React.useRef();
  const navigationRef = React.useRef();
  const Stack = createStackNavigator();
 

  function screen() {

    return (
      <Stack.Navigator headerMode="none" initialRouteName={"Login"}>
        <Stack.Screen name="Login" component={Login} />
        <Stack.Screen name="Home" component={Home} />

        
      </Stack.Navigator>
    );
  }

  const config = {
    screens: {
    Login :"/Login",
    Home :"/Home"
    },
  };
  const linking = {
    // prefixes: [
    //   "https://www.asianathleticsassociation.com/",
    //   "asianAthleticsAssociation://",
    // ],
    config,
  };

  return (
    <NavigationContainer  linking={linking} fallback={<Text>Loading...</Text>}>
      {screen()}
    </NavigationContainer>
  );
}

export default Route;



