import React, { useRef, useState } from "react";
import {
  StyleSheet,
  Text,
  View,
  TouchableOpacity,
  Dimensions,
  SafeAreaView,
  TextInput,
  FlatList,
  ScrollView,
  Image,
} from "react-native";
import {
  widthPercentageToDP as wp,
  heightPercentageToDP as hp,
} from "react-native-responsive-screen";
import {
  AntDesign,
  Entypo,
  MaterialCommunityIcons,
  FontAwesome,
  FontAwesome5,
  Foundation,
} from "@expo/vector-icons";
const { width, height } = Dimensions.get("window");
import { useWindowSize } from "./responsive";
import Modal from "modal-enhanced-react-native-web";

const pageoption = () => {
  const [data, setData] = useState([]);
  const [modalVisible, setModalVisible] = useState(false);
  const [modalVisibleED, setModalVisibleED] = useState(false);
  const [modalVisibleADD, setModalVisibleADD] = useState(false);
  const size = useWindowSize();
  return (
    <View>
      <Modal
        isVisible={modalVisible}
        onBackdropPress={() => setModalVisible((val) => !val)}
      >
        <ScrollView>
          <View style={{ flex: 1, justifyContent: "center" }}>
            <View
              style={{
                width: 734,
                backgroundColor: "#fff",
                alignSelf: "center",
                borderRadius: 10,
                paddingTop: 10,
                height: 511,
                marginTop: 100,
                justifyContent: "center",
              }}
            >
              <View
                style={{
                  width: 675,
                  alignSelf: "center",
                  justifyContent: "center",
                }}
              >
                <Text
                  style={{
                    fontSize: 30,
                    color: "#EA640D",
                    fontFamily: "Kanit-Regular",
                  }}
                >
                  ข้อมูล Code
                </Text>

                <View
                  style={{
                    width: 678,
                    height: 350,
                    backgroundColor: "#E7E7E7",
                    borderRadius: 10,
                    justifyContent: "center",
                  }}
                >
                  <View style={{ width: 650, alignSelf: "center" }}>
                    <Text style={styles.styletextAll}>ชื่อ</Text>
                    <View
                      style={[
                        styles.styleinput,
                        {
                          width: 640,
                        },
                      ]}
                    >
                      <TextInput
                        style={[
                          styles.styleinput,
                          {
                            width: 640,
                          },
                        ]}
                      />
                    </View>
                    {/* <Text style={styles.styletextAll}>ชื่อ - สกุล</Text>
                    <View
                      style={[
                        styles.styleinput,
                        {
                          width: 640,
                        },
                      ]}
                    >
                      <TextInput
                        style={[
                          styles.styleinput,
                          {
                            width: 640,
                          },
                        ]}
                      />
                    </View> */}

                    <View style={{ flexDirection: "row" }}>
                      <View>
                        <Text style={styles.styletextAll}>ส่วนลด</Text>
                        <View
                          style={[
                            styles.styleinput,
                            {
                              width: 315,
                            },
                          ]}
                        >
                          <TextInput
                            style={[
                              styles.styleinput,
                              {
                                width: 315,
                              },
                            ]}
                          />
                        </View>
                      </View>
                      <View>
                        <Text style={[styles.styletextAll, { marginLeft: 20 }]}>
                          รหัส Code
                        </Text>
                        <View
                          style={[
                            styles.styleinput,
                            {
                              width: 309,
                              marginLeft: 20,
                            },
                          ]}
                        >
                          <TextInput
                            style={[
                              styles.styleinput,
                              {
                                width: 309,
                              },
                            ]}
                          />
                        </View>
                      </View>
                    </View>

                    <View style={{ flexDirection: "row" }}>
                      <View>
                        <Text style={styles.styletextAll}>สถานะ</Text>
                        <View
                          style={[
                            styles.styleinput,
                            {
                              width: 315,
                            },
                          ]}
                        >
                          <TextInput
                            style={[
                              styles.styleinput,
                              {
                                width: 315,
                              },
                            ]}
                          />
                        </View>
                      </View>
                      <View>
                        <Text style={[styles.styletextAll, { marginLeft: 20 }]}>
                          เพิ่มโดย
                        </Text>
                        <View
                          style={[
                            styles.styleinput,
                            {
                              width: 309,
                              marginLeft: 20,
                            },
                          ]}
                        >
                          <TextInput
                            style={[
                              styles.styleinput,
                              {
                                width: 309,
                              },
                            ]}
                          />
                        </View>
                      </View>
                    </View>

                    <View style={{ flexDirection: "row" }}>
                      <View>
                        <Text style={styles.styletextAll}>จำนวน Code</Text>
                        <View
                          style={[
                            styles.styleinput,
                            {
                              width: 315,
                            },
                          ]}
                        >
                          <TextInput
                            style={[
                              styles.styleinput,
                              {
                                width: 315,
                              },
                            ]}
                          />
                        </View>
                      </View>
                      <View>
                        <Text style={[styles.styletextAll, { marginLeft: 20 }]}>
                          จำนวน Code ที่เหลือ
                        </Text>
                        <View
                          style={[
                            styles.styleinput,
                            {
                              width: 309,
                              marginLeft: 20,
                            },
                          ]}
                        >
                          <TextInput
                            style={[
                              styles.styleinput,
                              {
                                width: 309,
                              },
                            ]}
                          />
                        </View>
                      </View>
                    </View>

                    <View style={{ flexDirection: "row" }}>
                      <View>
                        <Text style={styles.styletextAll}>เพิ่มเมื่อ</Text>
                        <View
                          style={[
                            styles.styleinput,
                            {
                              width: 315,
                            },
                          ]}
                        >
                          <TextInput
                            style={[
                              styles.styleinput,
                              {
                                width: 315,
                              },
                            ]}
                          />
                        </View>
                      </View>
                      <View>
                        <Text style={[styles.styletextAll, { marginLeft: 20 }]}>
                          เพิ่มโดย
                        </Text>
                        <View
                          style={[
                            styles.styleinput,
                            {
                              width: 309,
                              marginLeft: 20,
                            },
                          ]}
                        >
                          <TextInput
                            style={[
                              styles.styleinput,
                              {
                                width: 309,
                              },
                            ]}
                          />
                        </View>
                      </View>
                    </View>
                  </View>
                </View>

                <TouchableOpacity
                  onPress={() => {
                    setModalVisible((val) => !val);
                  }}
                  style={{
                    width: 84,
                    height: 23,
                    backgroundColor: "#EA640D",
                    justifyContent: "center",
                    marginHorizontal: 5,
                    marginVertical: 10,
                    alignSelf: "flex-end",
                  }}
                >
                  <Text
                    style={{
                      fontFamily: "Kanit-Regular",
                      fontSize: 12,
                      textAlign: "center",
                      color: "#ffff",
                    }}
                  >
                    SAVE
                  </Text>
                </TouchableOpacity>
              </View>
            </View>
          </View>
        </ScrollView>
      </Modal>

      <Modal
        isVisible={modalVisibleED}
        onBackdropPress={() => setModalVisibleED((val) => !val)}
      >
        <ScrollView>
          <View style={{ flex: 1, justifyContent: "center" }}>
            <View
              style={{
                width: 734,
                backgroundColor: "#fff",
                alignSelf: "center",
                borderRadius: 10,
                paddingTop: 10,
                height: 511,
                marginTop: 100,
                justifyContent: "center",
              }}
            >
              <View
                style={{
                  width: 675,
                  alignSelf: "center",
                  justifyContent: "center",
                }}
              >
                <Text
                  style={{
                    fontSize: 30,
                    color: "#EA640D",
                    fontFamily: "Kanit-Regular",
                  }}
                >
                  ข้อมูล Code
                </Text>

                <View
                  style={{
                    width: 678,
                    height: 350,
                    backgroundColor: "#E7E7E7",
                    borderRadius: 10,
                    justifyContent: "center",
                  }}
                >
                  <View style={{ width: 650, alignSelf: "center" }}>
                    <Text style={styles.styletextAll}>ชื่อ</Text>
                    <View
                      style={[
                        styles.styleinput,
                        {
                          width: 640,
                        },
                      ]}
                    >
                      <TextInput
                        style={[
                          styles.styleinput,
                          {
                            width: 640,
                          },
                        ]}
                      />
                    </View>
                    {/* <Text style={styles.styletextAll}>ชื่อ - สกุล</Text>
                    <View
                      style={[
                        styles.styleinput,
                        {
                          width: 640,
                        },
                      ]}
                    >
                      <TextInput
                        style={[
                          styles.styleinput,
                          {
                            width: 640,
                          },
                        ]}
                      />
                    </View> */}

                    <View style={{ flexDirection: "row" }}>
                      <View>
                        <Text style={styles.styletextAll}>ส่วนลด</Text>
                        <View
                          style={[
                            styles.styleinput,
                            {
                              width: 315,
                            },
                          ]}
                        >
                          <TextInput
                            style={[
                              styles.styleinput,
                              {
                                width: 315,
                              },
                            ]}
                          />
                        </View>
                      </View>
                      <View>
                        <Text style={[styles.styletextAll, { marginLeft: 20 }]}>
                          รหัส Code
                        </Text>
                        <View
                          style={[
                            styles.styleinput,
                            {
                              width: 309,
                              marginLeft: 20,
                            },
                          ]}
                        >
                          <TextInput
                            style={[
                              styles.styleinput,
                              {
                                width: 309,
                              },
                            ]}
                          />
                        </View>
                      </View>
                    </View>

                    <View style={{ flexDirection: "row" }}>
                      <View>
                        <Text style={styles.styletextAll}>สถานะ</Text>
                        <View
                          style={[
                            styles.styleinput,
                            {
                              width: 315,
                            },
                          ]}
                        >
                          <TextInput
                            style={[
                              styles.styleinput,
                              {
                                width: 315,
                              },
                            ]}
                          />
                        </View>
                      </View>
                      <View>
                        <Text style={[styles.styletextAll, { marginLeft: 20 }]}>
                          เพิ่มโดย
                        </Text>
                        <View
                          style={[
                            styles.styleinput,
                            {
                              width: 309,
                              marginLeft: 20,
                            },
                          ]}
                        >
                          <TextInput
                            style={[
                              styles.styleinput,
                              {
                                width: 309,
                              },
                            ]}
                          />
                        </View>
                      </View>
                    </View>

                    <View style={{ flexDirection: "row" }}>
                      <View>
                        <Text style={styles.styletextAll}>จำนวน Code</Text>
                        <View
                          style={[
                            styles.styleinput,
                            {
                              width: 315,
                            },
                          ]}
                        >
                          <TextInput
                            style={[
                              styles.styleinput,
                              {
                                width: 315,
                              },
                            ]}
                          />
                        </View>
                      </View>
                      <View>
                        <Text style={[styles.styletextAll, { marginLeft: 20 }]}>
                          จำนวน Code ที่เหลือ
                        </Text>
                        <View
                          style={[
                            styles.styleinput,
                            {
                              width: 309,
                              marginLeft: 20,
                            },
                          ]}
                        >
                          <TextInput
                            style={[
                              styles.styleinput,
                              {
                                width: 309,
                              },
                            ]}
                          />
                        </View>
                      </View>
                    </View>

                    <View style={{ flexDirection: "row" }}>
                      <View>
                        <Text style={styles.styletextAll}>เพิ่มเมื่อ</Text>
                        <View
                          style={[
                            styles.styleinput,
                            {
                              width: 315,
                            },
                          ]}
                        >
                          <TextInput
                            style={[
                              styles.styleinput,
                              {
                                width: 315,
                              },
                            ]}
                          />
                        </View>
                      </View>
                      <View>
                        <Text style={[styles.styletextAll, { marginLeft: 20 }]}>
                          เพิ่มโดย
                        </Text>
                        <View
                          style={[
                            styles.styleinput,
                            {
                              width: 309,
                              marginLeft: 20,
                            },
                          ]}
                        >
                          <TextInput
                            style={[
                              styles.styleinput,
                              {
                                width: 309,
                              },
                            ]}
                          />
                        </View>
                      </View>
                    </View>
                  </View>
                </View>

                <View style={{ flexDirection: "row", alignSelf: "flex-end" }}>
                  <TouchableOpacity
                    onPress={() => {
                      setModalVisibleED((val) => !val);
                    }}
                    style={{
                      width: 84,
                      height: 23,
                      backgroundColor: "#A1A1A1",
                      justifyContent: "center",
                      marginVertical: 10,
                      marginHorizontal: 5,
                    }}
                  >
                    <Text
                      style={{
                        fontFamily: "Kanit-Regular",
                        fontSize: 12,
                        textAlign: "center",
                        color: "#ffff",
                      }}
                    >
                      Close
                    </Text>
                  </TouchableOpacity>

                  <TouchableOpacity
                    onPress={() => {
                      setModalVisibleED((val) => !val);
                    }}
                    style={{
                      width: 84,
                      height: 23,
                      backgroundColor: "#EA640D",
                      justifyContent: "center",
                      marginHorizontal: 5,
                      marginVertical: 10,
                    }}
                  >
                    <Text
                      style={{
                        fontFamily: "Kanit-Regular",
                        fontSize: 12,
                        textAlign: "center",
                        color: "#ffff",
                      }}
                    >
                      SAVE
                    </Text>
                  </TouchableOpacity>
                </View>
              </View>
            </View>
          </View>
        </ScrollView>
      </Modal>

      <Modal
        isVisible={modalVisibleADD}
        onBackdropPress={() => setModalVisibleADD((val) => !val)}
      >
        <ScrollView>
          <View style={{ flex: 1, justifyContent: "center" }}>
            <View
              style={{
                width: 734,
                backgroundColor: "#fff",
                alignSelf: "center",
                borderRadius: 10,
                paddingTop: 10,
                height: 511,
                marginTop: 100,
                justifyContent: "center",
              }}
            >
              <View
                style={{
                  width: 675,
                  alignSelf: "center",
                  justifyContent: "center",
                }}
              >
                <Text
                  style={{
                    fontSize: 30,
                    color: "#EA640D",
                    fontFamily: "Kanit-Regular",
                  }}
                >
                  ข้อมูล Code
                </Text>

                <View
                  style={{
                    width: 678,
                    height: 350,
                    backgroundColor: "#E7E7E7",
                    borderRadius: 10,
                    justifyContent: "center",
                  }}
                >
                  <View style={{ width: 650, alignSelf: "center" }}>
                    <Text style={styles.styletextAll}>ชื่อ</Text>
                    <View
                      style={[
                        styles.styleinput,
                        {
                          width: 640,
                        },
                      ]}
                    >
                      <TextInput
                        style={[
                          styles.styleinput,
                          {
                            width: 640,
                          },
                        ]}
                      />
                    </View>
                    {/* <Text style={styles.styletextAll}>ชื่อ - สกุล</Text>
                    <View
                      style={[
                        styles.styleinput,
                        {
                          width: 640,
                        },
                      ]}
                    >
                      <TextInput
                        style={[
                          styles.styleinput,
                          {
                            width: 640,
                          },
                        ]}
                      />
                    </View> */}

                    <View style={{ flexDirection: "row" }}>
                      <View>
                        <Text style={styles.styletextAll}>ส่วนลด</Text>
                        <View
                          style={[
                            styles.styleinput,
                            {
                              width: 315,
                            },
                          ]}
                        >
                          <TextInput
                            style={[
                              styles.styleinput,
                              {
                                width: 315,
                              },
                            ]}
                          />
                        </View>
                      </View>
                      <View>
                        <Text style={[styles.styletextAll, { marginLeft: 20 }]}>
                          รหัส Code
                        </Text>
                        <View
                          style={[
                            styles.styleinput,
                            {
                              width: 309,
                              marginLeft: 20,
                            },
                          ]}
                        >
                          <TextInput
                            style={[
                              styles.styleinput,
                              {
                                width: 309,
                              },
                            ]}
                          />
                        </View>
                      </View>
                    </View>

                    <View style={{ flexDirection: "row" }}>
                      <View>
                        <Text style={styles.styletextAll}>สถานะ</Text>
                        <View
                          style={[
                            styles.styleinput,
                            {
                              width: 315,
                            },
                          ]}
                        >
                          <TextInput
                            style={[
                              styles.styleinput,
                              {
                                width: 315,
                              },
                            ]}
                          />
                        </View>
                      </View>
                      <View>
                        <Text style={[styles.styletextAll, { marginLeft: 20 }]}>
                          เพิ่มโดย
                        </Text>
                        <View
                          style={[
                            styles.styleinput,
                            {
                              width: 309,
                              marginLeft: 20,
                            },
                          ]}
                        >
                          <TextInput
                            style={[
                              styles.styleinput,
                              {
                                width: 309,
                              },
                            ]}
                          />
                        </View>
                      </View>
                    </View>

                    <View style={{ flexDirection: "row" }}>
                      <View>
                        <Text style={styles.styletextAll}>จำนวน Code</Text>
                        <View
                          style={[
                            styles.styleinput,
                            {
                              width: 315,
                            },
                          ]}
                        >
                          <TextInput
                            style={[
                              styles.styleinput,
                              {
                                width: 315,
                              },
                            ]}
                          />
                        </View>
                      </View>
                      <View>
                        <Text style={[styles.styletextAll, { marginLeft: 20 }]}>
                          จำนวน Code ที่เหลือ
                        </Text>
                        <View
                          style={[
                            styles.styleinput,
                            {
                              width: 309,
                              marginLeft: 20,
                            },
                          ]}
                        >
                          <TextInput
                            style={[
                              styles.styleinput,
                              {
                                width: 309,
                              },
                            ]}
                          />
                        </View>
                      </View>
                    </View>

                    <View style={{ flexDirection: "row" }}>
                      <View>
                        <Text style={styles.styletextAll}>เพิ่มเมื่อ</Text>
                        <View
                          style={[
                            styles.styleinput,
                            {
                              width: 315,
                            },
                          ]}
                        >
                          <TextInput
                            style={[
                              styles.styleinput,
                              {
                                width: 315,
                              },
                            ]}
                          />
                        </View>
                      </View>
                      <View>
                        <Text style={[styles.styletextAll, { marginLeft: 20 }]}>
                          เพิ่มโดย
                        </Text>
                        <View
                          style={[
                            styles.styleinput,
                            {
                              width: 309,
                              marginLeft: 20,
                            },
                          ]}
                        >
                          <TextInput
                            style={[
                              styles.styleinput,
                              {
                                width: 309,
                              },
                            ]}
                          />
                        </View>
                      </View>
                    </View>
                  </View>
                </View>

                <View style={{ flexDirection: "row", alignSelf: "flex-end" }}>
                  <TouchableOpacity
                    onPress={() => {
                      setModalVisibleADD((val) => !val);
                    }}
                    style={{
                      width: 84,
                      height: 23,
                      backgroundColor: "#A1A1A1",
                      justifyContent: "center",
                      marginVertical: 10,
                      marginHorizontal: 5,
                    }}
                  >
                    <Text
                      style={{
                        fontFamily: "Kanit-Regular",
                        fontSize: 12,
                        textAlign: "center",
                        color: "#ffff",
                      }}
                    >
                      Close
                    </Text>
                  </TouchableOpacity>

                  <TouchableOpacity
                    onPress={() => {
                      setModalVisibleADD((val) => !val);
                    }}
                    style={{
                      width: 84,
                      height: 23,
                      backgroundColor: "#EA640D",
                      justifyContent: "center",
                      marginHorizontal: 5,
                      marginVertical: 10,
                    }}
                  >
                    <Text
                      style={{
                        fontFamily: "Kanit-Regular",
                        fontSize: 12,
                        textAlign: "center",
                        color: "#ffff",
                      }}
                    >
                      SAVE
                    </Text>
                  </TouchableOpacity>
                </View>
              </View>
            </View>
          </View>
        </ScrollView>
      </Modal>
      <View
        style={{
          height: 138,
          backgroundColor: "#036BB2",
        }}
      >
        <Text style={[styles.styletext, { color: "#ffff", marginLeft: 40 }]}>
          Option
        </Text>
        <View style={styles.styleViewAll}>
          <View style={{ flexDirection: "row" }}>
            <View
              style={{
                width: "70%",
                maxWidth: 587,
                height: 58,
                borderRadius: 5,
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <Text
                style={{
                  fontSize: size.width < 1080 ? 48 : 69,
                  fontFamily: "Kanit-Regular",
                  color: "#036BB2",
                }}
              >
                คำขอยกเลิก
              </Text>
            </View>

            <TouchableOpacity
              onPress={() => {
                setModalVisibleADD((val) => !val);
              }}
              style={{
                // width: 188,
                // height: 59,
                width: "20%",
                height: "100%",
                backgroundColor: "#EA640D",
                justifyContent: "center",
                borderRadius: 5,
                flexDirection: "row",
                marginHorizontal: 10,
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Text
                style={{
                  fontSize: size.width < 1080 ? 18 : 20,
                  color: "#ffffff",
                  fontFamily: "Kanit-Regular",
                  textAlign: "center",
                  marginHorizontal: 5,
                }}
              >
                Add Code
              </Text>
            </TouchableOpacity>
          </View>

          <View
            style={{
              width: "100%",
              alignItems: "center",
              backgroundColor: "#036BB2",
              height: 60,
              borderRadius: 5,
              marginTop: 15,
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <Text
              style={[
                styles.textTitle,
                { width: "10%", fontSize: size.width < 1080 ? 18 : 20 },
              ]}
            >
              No.
            </Text>

            <Text
              style={[
                styles.textTitle,
                {
                  width: "20%",
                  fontSize: size.width < 1080 ? 18 : 20,
                  textAlign: "left",
                },
              ]}
            >
              ชื่อ
            </Text>

            <Text
              style={[
                styles.textTitle,
                { width: "10%", fontSize: size.width < 1080 ? 18 : 20 },
              ]}
            >
              ส่วนลด
            </Text>

            <Text
              style={[
                styles.textTitle,
                { width: "10%", fontSize: size.width < 1080 ? 18 : 20 },
              ]}
            >
              รหัส Code
            </Text>

            <Text
              style={[
                styles.textTitle,
                { width: "10%", fontSize: size.width < 1080 ? 18 : 20 },
              ]}
            >
              จำนวน
            </Text>

            <Text
              style={[
                styles.textTitle,
                { width: "10%", fontSize: size.width < 1080 ? 18 : 20 },
              ]}
            >
              สถานะ
            </Text>

            <Text
              style={[
                styles.textTitle,
                { width: "5%", fontSize: size.width < 1080 ? 18 : 20 },
              ]}
            >
              ข้อมูล
            </Text>

            <Text
              style={[
                styles.textTitle,
                { width: "5%", fontSize: size.width < 1080 ? 18 : 20 },
              ]}
            >
              แก้
            </Text>

            <Text
              style={[
                styles.textTitle,
                { width: "5%", fontSize: size.width < 1080 ? 18 : 20 },
              ]}
            >
              ลบ
            </Text>
          </View>

          <FlatList
            data={data}
            renderItem={({ item, index }) => {
              return (
                <View
                  style={{
                    width: "100%",
                    alignItems: "center",
                    backgroundColor: "#C5E5FE30",
                    height: 60,
                    borderRadius: 5,
                    marginTop: 15,
                    flexDirection: "row",
                    justifyContent: "space-between",
                  }}
                >
                  <Text
                    style={[
                      styles.textDetail,
                      { width: "10%", fontSize: size.width < 1080 ? 18 : 20 },
                    ]}
                  >
                    {index}
                  </Text>

                  <Text
                    style={[
                      styles.textDetail,
                      {
                        width: "20%",
                        fontSize: size.width < 1080 ? 18 : 20,
                        textAlign: "left",
                      },
                    ]}
                  >
                    DDDD
                  </Text>

                  <Text
                    style={[
                      styles.textDetail,
                      { width: "10%", fontSize: size.width < 1080 ? 18 : 20 },
                    ]}
                  >
                    50 ฿
                  </Text>

                  <Text
                    style={[
                      styles.textDetail,
                      { width: "10%", fontSize: size.width < 1080 ? 18 : 20 },
                    ]}
                  >
                    #1298760
                  </Text>

                  <Text
                    style={[
                      styles.textDetail,
                      { width: "10%", fontSize: size.width < 1080 ? 18 : 20 },
                    ]}
                  >
                    20
                  </Text>
                  <Text
                    style={[
                      styles.textDetail,
                      { width: "10%", fontSize: size.width < 1080 ? 18 : 20 },
                    ]}
                  >
                    ใช้งาน
                  </Text>

                  <TouchableOpacity
                    style={{ width: "5%", alignItems: "center" }}
                    onPress={() => {
                      setModalVisible((val) => !val);
                    }}
                  >
                    <Foundation
                      name="clipboard-notes"
                      size={30}
                      color="#A1A1A1"
                    />
                  </TouchableOpacity>

                  <TouchableOpacity
                    style={{ width: "5%", alignItems: "center" }}
                    onPress={() => {
                      setModalVisibleED((val) => !val);
                    }}
                  >
                    <FontAwesome5 name="edit" size={30} color="#A1A1A1" />
                  </TouchableOpacity>

                  <TouchableOpacity
                    style={{ width: "5%", alignItems: "center" }}
                  >
                    <AntDesign name="delete" size={30} color="#A1A1A1" />
                  </TouchableOpacity>
                </View>
              );
            }}
          />
        </View>
      </View>
    </View>
  );
};
const styles = StyleSheet.create({
  styletext: {
    fontSize: 36,
    fontFamily: "Kanit-Regular",
    marginHorizontal: 10,
  },

  styleViewAll: {
    height: 800,
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 3.84,
    elevation: 5,
    marginHorizontal: 20,
    borderRadius: 10,
    marginTop: 10,
    padding: 20,
    backgroundColor: "#fff",
  },
  styletextAll: {
    fontSize: 20,
    color: "#EA640D",
    fontFamily: "Kanit-Regular",
  },
  styleinput: {
    height: 29,
    backgroundColor: "#FFFFFF50",
    fontSize: 18,
    fontFamily: "Kanit-Regular",
  },
  Box: {
    height: 60,
    justifyContent: "center",
  },
  textTitle: {
    color: "#ffffff",
    fontFamily: "Kanit-Regular",
    textAlign: "center",
  },
  textDetail: {
    color: "#000000",
    fontFamily: "Kanit-Regular",
    textAlign: "center",
  },
});
export default pageoption;
