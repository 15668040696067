import React, { useEffect, useRef, useState } from "react";
import {
  StyleSheet,
  Text,
  View,
  TouchableOpacity,
  Dimensions,
  SafeAreaView,
  Image,
  ImageBackground,
  TextInput,
} from "react-native";
import ReactApexChart from "react-apexcharts";
import { PieChart } from "react-minimal-pie-chart";
import {
  widthPercentageToDP as wp,
  heightPercentageToDP as hp,
} from "react-native-responsive-screen";
import { AntDesign, Entypo, MaterialCommunityIcons } from "@expo/vector-icons";
import { apiservice } from "../apiservice/api";
import { useWindowSize } from "./responsive";
import moment from "moment";

const { width, height } = Dimensions.get("window");
const page = ({ navigation }) => {
  const [numall, setNumall] = useState(0);
  const [numall1, setNumall1] = useState(0);
  const [datefilter, setDatafilter] = useState([]);

  const [numall2, setNumall2] = useState(0);
  const [series, setSeries] = useState([
    {
      name: "Desktops",
      data: [10, 41, 35, 51, 49, 62, 69, 91, 148],
    },
  ]);
  const options = {
    chart: {
      height: 350,
      type: "line",
      zoom: {
        enabled: false,
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: "straight",
    },
    title: {
      text: "Product Trends by Month",
      align: "left",
    },
    grid: {
      row: {
        colors: ["#f3f3f3", "transparent"], // takes an array which will be repeated on columns
        opacity: 0.5,
      },
    },
    xaxis: {
      categories: [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ],
    },
  };

  useEffect(() => {
    callApi();
    callApiProcut();
    callApiProcute();
  }, []);

  async function callApi() {
    const api = await apiservice({
      path: "/market/all/1",
      method: "get",
    });
    setNumall(api.data.data.length);
  }

  async function callApiProcut() {
    const api = await apiservice({
      path: "/market/getallproduct?page=0",
      method: "get",
    });

    setDatafilter(api.data.data);
    api.data.data.filter((item) => {
      console.log(moment(item.createdAt).format("MM"));
      return moment(item.createdAt).format("MM") == "01";
    }).length;
    setSeries([
      {
        name: "Desktops",
        data: [
          api.data.data.filter(
            (item) => moment(item.createdAt).format("MM") == "01"
          ).length,
          api.data.data.filter(
            (item) => moment(item.createdAt).format("MM") == "02"
          ).length,
          api.data.data.filter(
            (item) => moment(item.createdAt).format("MM") == "03"
          ).length,
          api.data.data.filter(
            (item) => moment(item.createdAt).format("MM") == "04"
          ).length,
          api.data.data.filter(
            (item) => moment(item.createdAt).format("MM") == "05"
          ).length,
          api.data.data.filter(
            (item) => moment(item.createdAt).format("MM") == "06"
          ).length,
          api.data.data.filter(
            (item) => moment(item.createdAt).format("MM") == "07"
          ).length,
          api.data.data.filter(
            (item) => moment(item.createdAt).format("MM") == "08"
          ).length,
          api.data.data.filter(
            (item) => moment(item.createdAt).format("MM") == "09"
          ).length,
          api.data.data.filter(
            (item) => moment(item.createdAt).format("MM") == "10"
          ).length,
          api.data.data.filter(
            (item) => moment(item.createdAt).format("MM") == "11"
          ).length,
          api.data.data.filter(
            (item) => moment(item.createdAt).format("MM") == "12"
          ).length,
        ],
      },
    ]);
    setNumall1(api.data.data.length);
  }

  async function callApiProcute() {
    const api = await apiservice({
      path: "/authen/alluser",
      method: "get",
    });
    console.log(api);
    setNumall2(api.data.length);
  }
  const size = useWindowSize();
  return (
    <View>
      <View
        style={{
          width: wp("85%"),
          height: 138,
          backgroundColor: "#036BB2",
        }}
      >
        <Text style={[styles.styletext, { color: "#ffff", marginLeft: 40 }]}>
          Dashboard
        </Text>
        <View style={styles.styleViewAll}>
          <View
            style={{
              width: "20%",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Text
              style={{
                fontSize: size.width < 1080 ? 16 : 24,
                color: "#000000",
                fontFamily: "Kanit-Regular",
                textAlign: "center",
              }}
            >
              จำนวนผู้ใช้งานทั้งหมด
            </Text>
            <Text
              style={{
                fontSize: size.width < 1080 ? 36 : 56,
                color: "#EA640D",
                fontFamily: "Kanit-Regular",
                textAlign: "center",
              }}
            >
              {numall + numall2}
            </Text>
            <Text
              style={{
                fontSize: size.width < 1080 ? 16 : 24,
                color: "#000000",
                fontFamily: "Kanit-Regular",
                textAlign: "center",
              }}
            >
              แอคเคาท์
            </Text>
          </View>
          <View
            style={{
              width: "30%",
              justifyContent: "center",
            }}
          >
            <Text
              style={{
                fontSize: size.width < 1080 ? 16 : 36,
                color: "#000000",
                fontFamily: "Kanit-Regular",
                textAlign: "center",
              }}
            >
              ประเภทผู้ใช้งาน
            </Text>
            <View
              style={{
                flexDirection: "row",
                marginTop: 40,
                // justifyContent: "space-between",
              }}
            >
              <View
                style={{
                  width: "50%",
                  backgroundColor: "#59595950",
                  borderRadius: 10,
                  justifyContent: "center",
                }}
              >
                <Text
                  style={{
                    fontSize: size.width < 1080 ? 16 : 24,
                    color: "#ffffff",
                    fontFamily: "Kanit-Regular",
                    textAlign: "center",
                  }}
                >
                  ผู้ใช้งาน
                </Text>
                <Text
                  style={{
                    fontSize: size.width < 1080 ? 16 : 36,
                    color: "#ffffff",
                    fontFamily: "Kanit-Regular",
                    textAlign: "center",
                  }}
                >
                  {numall2}
                </Text>
                <Text
                  style={{
                    fontSize: size.width < 1080 ? 16 : 24,
                    color: "#ffffff",
                    fontFamily: "Kanit-Regular",
                    textAlign: "center",
                  }}
                >
                  แอคเคาท์
                </Text>
              </View>
              <View
                style={{
                  width: "50%",
                  backgroundColor: "#EA640D",
                  borderRadius: 10,
                  justifyContent: "center",
                  marginHorizontal: 5,
                }}
              >
                <Text
                  style={{
                    fontSize: size.width < 1080 ? 16 : 24,
                    color: "#ffffff",
                    fontFamily: "Kanit-Regular",
                    textAlign: "center",
                  }}
                >
                  ร้านค้า
                </Text>
                <Text
                  style={{
                    fontSize: size.width < 1080 ? 16 : 40,
                    color: "#ffffff",
                    fontFamily: "Kanit-Regular",
                    textAlign: "center",
                  }}
                >
                  {numall}
                </Text>
                <Text
                  style={{
                    fontSize: size.width < 1080 ? 16 : 24,
                    color: "#ffffff",
                    fontFamily: "Kanit-Regular",
                    textAlign: "center",
                  }}
                >
                  แอคเคาท์
                </Text>
              </View>
            </View>
          </View>
          <View
            style={{
              width: wp("22%"),
              height: 373,
              alignItems: "center",
              justifyContent: "center",
              // backgroundColor:"red"
            }}
          >
            <View
              style={{
                width: wp("25%"),
                height: 300,
                flexDirection: "row",
              }}
            >
              <View style={{ width: 100, backgroundColor: "#fff" }}>
                <View style={{ flexDirection: "row" }}>
                  <MaterialCommunityIcons
                    name="checkbox-blank"
                    size={24}
                    color="#C4C4C4"
                  />
                  <Text
                    style={{
                      fontSize: 14,
                      color: "#404040",
                      fontFamily: "Kanit-Regular",
                      marginHorizontal: 5,
                    }}
                  >
                    ผู้ใช้งาน
                  </Text>
                </View>
                <View style={{ flexDirection: "row" }}>
                  <MaterialCommunityIcons
                    name="checkbox-blank"
                    size={24}
                    color="#EA640D"
                  />
                  <Text
                    style={{
                      fontSize: 14,
                      color: "#404040",
                      fontFamily: "Kanit-Regular",
                      marginHorizontal: 5,
                    }}
                  >
                    ร้านค้า
                  </Text>
                </View>
              </View>
              <View
                style={{
                  width: wp("12%"),
                  height: wp("12%"),
                  alignSelf: "center",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <PieChart
                  data={[
                    { title: "One", value: numall2, color: "#E38627" },
                    { title: "Two", value: numall, color: "#C13C37" },
                  ]}
                />
                <Text
                  style={{
                    fontSize: 24,
                    color: "##00000025",
                    fontFamily: "Kanit-Regular",
                    marginTop: 10,
                  }}
                >
                  *Pie Doughnut Chat
                </Text>
              </View>
            </View>
          </View>
        </View>
        <View style={{ width: "90%", marginLeft: 35, marginTop: 35 }}>
          <ReactApexChart
            options={options}
            series={series}
            type="line"
            height={350}
          />
        </View>
        {/* <View style={styles.styleViewAll}>
          <View
            style={{
              width: wp("20%"),
              height: wp("30%"),
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <View
              style={{
                width: wp("25%"),
                height: 300,
              }}
            >
              <Text
                style={{
                  fontSize: hp("3%"),
                  color: "#000000",
                  fontFamily: "Kanit-Regular",
                  textAlign: "center",
                }}
              >
                จำนวนผู้ใช้งานทั้งหมด
              </Text>
              <Text
                style={{
                  fontSize: hp("10%"),
                  color: "#EA640D",
                  fontFamily: "Kanit-Regular",
                  textAlign: "center",
                }}
              >
                {numall + numall2}
              </Text>
              <Text
                style={{
                  fontSize: hp("3%"),
                  color: "#000000",
                  fontFamily: "Kanit-Regular",
                  textAlign: "center",
                }}
              >
                แอคเคาท์
              </Text>
            </View>
          </View>
          <View
            style={{
              width: wp("17%"),
              height: 373,
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <View
              style={{
                width: wp("30%"),
                height: 300,
              }}
            >
              <Text
                style={{
                  fontSize: hp("5%"),
                  color: "#000000",
                  fontFamily: "Kanit-Regular",
                  textAlign: "center",
                }}
              >
                ประวัติการใช้งาน
              </Text>
              <View style={{ flexDirection: "row", marginTop: 10 }}>
                <View
                  style={{
                    width: wp("12%"),
                    height: wp("12%"),
                    backgroundColor: "#59595950",
                    borderRadius: 10,
                    marginHorizontal: 10,
                    justifyContent: "center",
                  }}
                >
                  <Text
                    style={{
                      fontSize: hp("3%"),
                      color: "#ffffff",
                      fontFamily: "Kanit-Regular",
                      textAlign: "center",
                    }}
                  >
                    ผู้ใช้งาน
                  </Text>
                  <Text
                    style={{
                      fontSize: hp("8%"),
                      color: "#ffffff",
                      fontFamily: "Kanit-Regular",
                      textAlign: "center",
                    }}
                  >
                    {numall2}
                  </Text>
                  <Text
                    style={{
                      fontSize: hp("3%"),
                      color: "#ffffff",
                      fontFamily: "Kanit-Regular",
                      textAlign: "center",
                    }}
                  >
                    แอคเคาท์
                  </Text>
                </View>
                <View
                  style={{
                    width: wp("12%"),
                    height: wp("12%"),
                    backgroundColor: "#EA640D",
                    borderRadius: 10,
                    marginHorizontal: 10,
                    justifyContent: "center",
                  }}
                >
                  <Text
                    style={{
                      fontSize: hp("3%"),
                      color: "#ffffff",
                      fontFamily: "Kanit-Regular",
                      textAlign: "center",
                    }}
                  >
                    ร้านค้า
                  </Text>
                  <Text
                    style={{
                      fontSize: hp("8%"),
                      color: "#ffffff",
                      fontFamily: "Kanit-Regular",
                      textAlign: "center",
                    }}
                  >
                    {numall}
                  </Text>
                  <Text
                    style={{
                      fontSize: hp("3%"),
                      color: "#ffffff",
                      fontFamily: "Kanit-Regular",
                      textAlign: "center",
                    }}
                  >
                    แอคเคาท์
                  </Text>
                </View>
              </View>
            </View>
          </View>
          <View
            style={{
              width: wp("22%"),
              height: 373,
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <View
              style={{
                width: wp("25%"),
                height: 300,
                flexDirection: "row",
              }}
            >
              <View style={{ width: 100, backgroundColor: "#fff" }}>
                <View style={{ flexDirection: "row" }}>
                  <MaterialCommunityIcons
                    name="checkbox-blank"
                    size={24}
                    color="#C4C4C4"
                  />
                  <Text
                    style={{
                      fontSize: 14,
                      color: "#404040",
                      fontFamily: "Kanit-Regular",
                      marginHorizontal: 5,
                    }}
                  >
                    ผู้ใช้งาน
                  </Text>
                </View>
                <View style={{ flexDirection: "row" }}>
                  <MaterialCommunityIcons
                    name="checkbox-blank"
                    size={24}
                    color="#EA640D"
                  />
                  <Text
                    style={{
                      fontSize: 14,
                      color: "#404040",
                      fontFamily: "Kanit-Regular",
                      marginHorizontal: 5,
                    }}
                  >
                    ร้านค้า
                  </Text>
                </View>
              </View>
              <View
                style={{
                  width: wp("12%"),
                  height: wp("12%"),
                  alignSelf: "center",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <View
                  style={{
                    width: wp("12%"),
                    height: wp("12%"),
                    backgroundColor: "#036BB2",
                  }}
                ></View>
                <Text
                  style={{
                    fontSize: 24,
                    color: "##00000025",
                    fontFamily: "Kanit-Regular",
                    marginTop: 10,
                  }}
                >
                  *Pie Doughnut Chat
                </Text>
              </View>
            </View>
          </View>
        </View> */}
      </View>
    </View>
  );
};
const styles = StyleSheet.create({
  styletext: {
    fontSize: 36,
    fontFamily: "Kanit-Regular",
    marginHorizontal: 10,
  },
  styleR: {
    width: 17,
    height: 17,
    borderRadius: 30,
    marginHorizontal: 10,
  },
  styleViewAll: {
    width: "90%",
    paddingHorizontal: 10,
    backgroundColor: "#fff",
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 3.84,
    elevation: 5,
    marginLeft: 40,
    borderRadius: 10,
    marginTop: 10,
    flexDirection: "row",
    justifyContent: "space-between",
  },
});

export default page;
