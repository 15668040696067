import React, { useEffect, useRef, useState } from "react";
import {
  StyleSheet,
  Text,
  View,
  TouchableOpacity,
  Dimensions,
  SafeAreaView,
  TextInput,
  FlatList,
  ScrollView,
  Image,
} from "react-native";
import {
  widthPercentageToDP as wp,
  heightPercentageToDP as hp,
} from "react-native-responsive-screen";
import {
  AntDesign,
  Entypo,
  MaterialCommunityIcons,
  FontAwesome,
  FontAwesome5,
  Foundation,
} from "@expo/vector-icons";
const { width, height } = Dimensions.get("window");
import RNPickerSelect from "react-native-picker-select";
import Modal from "modal-enhanced-react-native-web";
import { apiservice } from "../apiservice/api";
import { useWindowSize } from "./responsive";
const Page3 = () => {
  const [selectedValue, setSelectedValue] = useState("เลือกประเภทผู้ใช้งาน");
  // const data = [{ ID: 1 }, { ID: 2 }, { ID: 3 }, { ID: 4 }, { ID: 5 }];
  const [data, setData] = useState([]);
  const [dropdaw, setdropdaw] = useState(null);
  const [modalVisible, setModalVisible] = useState(false);
  const placeholder = {
    label: "เลือกประเภทรถ",
    value: null,
    color: "#777777",
  };
  let type = [
    { label: "มอเตอร์ไซค์ตำ่กว่า 125 cc", value: "MOTOCYCLELESS125" },
    { label: "มอเตอร์ไซค์มากกว่า 125 cc", value: "MOTOCYCLEMORE125" },
    { label: "มอเตอร์ไซค์ไฟฟ้า", value: "ELECTRO MOTOCYCLE" },
    { label: "จักรยานไฟฟ้า", value: "ELECTRO BIKECYCLE" },
    { label: "จักรยาน", value: "BIKECYCLE" },
    { label: "บิ๊กไบค์", value: "BIGBIKE" },
  ];
  const [items, setItems] = useState([
    { label: "รถบิ๊กไบค์", value: "1" },
    { label: "น้อยกว่า 125cc", value: "2" },
    { label: "รถมอเตอร์ไซค์ไฟฟ้า", value: "3" },
    { label: "ทั้งหมด", value: "4" },
  ]);
  const placeholderJo = {
    label: "เลือกสถานะการเช่า",
    value: null,
    color: "#777777",
  };
  const [itemsjo, setItemsjo] = useState([
    { label: "จอง", value: "1" },
    { label: "กำลังเช่า", value: "2" },
    { label: "พร้อมใช้", value: "3" },
    { label: "ทั้งหมด", value: "4" },
  ]);
  const dataimg = [{ name: 1 }, { name: 2 }, { name: 2 }];
  const [modalVisibleED, setModalVisibleED] = useState(false);
  const [search, setsearch] = useState("");

  const [detail, setItem] = useState({});
  console.log("Prfile", detail);

  async function callApiProcut() {
    const api = await apiservice({
      path: "/market/getallproduct?page=0",
      method: "get",
    });
    console.log(api);
    setData(api.data.data);
  }

  useEffect(() => {
    callApiProcut();
  }, []);
  const size = useWindowSize();
  return (
    <View>
      <Modal
        isVisible={modalVisible}
        onBackdropPress={() => setModalVisible((val) => !val)}
      >
        <ScrollView>
          <View
            style={{
              width: 728,
              backgroundColor: "#fff",
              alignSelf: "center",
              borderRadius: 10,
              paddingTop: 10,
              height: 1000,
            }}
          >
            <View style={{ width: 675, alignSelf: "center" }}>
              <Text
                style={{
                  fontSize: 30,
                  color: "#EA640D",
                  fontFamily: "Kanit-Regular",
                }}
              >
                ข้อมูลรถ
              </Text>
              <View
                style={{
                  width: 678,
                  height: 815,
                  backgroundColor: "#E7E7E7",
                  borderRadius: 10,
                  height: 900,
                }}
              >
                <View style={{ width: 650, alignSelf: "center" }}>
                  <Text style={styles.styletextAll}>รูป</Text>

                  <FlatList
                    data={dataimg}
                    horizontal
                    renderItem={({ item }) => {
                      return (
                        <View
                          style={{
                            width: 205,
                            height: 130,
                            marginHorizontal: 10,
                          }}
                        >
                          <Image
                            style={{ width: 205, height: 130 }}
                            source={require("../image/img.png")}
                          />
                        </View>
                      );
                    }}
                  />

                  <Text style={[styles.styletextAll, { marginTop: 10 }]}>
                    รถที่เช่า
                  </Text>
                  <Text
                    style={[
                      styles.styleinput,
                      {
                        width: 642,
                      },
                    ]}
                  >
                    {detail.product_name}
                  </Text>

                  <Text style={[styles.styletextAll, { marginTop: 10 }]}>
                    ชื่อร้านค้า
                  </Text>
                  <View
                    style={[
                      styles.styleinput,
                      {
                        width: 642,
                      },
                    ]}
                  >
                    <TextInput
                      style={[
                        styles.styleinput,
                        {
                          width: 642,
                        },
                      ]}
                    />
                  </View>

                  <View style={{ flexDirection: "row" }}>
                    <View>
                      <Text style={styles.styletextAll}>เกียร์</Text>
                      <Text
                        style={[
                          styles.styleinput,
                          {
                            width: 315,
                          },
                        ]}
                      >
                        {detail.gear_type}
                      </Text>
                    </View>
                    <View>
                      <Text style={[styles.styletextAll, { marginLeft: 20 }]}>
                        ไมล์
                      </Text>
                      <Text
                        style={[
                          styles.styleinput,
                          {
                            width: 309,
                            marginLeft: 20,
                          },
                        ]}
                      >
                        {detail.miles}
                      </Text>
                    </View>
                  </View>

                  <View style={{ flexDirection: "row" }}>
                    <View>
                      <Text style={styles.styletextAll}>ปีรถ</Text>
                      <Text
                        style={[
                          styles.styleinput,
                          {
                            width: 315,
                          },
                        ]}
                      >
                        {detail.year}
                      </Text>
                    </View>
                    <View>
                      <Text style={[styles.styletextAll, { marginLeft: 20 }]}>
                        น้ำมัน
                      </Text>
                      <Text
                        style={[
                          styles.styleinput,
                          {
                            width: 309,
                            marginLeft: 20,
                          },
                        ]}
                      >
                        {detail.oils}
                      </Text>
                    </View>
                  </View>

                  <Text style={[styles.styletextAll]}>รายละเอียดเพิ่มเติม</Text>
                  <Text
                    style={{
                      height: 76,
                      backgroundColor: "#FFFFFF50",
                      width: 642,
                    }}
                  >
                    {detail.detail}
                  </Text>

                  <Text style={[styles.styletextAll]}>ราคาเช่า/วัน</Text>
                  <Text
                    style={[
                      styles.styleinput,
                      {
                        width: 642,
                      },
                    ]}
                  >
                    {detail.createdAt}
                  </Text>

                  {/* <View style={{ flexDirection: "row" }}>
                    <View>
                      <Text style={styles.styletextAll}>ปีรถ</Text>
                      <Text
                        style={[
                          styles.styleinput,
                          {
                            width: 315,
                          },
                        ]}
                      >
                        {detail.year}
                      </Text>
                    </View>
                    <View>
                      <Text style={[styles.styletextAll, { marginLeft: 20 }]}>
                        น้ำมัน
                      </Text>
                      <View
                        style={[
                          styles.styleinput,
                          {
                            width: 309,
                            marginLeft: 20,
                          },
                        ]}
                      >
                        <TextInput
                          style={[
                            styles.styleinput,
                            {
                              width: 309,
                            },
                          ]}
                        />
                      </View>
                    </View>
                  </View> */}

                  <View style={{ flexDirection: "row" }}>
                    <View>
                      <Text style={styles.styletextAll}>ตำแหน่งยืมรถ</Text>
                      <Text
                        style={[
                          styles.styleinput,
                          {
                            width: 460,
                          },
                        ]}
                      >
                        ทับหลวง
                      </Text>
                    </View>
                    {/* <View>
                      <Text style={[styles.styletextAll, { marginLeft: 20 }]}>
                        / ราคา
                      </Text>
                      <View
                        style={[
                          styles.styleinput,
                          {
                            width: 170,
                            marginLeft: 20,
                          },
                        ]}
                      >
                        <TextInput
                          style={[
                            styles.styleinput,
                            {
                              width: 170,
                            },
                          ]}
                        />
                      </View>
                    </View> */}
                  </View>
                  {/* <View style={{ flexDirection: "row", marginTop: 13 }}>
                    <View>
                      <View
                        style={[
                          styles.styleinput,
                          {
                            width: 460,
                          },
                        ]}
                      >
                        <TextInput
                          style={[
                            styles.styleinput,
                            {
                              width: 460,
                            },
                          ]}
                        />
                      </View>
                    </View>
                    <View>
                      <View
                        style={[
                          styles.styleinput,
                          {
                            width: 170,
                            marginLeft: 20,
                          },
                        ]}
                      >
                        <TextInput
                          style={[
                            styles.styleinput,
                            {
                              width: 170,
                            },
                          ]}
                        />
                      </View>
                    </View>
                  </View> */}

                  <View style={{ flexDirection: "row" }}>
                    <View>
                      <Text style={styles.styletextAll}>ตำแหน่งคืนรถ</Text>
                      <Text
                        style={[
                          styles.styleinput,
                          {
                            width: 460,
                          },
                        ]}
                      >
                        ทับหลวง
                      </Text>
                    </View>
                    <View>
                      {/* <Text style={[styles.styletextAll, { marginLeft: 20 }]}>
                        / ราคา
                      </Text>
                      <View
                        style={[
                          styles.styleinput,
                          {
                            width: 170,
                            marginLeft: 20,
                          },
                        ]}
                      >
                        <TextInput
                          style={[
                            styles.styleinput,
                            {
                              width: 170,
                            },
                          ]}
                        />
                      </View> */}
                    </View>
                  </View>
                  {/* <View style={{ flexDirection: "row", marginTop: 13 }}>
                    <View>
                      <View
                        style={[
                          styles.styleinput,
                          {
                            width: 460,
                          },
                        ]}
                      >
                        <TextInput
                          style={[
                            styles.styleinput,
                            {
                              width: 460,
                            },
                          ]}
                        />
                      </View>
                    </View>
                    <View>
                      <View
                        style={[
                          styles.styleinput,
                          {
                            width: 170,
                            marginLeft: 20,
                          },
                        ]}
                      >
                        <TextInput
                          style={[
                            styles.styleinput,
                            {
                              width: 170,
                            },
                          ]}
                        />
                      </View>
                    </View>
                  </View> */}

                  <View
                    style={{
                      flexDirection: "row",
                      marginVertical: 5,
                      height: 30,
                      alignItems: "center",
                    }}
                  >
                    {detail.product_info != undefined &&
                    detail.product_info.PLB == true ? (
                      <MaterialCommunityIcons
                        name="checkbox-blank"
                        size={24}
                        color="#ffff"
                      />
                    ) : (
                      <MaterialCommunityIcons
                        name="checkbox-blank"
                        size={24}
                        color="red"
                      />
                    )}
                    <Text
                      style={[styles.styletextAll, { marginHorizontal: 5 }]}
                    >
                      ต่อ พรบ. แล้ว
                    </Text>
                  </View>

                  <View
                    style={{
                      flexDirection: "row",
                      marginVertical: 5,
                      height: 30,
                      alignItems: "center",
                      marginTop: 10,
                    }}
                  >
                    {detail.product_info != undefined &&
                    detail.product_info.Vat == true ? (
                      <MaterialCommunityIcons
                        name="checkbox-blank"
                        size={24}
                        color="#ffff"
                      />
                    ) : (
                      <MaterialCommunityIcons
                        name="checkbox-blank"
                        size={24}
                        color="red"
                      />
                    )}
                    <Text
                      style={[styles.styletextAll, { marginHorizontal: 5 }]}
                    >
                      จ่ายภาษี แล้ว
                    </Text>
                  </View>

                  <View
                    style={{
                      flexDirection: "row",
                      marginVertical: 5,
                      height: 30,
                      alignItems: "center",
                    }}
                  >
                    {detail.product_info != undefined &&
                    detail.product_info.GPS == true ? (
                      <MaterialCommunityIcons
                        name="checkbox-blank"
                        size={24}
                        color="#ffff"
                      />
                    ) : (
                      <MaterialCommunityIcons
                        name="checkbox-blank"
                        size={24}
                        color="red"
                      />
                    )}
                    <Text
                      style={[styles.styletextAll, { marginHorizontal: 5 }]}
                    >
                      ติด GPS แล้ว
                    </Text>
                  </View>

                  {/* <View style={{ flexDirection: "row" }}>
                    <View style={{ width: 324, height: 183 }}>
                      <Image
                        style={{ width: 324, height: 183 }}
                        source={require("../image/Daw.png")}
                      />
                    </View>
                    <View
                      style={{
                        height: 183,
                        width: 200,
                        justifyContent: "flex-end",
                      }}
                    >
                      <TouchableOpacity
                        style={{
                          width: 48,
                          height: 17,
                          backgroundColor: "#EA640D",
                          borderRadius: 5,
                          marginLeft: 10,
                        }}
                      >
                        <Text
                          style={{
                            fontSize: 14,
                            color: "#FFFFFF",
                            fontFamily: "Kanit-Regular",
                            textAlign: "center",
                          }}
                        >
                          ลบ
                        </Text>
                      </TouchableOpacity>
                    </View>
                  </View> */}
                </View>
              </View>

              <View style={{ flexDirection: "row", alignSelf: "flex-end" }}>
                <TouchableOpacity
                  onPress={() => {
                    setModalVisible((val) => !val);
                  }}
                  style={{
                    width: 84,
                    height: 23,
                    backgroundColor: "#EA640D",
                    justifyContent: "center",
                    marginVertical: 10,
                    marginHorizontal: 5,
                    borderRadius: 5,
                  }}
                >
                  <Text
                    style={{
                      fontFamily: "Kanit-Regular",
                      fontSize: 12,
                      textAlign: "center",
                      color: "#ffff",
                    }}
                  >
                    Close
                  </Text>
                </TouchableOpacity>

                {/* <TouchableOpacity
                  onPress={() => {
                    setModalVisible((val) => !val);
                  }}
                  style={{
                    width: 84,
                    height: 23,
                    backgroundColor: "#EA640D",
                    justifyContent: "center",
                    marginHorizontal: 5,
                    marginVertical: 10,
                    borderRadius: 5,
                  }}
                >
                  <Text
                    style={{
                      fontFamily: "Kanit-Regular",
                      fontSize: 12,
                      textAlign: "center",
                      color: "#ffff",
                    }}
                  >
                    SAVE
                  </Text>
                </TouchableOpacity> */}
              </View>
            </View>
          </View>
        </ScrollView>
      </Modal>

      <View
        style={{
          // width: wp("85%"),
          height: 138,
          backgroundColor: "#036BB2",
        }}
      >
        <Text style={[styles.styletext, { color: "#ffff", marginLeft: 40 }]}>
          Motorbike
        </Text>
        <View style={styles.styleViewAll}>
          <View style={{ flexDirection: "row" }}>
            <View
              style={{
                width: "70%",
                maxWidth: 587,
                height: 58,
                backgroundColor: "#E7E7E7",
                borderRadius: 5,
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <FontAwesome
                name="search"
                size={35}
                color="black"
                style={{ marginHorizontal: 8 }}
              />
              <TextInput
                style={{
                  width: "50%",
                  maxWidth: 550,
                  height: 58,
                  fontSize: 30,
                  fontFamily: "Kanit-Regular",
                }}
                placeholder={"ค้นหา"}
                defaultValue={search}
                onChangeText={(text) => {
                  setsearch(text);
                }}
              />
            </View>

            <View style={{ flexDirection: "row" }}>
              <RNPickerSelect
                placeholder={placeholder}
                value={dropdaw}
                onValueChange={(value) => {
                  setdropdaw(value);
                }}
                style={{
                  inputWeb: {
                    width: "100%",
                    height: "100%",
                    backgroundColor: "#EA640D",
                    justifyContent: "center",
                    borderRadius: 5,
                    flexDirection: "row",
                    marginHorizontal: 10,
                    fontSize: size.width < 1080 ? 18 : 20,
                    color: "#ffffff",
                    fontFamily: "Kanit-Regular",
                  },
                }}
                items={type}
              />
              {/* <RNPickerSelect
                placeholder={placeholderJo}
                onValueChange={(value) => {
                  // setdropdaw(value);
                }}
                style={{
                  inputWeb: {
                    width: "100%",
                    height: "100%",
                    backgroundColor: "#EA640D",
                    justifyContent: "center",
                    borderRadius: 5,
                    flexDirection: "row",
                    marginHorizontal: 10,
                    fontSize: size.width < 1080 ? 18 : 20,
                    color: "#ffffff",
                    fontFamily: "Kanit-Regular",
                  },
                }}
                items={itemsjo}
              /> */}
            </View>
          </View>

          <View
            style={{
              width: "100%",
              alignItems: "center",
              backgroundColor: "#036BB2",
              height: 60,
              borderRadius: 5,
              marginTop: 15,
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <Text
              style={[
                styles.textTitle,
                { width: "10%", fontSize: size.width < 1080 ? 18 : 20 },
              ]}
            >
              No.
            </Text>

            <Text
              style={[
                styles.textTitle,
                { width: "20%", fontSize: size.width < 1080 ? 18 : 20 },
              ]}
            >
              ชื่อ
            </Text>

            <Text
              style={[
                styles.textTitle,
                { width: "13%", fontSize: size.width < 1080 ? 18 : 20 },
              ]}
            >
              ชื่อร้าน
            </Text>

            <Text
              style={[
                styles.textTitle,
                { width: "12%", fontSize: size.width < 1080 ? 18 : 20 },
              ]}
            >
              ราคาเช่า/วัน
            </Text>

            <Text
              style={[
                styles.textTitle,
                { width: "12%", fontSize: size.width < 1080 ? 18 : 20 },
              ]}
            >
              ประเภทรถ
            </Text>

            <Text
              style={[
                styles.textTitle,
                { width: "12%", fontSize: size.width < 1080 ? 18 : 20 },
              ]}
            >
              สถานะการเช่า
            </Text>

            <Text
              style={[
                styles.textTitle,
                { width: "8%", fontSize: size.width < 1080 ? 18 : 20 },
              ]}
            >
              สถานะ
            </Text>

            <Text
              style={[
                styles.textTitle,
                { width: "5%", fontSize: size.width < 1080 ? 18 : 20 },
              ]}
            >
              ข้อมูล
            </Text>

            {/* <Text
              style={[
                styles.textTitle,
                { width: "5%", fontSize: size.width < 1080 ? 18 : 20 },
              ]}
            >
              แก้
            </Text> */}

            <Text
              style={[
                styles.textTitle,
                { width: "5%", fontSize: size.width < 1080 ? 18 : 20 },
              ]}
            >
              ลบ
            </Text>
          </View>

          <FlatList
            data={data
              .filter((item) =>
                item.product_name.toLowerCase().includes(search.toLowerCase())
              )
              .filter((item) => {
                if (dropdaw != null) {
                  console.log(item.rent_type);
                  return item.rent_type == dropdaw;
                } else {
                  return true;
                }
              })}
            renderItem={({ item }) => {
              return (
                <View
                  style={{
                    width: "100%",
                    alignItems: "center",
                    backgroundColor: "#C5E5FE30",
                    height: 60,
                    borderRadius: 5,
                    marginTop: 15,
                    flexDirection: "row",
                    justifyContent: "space-between",
                  }}
                >
                  <Text
                    style={[
                      styles.textDetail,
                      { width: "10%", fontSize: size.width < 1080 ? 18 : 20 },
                    ]}
                  >
                    {item.id}
                  </Text>

                  <Text
                    style={[
                      styles.textDetail,
                      { width: "20%", fontSize: size.width < 1080 ? 18 : 20 },
                    ]}
                  >
                    {item.product_name}
                  </Text>

                  <Text
                    style={[
                      styles.textDetail,
                      { width: "15%", fontSize: size.width < 1080 ? 18 : 20 },
                    ]}
                  >
                    กวางขาวอยู่กลางเขา
                  </Text>

                  <Text
                    style={[
                      styles.textDetail,
                      { width: "11%", fontSize: size.width < 1080 ? 18 : 20 },
                    ]}
                  >
                    {item.price} ฿
                  </Text>

                  <Text
                    style={[
                      styles.textDetail,
                      { width: "12%", fontSize: size.width < 1080 ? 18 : 20 },
                    ]}
                  >
                    {
                      type.filter((val, i) => item.rent_type == val.value)[0]
                        .label
                    }
                  </Text>

                  <Text
                    style={[
                      styles.textDetail,
                      { width: "12%", fontSize: size.width < 1080 ? 18 : 20 },
                    ]}
                  >
                    อยู่ในคลัง
                  </Text>

                  <Text
                    style={[
                      styles.textDetail,
                      { width: "8%", fontSize: size.width < 1080 ? 18 : 20 },
                    ]}
                  >
                    ใช้งาน
                  </Text>

                  <TouchableOpacity
                    style={{ width: "5%", alignItems: "center" }}
                    onPress={() => {
                      setModalVisible((val) => !val);
                      setItem(item);
                    }}
                  >
                    <Foundation
                      name="clipboard-notes"
                      size={30}
                      color="#A1A1A1"
                    />
                  </TouchableOpacity>
                  {/* 
                  <TouchableOpacity
                    style={{ width: "5%", alignItems: "center" }}
                    onPress={() => {
                      setModalVisibleED((val) => !val);
                    }}
                  >
                    <FontAwesome5 name="edit" size={30} color="#A1A1A1" />
                  </TouchableOpacity> */}

                  <TouchableOpacity
                    style={{ width: "5%", alignItems: "center" }}
                  >
                    <AntDesign name="delete" size={30} color="#A1A1A1" />
                  </TouchableOpacity>
                </View>
              );
            }}
          />
        </View>
      </View>
    </View>
  );
};
const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: "#F5F5F5",
  },
  stytab: {
    width: wp("18%"),
    height: 90,
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
  },
  styletext: {
    fontSize: 36,
    fontFamily: "Kanit-Regular",
    marginHorizontal: 10,
  },
  styleR: {
    width: 17,
    height: 17,
    borderRadius: 30,
    marginHorizontal: 10,
  },
  styleViewAll: {
    height: 800,
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 3.84,
    elevation: 5,
    marginHorizontal: 20,
    borderRadius: 10,
    marginTop: 10,
    padding: 20,
    backgroundColor: "#fff",
  },
  Box: {
    height: 60,
    justifyContent: "center",
  },
  styletextAll: {
    fontSize: 20,
    color: "#EA640D",
    fontFamily: "Kanit-Regular",
  },
  styleinput: {
    height: 29,
    backgroundColor: "#FFFFFF50",
    fontSize: 18,
    fontFamily: "Kanit-Regular",
  },
  Box: {
    height: 60,
    justifyContent: "center",
  },
  textTitle: {
    color: "#ffffff",
    fontFamily: "Kanit-Regular",
    textAlign: "center",
  },
  textDetail: {
    color: "#000000",
    fontFamily: "Kanit-Regular",
    textAlign: "center",
  },
});

export default Page3;
