import React, { useEffect, useRef, useState } from "react";
import {
  StyleSheet,
  Text,
  View,
  TouchableOpacity,
  Dimensions,
  SafeAreaView,
  Image,
  ImageBackground,
  TextInput,
  ScrollView,
  FlatList,
} from "react-native";
import {
  widthPercentageToDP as wp,
  heightPercentageToDP as hp,
} from "react-native-responsive-screen";
import {
  AntDesign,
  Entypo,
  MaterialCommunityIcons,
  FontAwesome,
} from "@expo/vector-icons";
import Modal from "modal-enhanced-react-native-web";
import { apiservice } from "../apiservice/api";

const pageoption3 = () => {
  const [data, setData] = useState([]);

  const [modalVisible, setModalVisible] = useState(false);

  async function callApiProcut() {
    const api = await apiservice({
      path: "/notificaton/reportall?page=0",
      method: "get",
    });
    console.log(api);
    // api.data.data.map((val) => {
    //   console.log(val.uid);
    // });
    setData(api.data.data);
  }

  useEffect(() => {
    callApiProcut();
  }, []);

  //notificaton

  return (
    <View>
      <Modal
        isVisible={modalVisible}
        onBackdropPress={() => setModalVisible((val) => !val)}
      >
        <ScrollView>
          <View style={{ flex: 1, justifyContent: "center" }}>
            <View
              style={{
                width: 970,
                backgroundColor: "#fff",
                alignSelf: "center",
                borderRadius: 10,
                height: 700,
                justifyContent: "center",
              }}
            >
              <View
                style={{
                  width: 900,
                  alignSelf: "center",
                  height: 650,
                  justifyContent: "center",
                }}
              >
                <View style={{ flexDirection: "row" }}>
                  <View
                    style={{
                      width: 120,
                      height: 100,
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <View
                      style={{
                        width: 103,
                        height: 103,
                        borderRadius: 50,
                        backgroundColor: "#036BB2",
                        marginTop: 10,
                      }}
                    ></View>
                  </View>
                  <View style={{ height: 120, justifyContent: "flex-end" }}>
                    <Text
                      style={{
                        fontSize: 24,
                        fontFamily: "Kanit-Regular",
                        marginHorizontal: 10,
                      }}
                    >
                      จาก : Userza112
                    </Text>
                  </View>
                </View>

                <View
                  style={[
                    styles.styleinput,
                    {
                      width: 890,
                    },
                  ]}
                >
                  <TextInput
                    placeholder="หัวข้อ : ร้องเรียนเรื่องร้านค้า....."
                    style={[
                      styles.styleinput,
                      {
                        width: 890,
                        marginLeft: 10,
                      },
                    ]}
                  />
                </View>

                <View
                  style={{
                    height: 160,
                    backgroundColor: "#E7E7E7",
                    width: 900,
                    marginTop: 10,
                    borderRadius: 10,
                  }}
                >
                  <TextInput
                    placeholder="รายละเอียด :"
                    multiline={true}
                    style={{
                      height: 160,
                      fontSize: 18,
                      fontFamily: "Kanit-Regular",
                      width: 890,
                      marginLeft: 10,
                    }}
                  />
                </View>
                <Text
                  style={{
                    fontSize: 24,
                    fontFamily: "Kanit-Regular",
                    textAlign: "right",
                    marginTop: 10,
                  }}
                >
                  ส่งจาก : Adminza
                </Text>

                <View
                  style={[
                    styles.styleinput,
                    {
                      width: 890,
                    },
                  ]}
                >
                  <TextInput
                    placeholder="หัวข้อ : ร้องเรียนเรื่องร้านค้า....."
                    style={[
                      styles.styleinput,
                      {
                        width: 890,
                        marginLeft: 10,
                      },
                    ]}
                  />
                </View>

                <View
                  style={{
                    height: 160,
                    backgroundColor: "#E7E7E7",
                    width: 900,
                    marginTop: 10,
                    borderRadius: 10,
                  }}
                >
                  <TextInput
                    placeholder="รายละเอียด :"
                    multiline={true}
                    style={{
                      height: 160,
                      fontSize: 18,
                      fontFamily: "Kanit-Regular",
                      width: 890,
                      marginLeft: 10,
                    }}
                  />
                </View>

                <TouchableOpacity
                  style={{
                    marginTop: 10,
                    width: 150,
                    height: 33,
                    backgroundColor: "#A1A1A1",
                    justifyContent: "center",
                    borderRadius: 10,
                  }}
                >
                  <Text
                    style={{
                      fontSize: 18,
                      fontFamily: "Kanit-Regular",
                      textAlign: "center",
                      color: "#ffffff",
                    }}
                  >
                    ส่งจาก : Adminza
                  </Text>
                </TouchableOpacity>

                <TouchableOpacity
                  onPress={() => {
                    setModalVisible((val) => !val);
                  }}
                  style={{
                    marginTop: 10,
                    width: 131,
                    height: 33,
                    backgroundColor: "#EA640D",
                    justifyContent: "center",
                    alignSelf: "flex-end",
                    borderRadius: 10,
                  }}
                >
                  <Text
                    style={{
                      fontSize: 24,
                      fontFamily: "Kanit-Regular",
                      textAlign: "center",
                      color: "#ffff",
                    }}
                  >
                    ตอบกลับ
                  </Text>
                </TouchableOpacity>
              </View>
            </View>
          </View>
        </ScrollView>
      </Modal>

      <View
        style={{
          width: wp("85%"),
          height: 138,
          backgroundColor: "#036BB2",
        }}
      >
        <Text style={[styles.styletext, { color: "#ffff", marginLeft: 40 }]}>
          Option ร้องเรียนและ ข้อเสนอเพิ่มเติม
        </Text>

        <View
          style={{
            width: wp("85%"),
            height: 800,
          }}
        >
          <FlatList
            data={data}
            renderItem={({ item, index }) => {
              return (
                <View
                  style={{
                    width: wp("78%"),
                    height: 200,
                    backgroundColor: "#fff",
                    shadowColor: "#000",
                    shadowOffset: {
                      width: 0,
                      height: 2,
                    },
                    shadowOpacity: 0.25,
                    shadowRadius: 3.84,
                    elevation: 5,
                    marginLeft: 40,
                    borderRadius: 10,
                    marginTop: 10,
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <View
                    style={{
                      width: wp("76%"),
                      height: 170,
                      flexDirection: "row",
                    }}
                  >
                    <View
                      style={{ width: 150, height: 170, alignItems: "center" }}
                    >
                      <View
                        style={{
                          width: 103,
                          height: 103,
                          borderRadius: 50,
                          // backgroundColor: "#036BB2",
                          marginTop: 10,
                        }}
                      >
                        <Image
                          style={{
                            width: 103,
                            height: 103,
                            borderRadius: 50,
                            // backgroundColor: "#036BB2",
                            marginTop: 10,
                          }}
                          source={{ uri: item.user.image_Profile }}
                        />
                      </View>
                    </View>

                    <View
                      style={{ width: 150, height: 170, alignItems: "center" }}
                    >
                      <Text
                        style={{
                          marginTop: 10,
                          fontSize: 24,
                          fontFamily: "Kanit-Regular",
                        }}
                      >
                        หัวข้อ :
                      </Text>
                      <Text
                        style={{ fontSize: 24, fontFamily: "Kanit-Regular" }}
                      >
                        เรื่อง :
                      </Text>
                    </View>

                    <View style={{ width: wp("50%"), height: 170 }}>
                      <Text
                        style={{
                          marginTop: 10,
                          fontSize: 24,
                          fontFamily: "Kanit-Regular",
                        }}
                      >
                        {item.title}
                      </Text>
                      <Text
                        style={{ fontSize: 24, fontFamily: "Kanit-Regular" }}
                      >
                        {item.message}
                      </Text>
                    </View>
                    <View
                      style={{ width: 170, height: 170, alignItems: "center" }}
                    >
                      <TouchableOpacity
                        onPress={() => {
                          setModalVisible((val) => !val);
                        }}
                        style={{
                          width: 166,
                          height: 48,
                          backgroundColor: "#EA640D",
                          borderRadius: 10,
                          marginTop: 110,
                          justifyContent: "center",
                        }}
                      >
                        <Text
                          style={{
                            fontSize: 24,
                            fontFamily: "Kanit-Regular",
                            color: "#ffff",
                            textAlign: "center",
                          }}
                        >
                          ดูเพิ่มเติม
                        </Text>
                      </TouchableOpacity>
                    </View>
                  </View>
                </View>
              );
            }}
          />
        </View>
      </View>
    </View>
  );
};
const styles = StyleSheet.create({
  styletext: {
    fontSize: 36,
    fontFamily: "Kanit-Regular",
    marginHorizontal: 10,
  },
  styletextAll: {
    fontSize: 20,
    color: "#EA640D",
    fontFamily: "Kanit-Regular",
  },
  styleinput: {
    height: 47,
    backgroundColor: "#E7E7E7",
    fontSize: 18,
    fontFamily: "Kanit-Regular",
    borderRadius: 10,
  },
});

export default pageoption3;
