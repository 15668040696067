import React, { useEffect, useRef, useState } from "react";
import {
  StyleSheet,
  Text,
  View,
  TouchableOpacity,
  Dimensions,
  SafeAreaView,
  Image,
  ImageBackground,
  TextInput,
} from "react-native";
import {
  widthPercentageToDP as wp,
  heightPercentageToDP as hp,
} from "react-native-responsive-screen";
import { apiservice } from "../apiservice/api";

const { width, height } = Dimensions.get("window");

const pageoption2 = () => {
  const [user, setUser] = useState({ term: "", policy: "" });

  async function apicall() {
    const response = await apiservice({
      path: "notificaton/termall",
      method: "get",
    });

    if (response.status == 200) {
      console.log(response.data);
      if (response.data.length > 0) {
        setUser(response.data[0]);
      } else {
        setUser({});
      }
    }
  }

  async function apicreatecall() {
    const response = await apiservice({
      path: "notificaton/termcreate",
      method: "post",
      body: user,
    });

    if (response.status == 200) {
      apicall();
    }
  }

  useEffect(() => {
    apicall();
  }, []);

  return (
    <View
      style={{
        height: 138,
        backgroundColor: "#036BB2",
      }}
    >
      <View
        style={{
          flexDirection: "row",
          justifyContent: "space-between",
          paddingHorizontal: 10,
        }}
      >
        <Text style={[styles.styletext, { color: "#ffff", marginLeft: 40 }]}>
          Option Privacy policy & Term of use
        </Text>
        <TouchableOpacity
          onPress={() => {
            apicreatecall();
          }}
          style={{ backgroundColor: "#EA640D", borderRadius: 10 }}
        >
          <Text
            style={[styles.styletext, { color: "#ffff", textAlign: "center" }]}
          >
            SUBMIT
          </Text>
        </TouchableOpacity>
      </View>
      <View
        style={{
          height: 400,
          shadowColor: "#000",
          shadowOffset: {
            width: 0,
            height: 2,
          },
          shadowOpacity: 0.25,
          shadowRadius: 3.84,
          elevation: 5,
          marginHorizontal: 20,
          borderRadius: 10,
          marginTop: 10,
          padding: 20,
          backgroundColor: "#fff",
        }}
      >
        <Text style={[styles.styletext, { color: "#036BB2", marginLeft: 40 }]}>
          Privacy policy
        </Text>

        <View
          style={{
            width: wp("74%"),
            height: 250,
            backgroundColor: "#E7E7E7",
            alignSelf: "center",
            marginTop: 30,
          }}
        >
          <TextInput
            style={{
              width: wp("74%"),
              height: 250,
              backgroundColor: "#E7E7E7",
              alignSelf: "center",
              marginTop: 20,
            }}
            multiline
            defaultValue={user.term}
            onChangeText={(text) => {
              setUser((val) => ({ ...val, term: text }));
            }}
          />
        </View>
      </View>

      <View
        style={{
          height: 400,
          shadowColor: "#000",
          shadowOffset: {
            width: 0,
            height: 2,
          },
          shadowOpacity: 0.25,
          shadowRadius: 3.84,
          elevation: 5,
          marginHorizontal: 20,
          borderRadius: 10,
          marginTop: 10,
          padding: 20,
          backgroundColor: "#fff",
        }}
      >
        <Text style={[styles.styletext, { color: "#036BB2", marginLeft: 40 }]}>
          Term of use
        </Text>

        <View
          style={{
            width: wp("74%"),
            height: 250,
            backgroundColor: "#E7E7E7",
            alignSelf: "center",
            marginTop: 20,
          }}
        >
          <TextInput
            style={{
              width: wp("74%"),
              height: 250,
              backgroundColor: "#E7E7E7",
              alignSelf: "center",
              marginTop: 20,
            }}
            multiline
            defaultValue={user.policy}
            onChangeText={(text) => {
              setUser((val) => ({ ...val, policy: text }));
            }}
          />
        </View>
      </View>
    </View>
  );
};
const styles = StyleSheet.create({
  styletext: {
    fontSize: 36,
    fontFamily: "Kanit-Regular",
    marginHorizontal: 10,
  },
});

export default pageoption2;
